import React from "react";

export default class SuccessBanner extends React.Component {
  handleClose = () => {
    const { parent } = this.props;
    parent.setState({ successMessage: "" });
  };

  render() {
    const { successMessage } = this.props.parent.state;
    if (!successMessage) {
      return null;
    }

    return (
      <div className="uk-alert-success uk-alert" uk-alert="">
        {/* <button
          style={{ paddingTop: "-5px" }}
          className="uk-button-link uk-alert-close uk-icon"
          uk-close=""
          onClick={this.handleClose}
        /> */}
        <div>{successMessage}</div>
      </div>
    );
  }
}
