import React from "react";

import NowPlayingViewWidget from "./NowPlayingViewWidget";
import RadioScheduleViewWidget from "./RadioScheduleViewWidget";
import RotatingViewWidget from "./RotatingViewWidget";
import TextImageViewWidget from "./TextImageViewWidget";
import TwitterFeedViewWidget from "./TwitterFeedViewWidget";
import WorldClockViewWidget from "./WorldClockViewWidget";
import YouTubeLiveChatViewWidget from "./YouTubeLiveChatViewWidget";
import InstagramCommentsViewWidget from "./InstagramCommentsViewWidget";

import DashboardWidgetTypes from "../lib/DashboardWidgetTypes";

// Selects correct view widget to render based on widget type string.
export default class SelectingDashboardViewWidget extends React.Component {
  render() {
    const { type, ...other } = this.props;

    const types = DashboardWidgetTypes.Types;
    switch (type) {
      case types.NowPlaying.Type:
        return <NowPlayingViewWidget {...other} />;
      case types.RadioSchedule.Type:
        return <RadioScheduleViewWidget {...other} />;
      case types.Rotating.Type:
        return <RotatingViewWidget {...other} />;
      case types.TextImage.Type:
        return <TextImageViewWidget {...other} />;
      case types.TwitterFeed.Type:
        return <TwitterFeedViewWidget {...other} />;
      case types.WorldClock.Type:
        return <WorldClockViewWidget {...other} />;
      case types.YouTubeLiveChat.Type:
        return <YouTubeLiveChatViewWidget {...other} />;
      case types.InstagramComments.Type:
        return <InstagramCommentsViewWidget {...other} />;
      default:
        throw new Error(`Invalid widget type: ${type}`);
    }
  }
}
