import { connect } from "react-redux";

import BaseViewPage from "../../pages/BaseViewPage";
import { scheduleDashboard } from "../../actions";

class DashboardsView extends BaseViewPage {
  componentDidMount() {
    super.componentDidMount();

    const { id } = this.props.match.params;
    this.props.scheduleDashboard(id);
  }
}

function mapStateToProps(state) {
  const { scheduledLayout } = state;
  return { layout: scheduledLayout };
}

function mapDispatchToProps(dispatch) {
  return { scheduleDashboard: (id) => dispatch(scheduleDashboard(id)) };
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardsView);
