import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import UIkit from "uikit";

import AbstractBasePage from "../../pages/AbstractBasePage";
import MasterClientNavigator from "../../pages/MasterClientNavigator";
import { Spinner } from "../_shared/Bundle";
import DashboardForm from "../../components/DashboardForm";
import LayoutsTable from "../../components/LayoutsTable";
import DashboardPageTitle from "../../components/DashboardPageTitle";
import DashboardSchedule from "../../components/DashboardSchedule";
import NullLink from "../../components/NullLink";
import { BreadcrumbItem } from "../../components/Breadcrumbs";

import {
  loadDashboard,
  saveDashboard,
  setBreadcrumbItems,
} from "../../actions";

class DashboardsEdit extends AbstractBasePage {
  static TAB_INDEXES = {
    "#layouts": 0,
    "#schedule": 1,
    "#edit": 2,
  };

  constructor(props) {
    super(props);
    this.navigator = new MasterClientNavigator(this.props.history);
  }

  componentDidMount() {
    const hash = this.props.history.location.hash;
    this.switchTab(hash);
  }

  handleSubmit = async (data, { setSubmitting }) => {
    const { dashboard } = this.props;
    if (!dashboard) {
      return;
    }

    dashboard.set(data);
    const self = this;
    this.props.saveDashboard(dashboard, {
      onSuccess: () => {
        setSubmitting(false);
        self.navigator.pushDashboardEdit(dashboard);
        self.switchTab("#layouts");
        UIkit.notification(
          "<span uk-icon='icon: check'></span> Dashboard Updated",
          {
            status: "success",
            timeout: 5000,
          }
        );
      },
      onError: () => {
        setSubmitting(false);
      },
    });
  };

  componentWillMount() {
    this.loadDashboard();
  }

  render() {
    const { dashboard } = this.props;

    if (!dashboard) {
      return <Spinner />;
    }

    return (
      <div>
        <DashboardPageTitle dashboard={dashboard} />

        <ul
          id="switcher-dashboard"
          uk-tab=""
          data-uk-switcher="{swiping:false}"
        >
          <li className="uk-active">
            <NullLink>Layouts</NullLink>
          </li>
          <li>
            <NullLink>Schedule</NullLink>
          </li>
          <li>
            <NullLink>Edit Details</NullLink>
          </li>
        </ul>

        <ul className="uk-switcher">
          <li>
            <LayoutsTable dashboard={dashboard} />
          </li>
          <li>
            <DashboardSchedule dashboard={dashboard} />
          </li>
          <li>
            <DashboardForm dashboard={dashboard} onSubmit={this.handleSubmit} />
          </li>
        </ul>
      </div>
    );
  }

  getBreadcrumbItems() {
    const { dashboard } = this.props;
    if (!dashboard) {
      return [];
    }

    const pushDashboardIndex = this.navigator.pushDashboardIndex;
    return [
      new BreadcrumbItem("Dashboards", pushDashboardIndex),
      new BreadcrumbItem(dashboard.getDisplayName()),
    ];
  }

  loadDashboard() {
    const { id } = this.props.match.params;
    this.props.loadDashboard(id);
  }

  switchTab(hash) {
    if (!(hash in this.constructor.TAB_INDEXES)) {
      return;
    }

    const index = this.constructor.TAB_INDEXES[hash];
    UIkit.switcher("#switcher-dashboard").show(index);
  }
}

function mapStateToProps(state) {
  const { dashboard, breadcrumbItems } = state;
  return { dashboard, breadcrumbItems };
}

function mapDispatchToProps(dispatch) {
  return {
    loadDashboard: (id) => dispatch(loadDashboard(id)),
    saveDashboard: (dashboard, { onSuccess, onError }) =>
      dispatch(saveDashboard(dashboard, { onSuccess, onError })),
    setBreadcrumbItems: (items) => dispatch(setBreadcrumbItems(items)),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DashboardsEdit)
);
