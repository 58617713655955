import AppNavigator from "../../pages/AppNavigator";

export default class DashboardsNavigator extends AppNavigator {
  pushIndex = () => this.push(this.indexPath());

  pushNew = () => this.push(this.newPath());

  pushEdit = (dashboard, hashParam) =>
    this.push(this.editPath(dashboard, hashParam));

  pushViewInTab = (dashboard) =>
    window.open(this.viewPath(dashboard), "_blank");

  indexPath = () => "/dashboards";

  newPath = () => "/dashboards/new";

  editPath = (dashboard) => {
    const path = `/dashboards/${dashboard.get("id")}`;

    return path;
  };

  viewPath = (dashboard) => `/dashboards/${dashboard.get("id")}/view`;
}
