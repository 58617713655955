import React from "react";

import GradientBackground from "../components/GradientBackground";

// Stub page required by Facebook/Instagram app application process.
export default class PrivacyPolicyPage extends React.Component {
  render() {
    return (
      <GradientBackground className="authentication">
        <div className="uk-position-center">
          <h1 className="uk-light uk-text-center">Beats1&nbsp;Dashboard</h1>
          <div className="uk-card uk-card-body uk-card-default uk-text-small uk-padding-remove-vertical uk-text-center uk-width-large">
            <div className="uk-section-xsmall">
              <p>This is a private, invitation-only platform!</p>
              <p>
                If you're fortunate enough to be invited, the privacy policy
                will be linked from the invitation.
              </p>
              <p>Thanks! Now clear out.</p>
            </div>
          </div>
        </div>
      </GradientBackground>
    );
  }
}
