import React from "react";
import { connect } from "react-redux";
import { ActionCableProvider } from "react-actioncable-provider";

import YouTubeLiveChatConsumer from "./YouTubeLiveChatConsumer";

// Manage ActionCable consumers for YouTube live chats. The library is a little
// buggy, so we want to just mount this once and leave it mounted.
class YouTubeLiveChatConsumerManager extends React.Component {
  render() {
    const { subscribedYouTubeStreamerEmails } = this.props;
    const streamerEmails = this.unique(subscribedYouTubeStreamerEmails || []);

    return (
      <ActionCableProvider url={process.env.REACT_APP_WS_URL}>
        {streamerEmails.map((streamerEmail) => (
          <YouTubeLiveChatConsumer
            key={streamerEmail}
            streamerEmail={streamerEmail}
          />
        ))}
      </ActionCableProvider>
    );
  }

  unique = (array) => [...new Set(array)];
}

function mapStateToProps(state) {
  const { subscribedYouTubeStreamerEmails } = state;
  return { subscribedYouTubeStreamerEmails };
}

export default connect(mapStateToProps)(YouTubeLiveChatConsumerManager);
