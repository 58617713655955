import React from "react";
import RGL, { WidthProvider } from "react-grid-layout";

import SelectingDashboardViewWidget from "./SelectingDashboardViewWidget";

const ReactGridLayout = WidthProvider(RGL);

export default class LayoutViewPane extends React.Component {
  static defaultProps = {
    className: "layout",
    rowHeight: 120,
    cols: 6,
  };

  render() {
    const { layout, ...other } = this.props;
    const gridLayout = layout.getStaticGridLayout();

    return (
      <ReactGridLayout layout={gridLayout} {...other}>
        {this.generateDOM()}
      </ReactGridLayout>
    );
  }

  // private

  generateDOM() {
    const { layout } = this.props;
    const gridLayout = layout.get("grid_layout");
    const widgetData = layout.get("widget_data");

    return gridLayout.map((item) => {
      const { type, config } = widgetData[item.i];
      return (
        <SelectingDashboardViewWidget
          key={item.i}
          index={item.i}
          type={type}
          config={config}
          layout={item}
        />
      );
    });
  }
}
