import API from "./API";
import AuthenticatedModel from "./AuthenticatedModel";

const TwitterFeedModel = AuthenticatedModel.extend({
  urlRoot: API.urlRoot("social/twitter_feeds"),

  getDisplayName: function () {
    return this.get("name") || "Untitled Twitter Feed";
  },
});
export default TwitterFeedModel;
