import React from "react";

// Be sure child classes connect via Redux and expose required dispatch in props:
//
//   import { connect } from 'react-redux'
//   import { setBreadcrumbItems } from '../actions'
//
//   function mapStateToProps(state) {
//     const { breadcrumbItems } = state
//     return { breadcrumbItems }
//   }
//
//   function mapDispatchToProps(dispatch) {
//     return { setBreadcrumbItems: items => dispatch(setBreadcrumbItems(items)) },
//   }
//
export default class AbstractBasePage extends React.Component {
  componentDidMount() {
    this.updateBreadcrumbs();
  }

  componentDidUpdate() {
    this.updateBreadcrumbs();
  }

  updateBreadcrumbs() {
    if (!this.getBreadcrumbItems) {
      this.props.setBreadcrumbItems([]);
      return;
    }

    const items = this.getBreadcrumbItems();
    if (JSON.stringify(items) !== JSON.stringify(this.props.breadcrumbItems)) {
      this.props.setBreadcrumbItems(items);
    }
  }
}
