import React from "react";

import DashboardWidgetTypes from "../lib/DashboardWidgetTypes";

export default class DashboardPreviewWidget extends React.Component {
  static defaultProps = {
    index: undefined,
  };

  render() {
    const { className, style, ...other } = this.props;
    const { name, color, stem } = this.state;

    const src = DashboardWidgetTypes.iconSrc(stem);
    const alt = DashboardWidgetTypes.iconAlt(name);

    return (
      <div
        className={`dashboard-widget ${className}`}
        style={{ ...style, backgroundColor: color }}
        {...other}
      >
        <img className="image" src={src} alt={alt}></img>
      </div>
    );
  }
}
