export default class Utils {
  // Return copy of object with keys to undefined values removed.
  static definedOnly(object) {
    const newObject = {};
    Object.keys(object).forEach((key) => {
      if (object[key] !== undefined) {
        newObject[key] = object[key];
      }
    });
    return newObject;
  }

  static async sleep(milliseconds) {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  }
}
