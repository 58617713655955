import React from "react";
import { Formik } from "formik";

import TimezonePicker from "react-timezone";

import DashboardWidgetTypes from "../lib/DashboardWidgetTypes";

export default class WorldClockForm extends React.Component {
  defaultState = DashboardWidgetTypes.Types.WorldClock.DefaultState;

  render() {
    const { subwidgetDataItem, setSubmitForm, onSubmit } = this.props;
    const config = this.normalizeConfig(subwidgetDataItem.config);

    const labelStyle = { color: "#1C1C1C" };

    return (
      <Formik
        initialValues={config}
        onSubmit={onSubmit}
        render={({
          values,
          handleChange,
          handleBlur,
          setFieldValue,
          submitForm,
        }) => {
          setSubmitForm(submitForm);

          return (
            <div className="uk-form-stacked uk-margin">
              <div className="uk-margin">
                <label className="uk-form-label" style={labelStyle}>
                  Name
                </label>
                <div className="uk-form-controls">
                  <input
                    className="uk-input"
                    type="text"
                    name="name"
                    placeholder="Name of the widget"
                    value={values.name || ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
              </div>

              <div className="uk-margin">
                <label className="uk-form-label" style={labelStyle}>
                  Timezone
                </label>
                <div className="uk-form-controls">
                  <TimezonePicker
                    offset="UTC"
                    className="timezone-picker"
                    inputProps={{
                      placeholder: "Timezone",
                      name: "timezone",
                    }}
                    value={values.timezone}
                    onChange={(value) => setFieldValue("timezone", value)}
                    onBlur={handleBlur}
                  />
                </div>
              </div>

              <div className="uk-margin-small">
                <div className="uk-form-controls">
                  <label className="uk-form-label" style={labelStyle}>
                    <input
                      className="uk-checkbox uk-margin-small-right"
                      type="checkbox"
                      name="isTwelveHour"
                      checked={values.isTwelveHour || false}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    Display 12-hour format
                  </label>
                </div>
              </div>

              <div className="uk-margin-small">
                <div className="uk-form-controls">
                  <label className="uk-form-label" style={labelStyle}>
                    <input
                      className="uk-checkbox uk-margin-small-right"
                      type="checkbox"
                      name="isSecondsDisplayed"
                      checked={values.isSecondsDisplayed || false}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    Display seconds
                  </label>
                </div>
              </div>

              <div className="uk-margin">
                <label className="uk-form-label" style={labelStyle}>
                  <input
                    className="uk-input-xsmall uk-form-width-xsmall uk-margin-small-right"
                    type="color"
                    name="backgroundColor"
                    placeholder=""
                    value={values.backgroundColor || "#000000"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  Background Color
                </label>
              </div>

              <div className="uk-margin">
                <label className="uk-form-label" style={labelStyle}>
                  <input
                    className="uk-input-xsmall uk-form-width-xsmall uk-margin-small-right"
                    type="color"
                    name="textColor"
                    placeholder=""
                    value={values.textColor || "#000000"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  Text Color
                </label>
              </div>
            </div>
          );
        }}
      />
    );
  }

  normalizeConfig(config) {
    // mostly the schema gets goofy during development;
    // should not be an issue once schema stabilizes
    const {
      name,
      timezone,
      isTwelveHour,
      isSecondsDisplayed,
      backgroundColor,
      textColor,
    } = config || {};
    const normalized = {
      name,
      timezone,
      isTwelveHour,
      isSecondsDisplayed,
      backgroundColor,
      textColor,
    };

    // undefined values will break controlled form inputs
    if (normalized.name === undefined) {
      normalized.name = this.defaultState.name;
    }
    if (normalized.timezone === undefined) {
      normalized.timezone = this.defaultState.timezone;
    }
    if (
      normalized.isTwelveHour === undefined ||
      normalized.isTwelveHour === ""
    ) {
      normalized.isTwelveHour = this.defaultState.isTwelveHour;
    }
    if (
      normalized.isSecondsDisplayed === undefined ||
      normalized.isSecondsDisplayed === ""
    ) {
      normalized.isSecondsDisplayed = this.defaultState.isSecondsDisplayed;
    }
    if (normalized.backgroundColor === undefined) {
      normalized.backgroundColor = this.defaultState.backgroundColor;
    }
    if (normalized.textColor === undefined) {
      normalized.textColor = this.defaultState.textColor;
    }

    return normalized;
  }
}
