import React from "react";
// import Moment from "react-moment";
import { withRouter } from "react-router-dom";

import ButtonLink from "./ButtonLink";

import ThemeNavigator from "../pages/ThemeNavigator";

class ThemeRow extends React.Component {
  constructor(props) {
    super(props);
    this.navigator = new ThemeNavigator(this.props.history);
  }

  render() {
    const { theme } = this.props;
    if (!theme) {
      return null;
    }

    const pushEdit = this.navigator.pushEdit.bind(this.navigator, theme);

    const name = theme.get("name");
    // const createdAt = theme.get("created_at");
    const description = theme.get("description");
    const dashboards = theme.get("dashboards");
    const isLocked = theme.get("is_locked");

    return (
      <tr>
        <td>
          <ButtonLink onClick={pushEdit}>{name}</ButtonLink>
        </td>
        <td>{description.slice(0, 64)}</td>
        <td>{isLocked ? <span uk-icon="lock" /> : null}</td>
        <td>{dashboards.length}</td>

        <td className="actions">{this.renderDeleteButton(isLocked)}</td>
      </tr>
    );
  }

  renderDeleteButton(isLocked) {
    const { theme, onDelete } = this.props;
    if (!theme || !onDelete || isLocked) {
      return null;
    }

    return (
      <button
        className="uk-icon-button vl-table-delete"
        onClick={() => onDelete(theme)}
        uk-icon="icon: trash; scale: 0.75"
      ></button>
    );
  }
}
export default withRouter(ThemeRow);
