import "backbone";
import "backbone-relational";

import API from "./API";
import AuthenticatedModel from "./AuthenticatedModel";

function hoursFromNow(hours) {
  const date = new Date();
  date.setTime(date.getTime() + hours * 60 * 60 * 1000);
  return date;
}

function getTime(dateString) {
  return new Date(dateString).getTime();
}

function modWeeks(time) {
  return time % (7 * 24 * 60 * 60 * 1000);
}

// Attributes:
// - name:string
// - start_at:time
// - end_at:time
// - recurring:boolean
const TimeSlotModel = AuthenticatedModel.extend({
  urlRoot: API.urlRoot("time_slots"),

  defaults: () => {
    return {
      name: "",
      start_at: new Date(),
      end_at: hoursFromNow(1),
      recurring: false,
    };
  },

  isCurrent: function () {
    let now = Date.now();
    let startAt = getTime(this.get("start_at"));
    let endAt = getTime(this.get("end_at"));

    if (this.get("recurring")) {
      now = modWeeks(now);
      startAt = modWeeks(startAt);
      endAt = modWeeks(endAt);
    }

    return startAt <= now && now <= endAt;
  },
});

TimeSlotModel.fromDashboard = async (dashboard, { start, end }) => {
  await dashboard.fetch();
  const layout = await dashboard.getDefaultLayout();
  await layout.fetch();
  const schedule = dashboard.get("schedule");
  await schedule.fetch();

  return new TimeSlotModel({
    layout,
    schedule,

    start_at: start,
    end_at: end,
  });
};

export default TimeSlotModel;
