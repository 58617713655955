export default class DashboardWidgetTypes {
  static Types = {
    NowPlaying: {
      Type: "NowPlaying",
      Name: "Now Playing",
      Stem: "now-playing",
      DefaultState: {
        name: "",
        showLogo: true,
        showStationName: true,
        showOnAir: true,
      },
      ModalID: "#now-playing-modal",
      Color: "#f75f71",
      Editable: true,
    },
    Rotating: {
      Type: "Rotating",
      Name: "Rotating",
      Stem: "rotating",
      DefaultState: {
        name: "",
        interval: 30,
        subwidgetData: [],
      },
      ModalID: "#rotating-modal",
      Color: "#e5bd2c",
      Editable: false,
    },
    TextImage: {
      Type: "TextImage",
      Name: "Text/Image",
      Stem: "text-image",
      DefaultState: {
        name: "",
        textBody: "",
        imageURL: "",
      },
      ModalID: "#text-image-modal",
      Color: "#40be40",
      Editable: true,
    },
    RadioSchedule: {
      Type: "RadioSchedule",
      Name: "Radio Schedule",
      Stem: "schedule",
      DefaultState: {},
      ModalID: "#radio-schedule-modal",
      Color: "#29ccbf",
      Editable: true,
    },
    TwitterFeed: {
      Type: "TwitterFeed",
      Name: "Twitter Feed",
      Stem: "twitter",
      DefaultState: {
        name: "",
        twitterFeedID: "",
        imageDisplay: "first", // [none, first, all]
      },
      ModalID: "#twitter-feed-modal",
      Color: "#1ca1f3",
      Editable: true,
    },
    WorldClock: {
      Type: "WorldClock",
      Name: "World Clock",
      Stem: "clock",
      DefaultState: {
        name: "",
        timezone: "America/Los_Angeles",
        isTwelveHour: false,
        isSecondsDisplayed: false,
        timezoneText: "",
      },
      ModalID: "#world-clock-modal",
      Color: "#040404",
      Editable: true,
    },
    YouTubeLiveChat: {
      Type: "YouTubeLiveChat",
      Name: "YouTube Live Chat",
      Stem: "youtube",
      DefaultState: {
        name: "",
        streamerEmail: "",
      },
      ModalID: "#youtube-live-chat-modal",
      Color: "#c4302b",
      Editable: true,
    },
    InstagramComments: {
      Type: "InstagramComments",
      Name: "Instagram Comments",
      Stem: "instagram",
      DefaultState: {},
      ModalID: "#not-implemented-modal",
      Color: "#ee1877",
      Editable: false,
    },
  };

  static iconSrc(stem) {
    return `/images/icons/${stem}.svg`;
  }

  static iconAlt(name) {
    return `${name} dashboard widget`;
  }

  static baseModalId(type) {
    return this.Types[type].ModalID.replace("#", "");
  }
}
