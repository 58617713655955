import DashboardWidget from "./DashboardWidget";

export default class TwitterFeedWidget extends DashboardWidget {
  constructor(props) {
    super(props);
    this.state = {
      name: "Twitter Feed",
      color: "#1ca1f3",
      stem: "twitter",
      editable: true,
    };
  }
}
