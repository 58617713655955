import React from "react";
import { connect } from "react-redux";

import { Formik } from "formik";
import FormikDatetime from "./FormikDatetime";

import { saveTimeSlot, destroyTimeSlot } from "../actions";

class TimeSlotForm extends React.Component {
  handleSubmit = (values, { setSubmitting, resetForm }) => {
    const { timeSlot, saveTimeSlot } = this.props;
    timeSlot.set(values);

    saveTimeSlot(timeSlot, {
      onDone: () => {
        setSubmitting(false);
        resetForm();
      },
    });
  };

  handleDelete = () => {
    const { destroyTimeSlot, timeSlot } = this.props;
    destroyTimeSlot(timeSlot);
  };

  render() {
    const { timeSlot, dashboard } = this.props;
    if (!timeSlot || !dashboard) {
      return null;
    }

    const initialValues = timeSlot.attributes;
    initialValues.start_at = new Date(initialValues.start_at);
    initialValues.end_at = new Date(initialValues.end_at);

    try {
      initialValues.layout = initialValues.layout.get("id");
    } catch (_) {
      // already converted to id
    }

    const layouts = dashboard.get("layouts");

    return (
      <Formik
        initialValues={initialValues}
        validate={() => {}}
        onSubmit={this.handleSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          resetForm,
        }) => {
          const error =
            touched.name &&
            errors.name &&
            touched.layout &&
            errors.layout &&
            touched.recurring &&
            errors.recurring &&
            touched.start_at &&
            errors.start_at &&
            touched.end_at &&
            errors.end_at;

          return (
            <form className="uk-form-stacked uk-margin" onSubmit={handleSubmit}>
              <div className="uk-margin">
                <label className="uk-form-label">Name</label>
                <div className="uk-form-controls">
                  <input
                    className="uk-input"
                    name="name"
                    type="text"
                    placeholder="Name of the time slot"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
              </div>

              <div className="uk-margin">
                <label className="uk-form-label">Layout</label>
                <div className="uk-form-controls">
                  <select
                    className="uk-select"
                    name="layout"
                    value={values.layout}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    {layouts.map((layout) => (
                      <option key={layout.get("id")} value={layout.get("id")}>
                        {layout.getDisplayName()}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="uk-margin-small">
                <label className="uk-form-label">Recurring</label>
                <div className="uk-form-controls">
                  <input
                    className="uk-checkbox"
                    name="recurring"
                    type="checkbox"
                    checked={values.recurring}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
              </div>

              <div className="uk-margin">
                <label className="uk-form-label">Start Time</label>
                <div className="uk-form-controls">
                  <FormikDatetime name="start_at" value={values.start_at} />
                </div>
              </div>

              <div className="uk-margin">
                <label className="uk-form-label">End Time</label>
                <div className="uk-form-controls">
                  <FormikDatetime name="end_at" value={values.end_at} />
                </div>
              </div>

              {error ? (
                <div className="uk-margin uk-form-danger">{error}</div>
              ) : null}

              <div className="uk-grid uk-margin">
                <div className="uk-width-1-3 uk-text-left">
                  {timeSlot.isNew() ? null : (
                    <button
                      className="uk-button uk-button-danger"
                      type="button"
                      onClick={this.handleDelete}
                    >
                      Delete
                    </button>
                  )}
                </div>
                <div className="uk-width-2-3 uk-text-right">
                  <button
                    className="uk-button uk-button-default uk-modal-close"
                    type="button"
                    onClick={() => resetForm(initialValues)}
                  >
                    Cancel
                  </button>
                  <button
                    className="uk-button uk-button-primary"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Save
                  </button>
                </div>
              </div>
            </form>
          );
        }}
      </Formik>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    saveTimeSlot: (timeSlot) => dispatch(saveTimeSlot(timeSlot)),
    destroyTimeSlot: (timeSlot) => dispatch(destroyTimeSlot(timeSlot)),
  };
}

export default connect(null, mapDispatchToProps)(TimeSlotForm);
