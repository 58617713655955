import React from "react";
import { connect } from "react-redux";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import InstagramComment from "./InstagramComment";
import DashboardWidgetTypes from "../lib/DashboardWidgetTypes";

import exampleInstagramComments from "./exampleInstagramComments";

import { streamMockInstagramComments } from "../actions";

class InstagramCommentsViewWidget extends React.Component {
  static defaultProps = {
    config: DashboardWidgetTypes.Types.InstagramComments.DefaultState,
  };

  // left for developer convenience
  // componentDidMount() {
  //   this.props.streamMockInstagramComments()
  // }

  render() {
    const {
      className,
      config,
      instagramComments,
      streamMockInstagramComments,
      ...other
    } = this.props;
    const fullClassName = `dashboard-widget instagram-comments ${className}`;

    return (
      <div className={fullClassName} {...other}>
        <div className="instagram-comments-container">
          <ul className="instagram-comments-list list list-unstyled">
            <TransitionGroup>
              {(instagramComments || []).map((instagramComment) => (
                <CSSTransition
                  key={instagramComment.id}
                  classNames="instagram-comment"
                  timeout={{ enter: 500 }}
                >
                  <InstagramComment instagramComment={instagramComment} />
                </CSSTransition>
              ))}
            </TransitionGroup>
          </ul>
        </div>
      </div>
    );
  }
}

// Left for developer convenience.
const useExampleInstagramComments = false;

function mapStateToProps(state) {
  const instagramComments = useExampleInstagramComments
    ? exampleInstagramComments
    : state.instagramComments;
  return { instagramComments };
}

function mapDispatchToProps(dispatch) {
  return {
    streamMockInstagramComments: () => dispatch(streamMockInstagramComments()),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InstagramCommentsViewWidget);
