import React from "react";
import { withRouter } from "react-router-dom";
import UserAuth from "../lib/UserAuth";

import LoginForm from "../components/LoginForm";
import ForgotPasswordForm from "../components/ForgotPasswordForm";
import ChangePasswordForm from "../components/ChangePasswordForm";
import ForceChangePasswordForm from "../components/ForceChangePasswordForm";
import ErrorBanner from "../components/ErrorBanner";
import SuccessBanner from "../components/SuccessBanner";
import GradientBackground from "../components/GradientBackground";
import AppNavigator from "./AppNavigator";

const FlowStep = {
  LOGIN: "flowStep:login",
  FORGOT_PASSWORD: "flowStep:forgotPassword",
  CHANGE_PASSWORD: "flowStep:changePassword",
  FORCE_CHANGE_PASSWORD: "flowStep:forceChangePassword",
};

class LandingPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      flowStep: FlowStep.LOGIN,
      email: "",
      user: "",
      successMessage: "",
      error: "",
    };
    this.navigator = new AppNavigator(this.props.history);
  }

  handleNewPasswordRequired = (user) => {
    this.clearError();
    this.setState({ user });
    this.setFlowStep(FlowStep.FORCE_CHANGE_PASSWORD);
  };

  handleForgotPasswordClicked = () => {
    this.clearError();
    this.setFlowStep(FlowStep.FORGOT_PASSWORD);
  };

  handlePasswordReset = (email) => {
    this.clearError();
    this.setState({ email });
    this.setFlowStep(FlowStep.CHANGE_PASSWORD);
  };

  handlePasswordChanged = () => {
    this.clearError();
    this.setSuccessMessage("Successfully changed password");
    this.setFlowStep(FlowStep.LOGIN);
  };

  handleCancel = () => {
    this.clearError();
    this.setFlowStep(FlowStep.LOGIN);
  };

  handleError = (error) => {
    this.clearSuccessMessage();
    this.setError(error);
  };

  handleStateChange = (_state, _user) => {
    window.location.reload(); // TODO: gotta be a better way to do this
  };

  setFlowStep(flowStep) {
    this.setState({ flowStep });
  }

  setSuccessMessage(successMessage) {
    this.setState({ successMessage });
  }

  setError(error) {
    this.setState({ error });
  }

  clearSuccessMessage() {
    this.setSuccessMessage("");
  }

  clearError() {
    this.setError("");
  }

  render() {
    if (UserAuth.isAuthenticated(this.props)) {
      // either main app or unauthorized error page will render elsewhere
      return null;
    }

    const form = this.renderForm();

    return (
      <GradientBackground className="authentication ">
        <div className="uk-position-center">
          <h1 className="uk-light uk-text-center">
            <img
              src="/images/beats1.png"
              alt="Beats1"
              style={{ width: "100px" }}
            />
          </h1>
          <div className="uk-card uk-card-body uk-card-default uk-text-small uk-padding-remove-vertical uk-text-center uk-width-large vl-card">
            <div className="uk-section-xsmall">
              <ErrorBanner parent={this} />
              <SuccessBanner parent={this} />
              {form}
            </div>
          </div>
        </div>
      </GradientBackground>
    );
  }

  renderForm() {
    const { flowStep, email, user } = this.state;

    switch (flowStep) {
      case FlowStep.LOGIN:
        return (
          <LoginForm
            authData={this.props.authData}
            onStateChange={this.handleStateChange}
            onForgotPasswordClicked={this.handleForgotPasswordClicked}
            onNewPasswordRequired={this.handleNewPasswordRequired}
            onError={this.handleError}
          />
        );
      case FlowStep.FORGOT_PASSWORD:
        return (
          <ForgotPasswordForm
            onPasswordReset={this.handlePasswordReset}
            onCancel={this.handleCancel}
            onError={this.handleError}
          />
        );
      case FlowStep.CHANGE_PASSWORD:
        return (
          <ChangePasswordForm
            email={email}
            onPasswordChanged={this.handlePasswordChanged}
            onError={this.handleError}
          />
        );
      case FlowStep.FORCE_CHANGE_PASSWORD:
        return (
          <ForceChangePasswordForm
            user={user}
            onPasswordChanged={this.handlePasswordChanged}
            onError={this.handleError}
          />
        );
      default:
        throw new Error(`Invalid flow step: ${flowStep}`);
    }
  }
}

export default withRouter(LandingPage);
