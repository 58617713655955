import DashboardPreviewWidget from "./DashboardPreviewWidget";

export default class YouTubeLiveChatPreviewWidget extends DashboardPreviewWidget {
  constructor(props) {
    super(props);
    this.state = {
      name: "YouTube Live Chat",
      color: "#c4302b",
      stem: "youtube",
    };
  }
}
