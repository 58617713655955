import React from "react";
import { connect } from "react-redux";

// import { Formik } from "formik";
import UserAuth from "../lib/UserAuth";

import AbstractBasePage from "./AbstractBasePage";
import PageNotFoundPage from "./PageNotFoundPage";

import PageTitle from "../components/PageTitle";
// import ErrorBanner from "../components/ErrorBanner";
// import SuccessBanner from "../components/SuccessBanner";
// import UserTable from "../components/UserTable";
import { BreadcrumbItem } from "../components/Breadcrumbs";

import { setBreadcrumbItems, loadUsers } from "../actions";

class UserIndexPage extends AbstractBasePage {
  constructor(props) {
    super(props);
    this.state = { isAdmin: false, successMessage: "", error: "" };
  }

  handleInviteUser = async (values, { setSubmitting }) => {
    const { email } = values;
    if (!email) {
      this.setState({ error: "Enter email address" });
      setSubmitting(false);
      return;
    }

    try {
      await UserAuth.invite(email);
      if (this._isMounted) {
        this.setState({ successMessage: `Invited ${email}`, error: "" });
      }
    } catch (error) {
      if (this._isMounted) {
        this.setState({ successMessage: "", error: error });
      }
    }

    setSubmitting(false);
  };

  async componentDidMount() {
    this._isMounted = true;

    const isAdmin = await UserAuth.isCurrentUserAdmin();
    if (this._isMounted) {
      this.setState({ isAdmin });
    }

    this.props.loadUsers();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { isAdmin } = this.state;
    if (!isAdmin) {
      return <PageNotFoundPage />;
    }

    const { users } = this.props;
    if (!users) {
      return null;
    }

    // const self = this;
    return (
      <div>
        <PageTitle text="Users" />

        <div className="uk-alert uk-alert-warning">
          <p>Coming Soon.</p>
        </div>

        {/* <Formik
          initialValues={{}}
          validate={() => {}}
          onSubmit={this.handleInviteUser}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => {
            const error = touched.email && errors.email;

            return (
              <form
                className="uk-form-stacked uk-margin"
                onSubmit={handleSubmit}
              >
                <h3 className="uk-heading-small">Invite User</h3>

                <div className="uk-margin">
                  <div className="uk-form-controls">
                    <input
                      className="uk-input"
                      name="email"
                      type="email"
                      placeholder="Email"
                      value={values.email || ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                </div>

                {error && (
                  <div className="uk-margin uk-form-danger">{error}</div>
                )}

                <ErrorBanner parent={self} />
                <SuccessBanner parent={self} />

                <div className="uk-margin">
                  <button
                    className="uk-button uk-button-primary"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Invite
                  </button>
                </div>
              </form>
            );
          }}
        </Formik>

        <UserTable users={users} /> */}
      </div>
    );
  }

  getBreadcrumbItems() {
    const { isAdmin } = this.state;
    if (!isAdmin) {
      return [];
    }

    return [new BreadcrumbItem("Admin"), new BreadcrumbItem("Users")];
  }
}

function mapStateToProps(state) {
  const { breadcrumbItems, users } = state;
  return { breadcrumbItems, users };
}

function mapDispatchToProps(dispatch) {
  return {
    setBreadcrumbItems: (items) => dispatch(setBreadcrumbItems(items)),
    loadUsers: () => dispatch(loadUsers()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserIndexPage);
