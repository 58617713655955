import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import UserAuth from "./lib/UserAuth";

// import Header from "./components/Header";
import { SettingsMenu, Sidebar } from "./views/_shared/Bundle";
// import Breadcrumbs from "./components/Breadcrumbs";
// import WelcomeBanner from "./components/WelcomeBanner";
import ModalList from "./components/ModalList";
import ModelDefaultsLoader from "./components/ModelDefaultsLoader";

import {
  DashboardsIndex,
  DashboardsNew,
  DashboardsEdit,
} from "./views/dashboards/Bundle";

import LayoutNewPage from "./pages/LayoutNewPage";
import LayoutEditPage from "./pages/LayoutEditPage";
import LayoutViewPage from "./pages/LayoutViewPage";

import UserIndexPage from "./pages/UserIndexPage";

import TwitterFeedIndexPage from "./pages/TwitterFeedIndexPage";
import TwitterFeedNewPage from "./pages/TwitterFeedNewPage";
import TwitterFeedEditPage from "./pages/TwitterFeedEditPage";
import TwitterGlobalFilterPage from "./pages/TwitterGlobalFilterPage";
import TwitterAnalyticsPage from "./pages/TwitterAnalyticsPage";

import { ThemesIndex } from "./views/themes/Bundle";
import ThemeNewPage from "./pages/ThemeNewPage";
import ThemeEditPage from "./pages/ThemeEditPage";

import PageNotFoundPage from "./pages/PageNotFoundPage";

export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = { token: null };
  }

  componentDidMount() {
    const getToken = async () => {
      await UserAuth.refreshCachedAccessToken();
      const token = UserAuth.getCachedAccessToken();
      this.setState({ token });
    };

    getToken();
  }

  render() {
    if (!UserAuth.isAuthorized(this.props) || !this.state.token) {
      // either login page or unauthorized error page will render elsewhere
      return null;
    }

    return (
      <div className="uk-light">
        <ModelDefaultsLoader />

        <BrowserRouter>
          {/* <Header /> */}
          <SettingsMenu />
          <Sidebar />

          <div className="uk-section xuk-section-default tm-main">
            {/* <Breadcrumbs /> */}
            {/* <WelcomeBanner /> */}
            <div className="uk-container uk-position-relative uk-container-expand">
              <Switch>
                <Route exact path="/" component={DashboardsIndex} />
                <Route exact path="/dashboards" component={DashboardsIndex} />
                <Route exact path="/dashboards/new" component={DashboardsNew} />
                <Route
                  exact
                  path="/dashboards/:id"
                  component={DashboardsEdit}
                />
                <Route
                  exact
                  path="/dashboards/:dashboardId/layouts/new"
                  component={LayoutNewPage}
                />
                <Route
                  exact
                  path="/dashboards/:dashboardId/layouts/:id"
                  component={LayoutEditPage}
                />
                <Route
                  exact
                  path="/dashboards/:dashboardId/layouts/:id/view"
                  component={LayoutViewPage}
                />

                <Route exact path="/layouts/new" component={LayoutNewPage} />
                <Route exact path="/layouts/:id" component={LayoutEditPage} />

                <Route exact path="/admin/users" component={UserIndexPage} />

                <Route
                  exact
                  path="/social/twitter-feeds"
                  component={TwitterFeedIndexPage}
                />
                <Route
                  exact
                  path="/social/twitter-feeds/new"
                  component={TwitterFeedNewPage}
                />
                <Route
                  exact
                  path="/social/twitter-feeds/:id"
                  component={TwitterFeedEditPage}
                />
                <Route
                  exact
                  path="/social/twitter-global-filter"
                  component={TwitterGlobalFilterPage}
                />

                <Route
                  exact
                  path="/social/twitter-analytics"
                  component={TwitterAnalyticsPage}
                />

                <Route exact path="/themes/" component={ThemesIndex} />
                <Route exact path="/themes/new" component={ThemeNewPage} />
                <Route exact path="/themes/:id" component={ThemeEditPage} />

                <Route component={PageNotFoundPage} />
              </Switch>
            </div>
          </div>

          <ModalList />
        </BrowserRouter>
      </div>
    );
  }
}
