import React from "react";
import { withRouter } from "react-router-dom";
import AppNavigator from "../../pages/AppNavigator";
import API from "../../models/API";
import UserAuth from "../../lib/UserAuth";

/**
 * Will show a notification at the top of the screen.
 * @constructor
 * @param {string} message - Notification message to show.
 * @param {string} status - Notification status color.
 */

class SettingsMenu extends React.Component {
  constructor(props) {
    super(props);

    this.state = { userInfo: "" };

    this.navigator = new AppNavigator(this.props.history);
  }

  async componentDidMount() {
    this._isMounted = true;

    const userInfo = await UserAuth.basicCurrentUserInfo();
    if (this._isMounted) {
      this.setState({ userInfo });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleLinkTwitterClicked = () => {
    const origin = encodeURI(window.location.href);
    const url = API.url(`auth/twitter?origin=${origin}`);
    window.history.pushState({}, "", window.location.href);
    window.location.replace(url);
  };

  handleLinkYouTubeClicked = () => {
    this.linkAccountWithRedirect("google_oauth2");
  };

  handleLinkInstagramClicked = () => {
    this.linkAccountWithRedirect("facebook");
  };

  linkAccountWithRedirect(provider) {
    const origin = encodeURI(window.location.href);
    const url = API.url(`auth/${provider}/redirect?origin=${origin}`);
    window.history.pushState({}, "", window.location.href);
    window.location.replace(url);
  }

  handleLogoutClicked = async () => {
    await UserAuth.signOut();
  };

  render() {
    return (
      <div className="tm-auth-container">
        <div className="uk-inline">
          <button
            className="uk-icon-link"
            uk-icon="icon: user; ratio: 1"
          ></button>
          <div
            uk-dropdown="pos: bottom-right; mode: click"
            className="tm-dropdown-nav"
          >
            <ul className="uk-nav uk-dropdown-nav ">
              <li>{this.state.userInfo.email}</li>
              <li className="uk-nav-divider"></li>
              <li>
                <button
                  className="vl-button-link"
                  onClick={this.handleLinkTwitterClicked}
                >
                  Link Twitter account
                </button>
              </li>
              <li>
                <button
                  className="vl-button-link"
                  onClick={this.handleLinkYouTubeClicked}
                >
                  Link YouTube account
                </button>
              </li>
              <li>
                <button
                  className="vl-button-link"
                  onClick={this.handleLinkInstagramClicked}
                >
                  Link Instagram account
                </button>
              </li>
              <li className="uk-nav-divider"></li>
              <li>
                <button
                  className="vl-button-link"
                  onClick={this.handleLogoutClicked}
                >
                  Logout
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(SettingsMenu);
