import { connect } from "react-redux";

import BaseViewPage from "./BaseViewPage";
import { loadLayout } from "../actions";

class LayoutViewPage extends BaseViewPage {
  componentDidMount() {
    super.componentDidMount();

    const { id } = this.props.match.params;
    this.props.loadLayout(id);
  }
}

function mapStateToProps(state) {
  const { layout } = state;
  return { layout };
}

function mapDispatchToProps(dispatch) {
  return { loadLayout: (id) => dispatch(loadLayout(id)) };
}

export default connect(mapStateToProps, mapDispatchToProps)(LayoutViewPage);
