import React from "react";

import FormParsing from "../lib/FormParsing";
import UserAuth from "../lib/UserAuth";

export default class ForceChangePasswordForm extends React.Component {
  static defaultProps = {
    onPasswordChanged: () => {},
    onError: (_error) => {},
  };

  handleSubmit = async (event) => {
    const { user } = this.props;
    const { password } = FormParsing.consumeSubmit(event);

    try {
      await UserAuth.completeNewPassword(user, password);
      this.props.onPasswordChanged();
    } catch (error) {
      this.props.onError(error);
    }
  };

  render() {
    return (
      <div className="uk-flex uk-flex-column">
        <div>Enter your new password.</div>
        <div className="uk-margin-top uk-padding-remove-vertical">
          <form onSubmit={this.handleSubmit}>
            <input
              className="uk-input"
              placeholder="Password"
              type="password"
              name="password"
            />
            <input
              className="tm-button tm-button-primary"
              type="submit"
              name="commit"
              value="Change Password"
              data-disable-with="Change Password"
            />
          </form>
        </div>
      </div>
    );
  }
}
