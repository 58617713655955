import React from "react";
import { connect } from "react-redux";

import { loadTwitterGlobalFilter } from "../actions";

class TwitterGlobalFilterTable extends React.Component {
  componentDidMount() {
    this.props.loadTwitterGlobalFilter();
  }

  render() {
    const { twitterGlobalFilter } = this.props;
    if (!twitterGlobalFilter) {
      return null;
    }

    return (
      <table className="uk-table uk-table-small uk-table-divider uk-table-middle uk-margin">
        <thead>
          <tr>
            <th>Blocked Users</th>
            <th>Blocked Topics</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{this.listToText(twitterGlobalFilter.blocked_follows)}</td>
            <td>{this.listToText(twitterGlobalFilter.blocked_topics)}</td>
          </tr>
        </tbody>
      </table>
    );
  }

  listToText(list) {
    if (!list || list.length === 0) {
      return "None";
    }

    return list.join(", ");
  }
}

function mapStateToProps(state) {
  const { twitterGlobalFilter } = state;
  return { twitterGlobalFilter };
}

function mapDispatchToProps(dispatch) {
  return {
    loadTwitterGlobalFilter: () => dispatch(loadTwitterGlobalFilter()),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TwitterGlobalFilterTable);
