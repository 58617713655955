import React from "react";
// import Moment from "react-moment";
import { withRouter } from "react-router-dom";

import ButtonLink from "../components/ButtonLink";

import LayoutNavigator from "../pages/LayoutNavigator";

class LayoutRow extends React.Component {
  constructor(props) {
    super(props);
    this.navigator = new LayoutNavigator(this.props.history);
  }

  render() {
    const { dashboard, layout, theme } = this.props;
    if (!layout) {
      return null;
    }

    const pushEdit = this.navigator.pushEdit.bind(
      this.navigator,
      dashboard,
      layout
    );

    const displayName = layout.getDisplayName();
    const isDefault = layout.get("default");
    const gridLayout = layout.get("grid_layout");
    const themeName = theme.get("name");
    // const createdAt = layout.get("created_at");

    return (
      <tr>
        <td>
          <ButtonLink onClick={pushEdit}>{displayName}</ButtonLink>
        </td>
        <td>{isDefault ? <span uk-icon="check"></span> : null}</td>
        <td>{gridLayout.length}</td>
        <td>{themeName}</td>
        <td className="actions">{this.renderDeleteButton()}</td>
      </tr>
    );
  }

  renderDeleteButton() {
    const { layout, onDelete } = this.props;
    if (!layout || !onDelete || layout.get("default")) {
      return null;
    }

    return (
      <button
        className="uk-icon-button vl-table-delete"
        onClick={() => onDelete(layout)}
        uk-icon="icon: trash; scale: 0.75"
      ></button>
    );
  }
}
export default withRouter(LayoutRow);
