import React from "react";

import DashboardWidgetTypes from "../lib/DashboardWidgetTypes";

export default class DashboardWidget extends React.Component {
  static defaultProps = {
    index: undefined,
    onEdit: undefined,
    onDelete: undefined,
  };

  handleEditClicked = () => {
    const { onEdit, index } = this.props;
    if (onEdit) {
      onEdit(index);
    }
  };

  handleDeleteClicked = () => {
    const { onDelete, index } = this.props;
    if (onDelete) {
      onDelete(index);
    }
  };

  render() {
    const { className, style, onEdit, onDelete, ...other } = this.props;
    const { name, color, stem } = this.state;

    const src = DashboardWidgetTypes.iconSrc(stem);
    const alt = DashboardWidgetTypes.iconAlt(name);

    const editButton = this.renderEditButton();
    const deleteButton = this.renderDeleteButton();

    return (
      <div
        className={`dashboard-widget ${className}`}
        style={{ ...style, backgroundColor: color }}
        {...other}
      >
        <img className="image" src={src} alt={alt}></img>
        <div className="text">{name}</div>
        <div className="add">
          <span uk-icon="icon: plus; ratio: 0.8" />
        </div>
        {editButton}
        {deleteButton}

        {/* necessary for react-grid-layout to render resize handle */}
        {this.props.children}
      </div>
    );
  }

  // private

  renderEditButton() {
    const { editable } = this.state;
    if (!editable) {
      return null;
    }

    const { onEdit } = this.props;
    if (onEdit) {
      return (
        <button
          className="vl-edit-dashboard-widget"
          type="button"
          onClick={this.handleEditClicked}
        >
          <span uk-icon="pencil"></span>
        </button>
      );
    }
    return null;
  }

  renderDeleteButton() {
    const { onDelete } = this.props;
    if (onDelete) {
      return (
        <button
          className="vl-delete-dashboard-widget"
          type="button"
          onClick={this.handleDeleteClicked}
          uk-close=""
        />
      );
    }
    return null;
  }
}
