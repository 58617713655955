import AppNavigator from "./AppNavigator";

export default class ThemeNavigator extends AppNavigator {
  pushIndex = () => this.push(this.indexPath());

  pushNew = () => this.push(this.newPath());

  pushEdit = (theme) => this.push(this.editPath(theme));

  indexPath = () => "/themes";

  newPath = () => "/themes/new";

  editPath = (theme) => `/themes/${theme.get("id")}`;
}
