export default class FormParsing {
  // Prevent default behavior and return serialized hash of attributes.
  static consumeSubmit(event) {
    event.preventDefault();
    const data = new FormData(event.target);

    const iterated = {};
    for (let [key, value] of data.entries()) {
      iterated[key] = value;
    }
    return iterated;
  }
}
