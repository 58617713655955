import DashboardWidget from "./DashboardWidget";

export default class NowPlayingWidget extends DashboardWidget {
  constructor(props) {
    super(props);
    this.state = {
      name: "Now Playing",
      color: "#f75f71",
      stem: "now-playing",
      editable: true,
    };
  }
}
