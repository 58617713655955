import React from "react";

// Displays static JPG or animated GIF (as autoplaying, looping video).
export default class TwitterImage extends React.Component {
  render() {
    const { image } = this.props;

    if (image.type === "photo") {
      const { media_url_https } = image;
      return (
        <img className="tweet-image" alt="From Twitter" src={media_url_https} />
      );
    }

    if (image.type === "animated_gif") {
      const { variants } = image.video_info;

      // always seems to be exactly 1 variant
      const { content_type, url } = variants[0];

      return (
        <video
          className="tweet-image"
          type={content_type}
          src={url}
          autoPlay={true}
          loop={true}
        />
      );
    }

    return null;
  }
}
