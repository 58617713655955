import DashboardWidget from "./DashboardWidget";

export default class RadioScheduleWidget extends DashboardWidget {
  constructor(props) {
    super(props);
    this.state = {
      name: "Schedule",
      color: "#29ccbf",
      stem: "schedule",
      editable: true,
    };
  }
}
