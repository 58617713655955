import React from "react";

import FormParsing from "../lib/FormParsing";
import UserAuth from "../lib/UserAuth";

export default class ForgotPasswordForm extends React.Component {
  static defaultProps = {
    onPasswordReset: () => {},
    onCancel: () => {},
    onError: (_error) => {},
  };

  handleSubmit = async (event) => {
    const { email } = FormParsing.consumeSubmit(event);

    if (!email) {
      this.props.onError("Enter email");
      return;
    }

    try {
      await UserAuth.forgotPassword(email);
      this.props.onPasswordReset(email);
    } catch (error) {
      this.props.onError(error);
    }
  };

  render() {
    return (
      <div className="uk-flex uk-flex-column">
        <div>Enter your email to reset your password.</div>
        <div className="uk-margin-top uk-padding-remove-vertical">
          <form onSubmit={this.handleSubmit}>
            <input
              className="uk-input"
              placeholder="Email"
              type="email"
              name="email"
            />
            <input
              className="tm-button tm-button-primary tm-button-caution uk-margin-top"
              type="submit"
              name="commit"
              value="Reset Password"
              data-disable-with="Reset Password"
            />
          </form>
        </div>
        <div className="uk-margin-small-bottom">
          <button
            className="uk-button-link auth-link"
            onClick={this.props.onCancel}
          >
            Cancel
          </button>
        </div>
      </div>
    );
  }
}
