import React from "react";
import { connect } from "react-redux";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import Tweet from "./Tweet";
import DashboardWidgetTypes from "../lib/DashboardWidgetTypes";

import TwitterFeedModel from "../models/TwitterFeedModel";

import { subscribeTwitterFeed } from "../actions";

import exampleTweets from "./exampleTweets";

class TwitterFeedViewWidget extends React.Component {
  static defaultProps = {
    config: DashboardWidgetTypes.Types.TwitterFeed.DefaultState,
  };

  async componentDidMount() {
    const { subscribeTwitterFeed, config } = this.props;
    const { twitterFeedID } = config;
    if (!twitterFeedID) {
      return;
    }

    subscribeTwitterFeed(twitterFeedID);
  }

  render() {
    const {
      className,
      config,
      subscribeTwitterFeed,
      twitterFeedTweets,
      ...other
    } = this.props;
    const fullClassName = `dashboard-widget twitter-feed ${className}`;

    const { twitterFeedID, imageDisplay } = config;

    const twitterFeed = TwitterFeedModel.findOrCreate({ id: twitterFeedID });
    const twitterFeedName = twitterFeed.get("name");

    return (
      <div className={fullClassName} {...other}>
        {twitterFeedName === "" ? null : (
          <div className="twitter-feed-widget-name">{twitterFeedName}</div>
        )}

        <div className="twitter-feed-feeds">
          <ul className="tweet-feed tweet-feed-tweets list list-unstyled">
            <TransitionGroup>
              {((twitterFeedTweets || {})[twitterFeedID] || []).map((tweet) => (
                <CSSTransition
                  key={tweet.id_str}
                  classNames="tweet"
                  timeout={{ enter: 500 }}
                >
                  <Tweet
                    key={tweet.id_str}
                    tweet={tweet}
                    imageDisplay={imageDisplay}
                  />
                </CSSTransition>
              ))}
            </TransitionGroup>
          </ul>
        </div>
      </div>
    );
  }
}

// Acts like an object but returns exampleTweets for any key.
const exampleTweetsProxy = new Proxy({}, { get: () => exampleTweets });

// Left for developer convenience.
const useExampleTweets = false;

function mapStateToProps(state) {
  const twitterFeedTweets = useExampleTweets
    ? exampleTweetsProxy
    : state.twitterFeedTweets;
  return { twitterFeedTweets };
}

function mapDispatchToProps(dispatch) {
  return {
    subscribeTwitterFeed: (twitterFeedID) =>
      dispatch(subscribeTwitterFeed(twitterFeedID)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TwitterFeedViewWidget);
