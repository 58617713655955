import React from "react";

export default class ErrorBanner extends React.Component {
  handleClose = () => {
    const { parent } = this.props;
    parent.setState({ error: "" });
  };

  render() {
    const { error } = this.props.parent.state || {};
    if (!error) {
      return null;
    }

    const message = error.message || error;
    if (!message) {
      return null;
    }

    return (
      <div className="uk-alert-danger uk-alert" uk-alert="">
        {/* <button
          style={{ paddingTop: "-5px" }}
          className="uk-button-link uk-alert-close uk-icon"
          uk-close=""
          onClick={this.handleClose}
        /> */}
        <p>{message}</p>
      </div>
    );
  }
}
