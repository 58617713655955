import React from "react";

import LayoutEditorCanvas from "./LayoutEditorCanvas";
import WidgetToolbar from "./WidgetToolbar";

export default class LayoutEditor extends React.Component {
  render() {
    return (
      <div style={{ overflow: "scroll" }}>
        <div className="layout-editor-container">
          <div className="layout-editor-canvas-container">
            <h5>Layout</h5>
            <LayoutEditorCanvas />
          </div>
          <div className="widget-toolbar-container">
            <h5>Widgets</h5>
            <WidgetToolbar />
          </div>
        </div>
      </div>
    );
  }
}
