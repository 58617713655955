import TwitterFeedModel from "../models/TwitterFeedModel";

export default class TwitterFormParsing {
  static setAttributesFromFormValues(twitterFeed, values) {
    const feedId = twitterFeed.get("id");
    const valuesId = values.id;
    if (feedId && valuesId) {
      // sanity check for development bug
      if (feedId !== valuesId) {
        throw new Error(
          `Mismatched feed ids: "${feedId}" versus "${valuesId}"`
        );
      }

      // delete the id or backbone will complain that instance already exists
      values = { ...values };
      delete values.id;
    }

    const converted = this.convertFormValuesToAttributes(values);
    const attributes = { ...twitterFeed.attributes, ...converted };
    twitterFeed.set(attributes);
  }

  static getFormValuesFromAttributes(twitterFeed) {
    return this.convertAttributesToFormValues(twitterFeed.attributes);
  }

  static createFromFormValues(values) {
    const attributes = this.convertFormValuesToAttributes(values);
    return TwitterFeedModel.findOrCreate(attributes);
  }

  static commaSeparate(strings) {
    return strings
      .map((s) => s.trim())
      .filter((s) => s !== "")
      .join(", ");
  }

  // private

  static arrayNames = [
    "topics",
    "follows",
    "blocked_topics",
    "blocked_follows",
  ];

  static convertFormValuesToAttributes(values) {
    const attributes = { ...values };

    this.arrayNames.forEach((arrayName) => {
      if (!!attributes[arrayName]) {
        attributes[arrayName] = attributes[arrayName]
          .split(",")
          .map((s) => s.trim());
      } else {
        attributes[arrayName] = [];
      }
    });

    return attributes;
  }

  static convertAttributesToFormValues(attributes) {
    const values = { ...attributes };

    this.arrayNames.forEach((arrayName) => {
      values[arrayName] = (values[arrayName] || [])
        .map((s) => s.trim())
        .join(", ");
    });

    return values;
  }
}
