import React from "react";
// import Moment from "react-moment";
import { withRouter } from "react-router-dom";

import ButtonLink from "./ButtonLink";

import TwitterFeedNavigator from "../pages/TwitterFeedNavigator";

import TwitterFormParsing from "../lib/TwitterFormParsing";

class TwitterFeedRow extends React.Component {
  constructor(props) {
    super(props);
    this.navigator = new TwitterFeedNavigator(this.props.history);
  }

  render() {
    const { twitterFeed } = this.props;
    if (!twitterFeed) {
      return null;
    }

    const pushEdit = this.navigator.pushEdit.bind(this.navigator, twitterFeed);

    const displayName = twitterFeed.getDisplayName();
    // const createdAt = twitterFeed.get("created_at");
    const requireApproval = twitterFeed.get("require_approval");
    const topics = twitterFeed.get("topics");
    const follows = twitterFeed.get("follows");
    const blocked = twitterFeed
      .get("blocked_topics")
      .concat(twitterFeed.get("blocked_follows"));

    return (
      <tr>
        <td>
          <ButtonLink onClick={pushEdit}>{displayName}</ButtonLink>
        </td>
        <td>{requireApproval ? <span uk-icon="check" /> : null}</td>

        <td>{TwitterFormParsing.commaSeparate(topics)}</td>
        <td>{TwitterFormParsing.commaSeparate(follows)}</td>
        <td>{TwitterFormParsing.commaSeparate(blocked)}</td>

        <td className="actions">{this.renderDeleteButton()}</td>
      </tr>
    );
  }

  renderDeleteButton() {
    const { twitterFeed, onDelete } = this.props;
    if (!twitterFeed || !onDelete) {
      return null;
    }

    return (
      <button
        className="uk-icon-button vl-table-delete"
        onClick={() => onDelete(twitterFeed)}
        uk-icon="icon: trash; scale: 0.75"
      ></button>
    );
  }
}
export default withRouter(TwitterFeedRow);
