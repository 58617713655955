import Backbone from "backbone";
import "backbone-relational";

import API from "./API";
import AuthenticatedModel from "./AuthenticatedModel";

import TimeSlotModel from "./TimeSlotModel";
import TimeSlotCollection from "./TimeSlotCollection";

const LayoutModel = AuthenticatedModel.extend({
  urlRoot: API.urlRoot("layouts"),

  relations: [
    {
      type: Backbone.HasMany,
      key: "time_slots",
      relatedModel: TimeSlotModel,
      collectionType: TimeSlotCollection,

      reverseRelation: {
        key: "layout",
      },
    },
  ],

  getDisplayName: function () {
    return this.get("name") || "Untitled Layout";
  },

  getStaticGridLayout: function () {
    const gridLayout = this.get("grid_layout");
    for (const item of gridLayout) {
      item.static = true;
    }
    return gridLayout;
  },

  // Hacky comparison method to help dedupe layout updates.
  isLike(gridLayout, widgetData) {
    return (
      JSON.stringify(this.get("grid_layout")) === JSON.stringify(gridLayout) &&
      JSON.stringify(this.get("widget_data")) === JSON.stringify(widgetData)
    );
  },
});
export default LayoutModel;
