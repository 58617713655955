const exampleTweets = [
  {
    id_str: "1148652370336858115",
    username: "𝙆𝙖𝙮 👼🏻",
    user_screen_name: "bulletbat",
    created_at: "2019-07-09T17:57:20.000Z",
    text:
      "me: mkay let’s sleep \nmy brain: the world is ending in 2040?? well man thAnks because being in a punk rock band won… https://t.co/XuGHihzXJs",
    favorite_count: 0,
    retweet_count: 0,
    in_reply_to_screen_name: null,
    retweeted_status: null,
  },
  {
    id_str: "1148652367585390592",
    username: "D E M O N I A",
    user_screen_name: "deariadnag",
    created_at: "2019-07-09T17:57:20.000Z",
    text:
      "RT @SpiderManMovie: what more can I say? 🕷🎶 https://t.co/cduWATX3EH",
    favorite_count: 0,
    retweet_count: 0,
    in_reply_to_screen_name: null,
    retweeted_status: {
      id_str: "1148472163453693952",
      username: "Spider-Man: Far From Home",
      user_screen_name: "SpiderManMovie",
      created_at: "2019-07-09T06:01:16.000Z",
      text: "what more can I say? 🕷🎶 https://t.co/cduWATX3EH",
      favorite_count: 80511,
      retweet_count: 23483,
      in_reply_to_screen_name: null,
      retweeted_status: null,
    },
  },
  {
    id_str: "1148652362745053184",
    username: "カイラ",
    user_screen_name: "KYLAMEL1SSE",
    created_at: "2019-07-09T17:57:18.000Z",
    text:
      "RT @rxbbersoul: she was a punk            he did ballet https://t.co/7t09D7UIb8",
    favorite_count: 0,
    retweet_count: 0,
    in_reply_to_screen_name: null,
    retweeted_status: {
      id_str: "1148002181011910656",
      username: "Aida",
      user_screen_name: "rxbbersoul",
      created_at: "2019-07-07T22:53:43.000Z",
      text: "she was a punk            he did ballet https://t.co/7t09D7UIb8",
      favorite_count: 180826,
      retweet_count: 51350,
      in_reply_to_screen_name: null,
      retweeted_status: null,
    },
  },
  {
    id_str: "1148652361210126341",
    username: "crippled old yippster",
    user_screen_name: "teenageyippie",
    created_at: "2019-07-09T17:57:18.000Z",
    text: "RT @77MASH: #Punk - #Japan - #80s https://t.co/oh2o2OPzEy",
    favorite_count: 0,
    retweet_count: 0,
    in_reply_to_screen_name: null,
    retweeted_status: {
      id_str: "1148288629766336518",
      username: "SheenaIsAPunkRocker 🎸",
      user_screen_name: "77MASH",
      created_at: "2019-07-08T17:51:58.000Z",
      text: "#Punk - #Japan - #80s https://t.co/oh2o2OPzEy",
      favorite_count: 22,
      retweet_count: 8,
      in_reply_to_screen_name: null,
      retweeted_status: null,
    },
  },
  {
    id_str: "1148652356797530114",
    username: "AshleyB132",
    user_screen_name: "B132Ashley",
    created_at: "2019-07-09T17:57:17.000Z",
    text:
      "RT @rxbbersoul: she was a punk            he did ballet https://t.co/7t09D7UIb8",
    favorite_count: 0,
    retweet_count: 0,
    in_reply_to_screen_name: null,
    retweeted_status: {
      id_str: "1148002181011910656",
      username: "Aida",
      user_screen_name: "rxbbersoul",
      created_at: "2019-07-07T22:53:43.000Z",
      text: "she was a punk            he did ballet https://t.co/7t09D7UIb8",
      favorite_count: 180821,
      retweet_count: 51349,
      in_reply_to_screen_name: null,
      retweeted_status: null,
    },
  },
  {
    id_str: "1148652356063678466",
    username: "sh@tty",
    user_screen_name: "ughhhsucks",
    created_at: "2019-07-09T17:57:17.000Z",
    text:
      "RT @SpiderManMovie: what more can I say? 🕷🎶 https://t.co/cduWATX3EH",
    favorite_count: 0,
    retweet_count: 0,
    in_reply_to_screen_name: null,
    retweeted_status: {
      id_str: "1148472163453693952",
      username: "Spider-Man: Far From Home",
      user_screen_name: "SpiderManMovie",
      created_at: "2019-07-09T06:01:16.000Z",
      text: "what more can I say? 🕷🎶 https://t.co/cduWATX3EH",
      favorite_count: 80498,
      retweet_count: 23480,
      in_reply_to_screen_name: null,
      retweeted_status: null,
    },
  },
  {
    id_str: "1148652354314690563",
    username: "amy ♔",
    user_screen_name: "ammaarah05",
    created_at: "2019-07-09T17:57:16.000Z",
    text:
      "RT @rxbbersoul: she was a punk            he did ballet https://t.co/7t09D7UIb8",
    favorite_count: 0,
    retweet_count: 0,
    in_reply_to_screen_name: null,
    retweeted_status: {
      id_str: "1148002181011910656",
      username: "Aida",
      user_screen_name: "rxbbersoul",
      created_at: "2019-07-07T22:53:43.000Z",
      text: "she was a punk            he did ballet https://t.co/7t09D7UIb8",
      favorite_count: 158180,
      retweet_count: 29443,
      in_reply_to_screen_name: null,
      retweeted_status: null,
    },
  },
  {
    id_str: "1148652352989061120",
    username: "JesterPunk",
    user_screen_name: "Jestersmokes",
    created_at: "2019-07-09T17:57:16.000Z",
    text:
      "@WWEUniverse @WWE @shanemcmahon Cm punk needs to come back and shut Shane up! https://t.co/lnmGIJe3ID",
    favorite_count: 0,
    retweet_count: 0,
    in_reply_to_screen_name: "WWEUniverse",
    retweeted_status: null,
  },
  {
    id_str: "1148652349352763393",
    username: "ANTI-AGING",
    user_screen_name: "xartpunk",
    created_at: "2019-07-09T17:57:15.000Z",
    text: "Aku teringin nak pergi indonesia celtic punk night.",
    favorite_count: 0,
    retweet_count: 0,
    in_reply_to_screen_name: null,
    retweeted_status: null,
  },
  {
    id_str: "1148652348690092039",
    username: "bi sex uau",
    user_screen_name: "lettyhi_",
    created_at: "2019-07-09T17:57:15.000Z",
    text:
      "RT @rxbbersoul: she was a punk            he did ballet https://t.co/7t09D7UIb8",
    favorite_count: 0,
    retweet_count: 0,
    in_reply_to_screen_name: null,
    retweeted_status: {
      id_str: "1148002181011910656",
      username: "Aida",
      user_screen_name: "rxbbersoul",
      created_at: "2019-07-07T22:53:43.000Z",
      text: "she was a punk            he did ballet https://t.co/7t09D7UIb8",
      favorite_count: 158174,
      retweet_count: 29442,
      in_reply_to_screen_name: null,
      retweeted_status: null,
    },
  },
  {
    id_str: "1148652348601851904",
    username: "Katie Kaniewski",
    user_screen_name: "katiekanoosk",
    created_at: "2019-07-09T17:57:15.000Z",
    text:
      "RT @SpiderManMovie: what more can I say? 🕷🎶 https://t.co/cduWATX3EH",
    favorite_count: 0,
    retweet_count: 0,
    in_reply_to_screen_name: null,
    retweeted_status: {
      id_str: "1148472163453693952",
      username: "Spider-Man: Far From Home",
      user_screen_name: "SpiderManMovie",
      created_at: "2019-07-09T06:01:16.000Z",
      text: "what more can I say? 🕷🎶 https://t.co/cduWATX3EH",
      favorite_count: 91670,
      retweet_count: 23461,
      in_reply_to_screen_name: null,
      retweeted_status: null,
    },
  },
  {
    id_str: "1148652347775692800",
    username: "danieℓℓa",
    user_screen_name: "dreamxnite",
    created_at: "2019-07-09T17:57:15.000Z",
    text:
      "RT @rxbbersoul: she was a punk            he did ballet https://t.co/7t09D7UIb8",
    favorite_count: 0,
    retweet_count: 0,
    in_reply_to_screen_name: null,
    retweeted_status: {
      id_str: "1148002181011910656",
      username: "Aida",
      user_screen_name: "rxbbersoul",
      created_at: "2019-07-07T22:53:43.000Z",
      text: "she was a punk            he did ballet https://t.co/7t09D7UIb8",
      favorite_count: 158173,
      retweet_count: 29441,
      in_reply_to_screen_name: null,
      retweeted_status: null,
    },
  },
  {
    id_str: "1148652343283650560",
    username: "unrepentant repenter⳩Ⓐ",
    user_screen_name: "ChasePadusniak",
    created_at: "2019-07-09T17:57:14.000Z",
    text:
      "@go_oat Ironically, I included punk because I expected people to get on my case if I didn’t do so. I had thought to just put “rock.”",
    favorite_count: 0,
    retweet_count: 0,
    in_reply_to_screen_name: "go_oat",
    retweeted_status: null,
  },
  {
    id_str: "1148652339949002752",
    username: "The Cutthroat Brothers",
    user_screen_name: "Cutthroat_Bros",
    created_at: "2019-07-09T17:57:13.000Z",
    text:
      "RT @history_of_punk: The Runaways backstage at CBGB's in New York on August 2, 1976 (Photo by Richard E. Aaron/Redferns)\n\n#punk #punks #pun…",
    favorite_count: 0,
    retweet_count: 0,
    in_reply_to_screen_name: null,
    retweeted_status: {
      id_str: "1148416574015123456",
      username: "History Of Punk",
      user_screen_name: "history_of_punk",
      created_at: "2019-07-09T02:20:22.000Z",
      text:
        "The Runaways backstage at CBGB's in New York on August 2, 1976 (Photo by Richard E. Aaron/Redferns)\n\n#punk #punks… https://t.co/t4xdFLaFEj",
      favorite_count: 125,
      retweet_count: 24,
      in_reply_to_screen_name: null,
      retweeted_status: null,
    },
  },
  {
    id_str: "1148652339848450048",
    username: "kaylah murray🤪",
    user_screen_name: "photos426",
    created_at: "2019-07-09T17:57:13.000Z",
    text:
      "RT @rxbbersoul: she was a punk            he did ballet https://t.co/7t09D7UIb8",
    favorite_count: 0,
    retweet_count: 0,
    in_reply_to_screen_name: null,
    retweeted_status: {
      id_str: "1148002181011910656",
      username: "Aida",
      user_screen_name: "rxbbersoul",
      created_at: "2019-07-07T22:53:43.000Z",
      text: "she was a punk            he did ballet https://t.co/7t09D7UIb8",
      favorite_count: 158157,
      retweet_count: 29440,
      in_reply_to_screen_name: null,
      retweeted_status: null,
    },
  },
  {
    id_str: "1148652334949523457",
    username: "𝚉𝚎𝚎 🌻🌹",
    user_screen_name: "confused_cheol",
    created_at: "2019-07-09T17:57:12.000Z",
    text:
      "RT @SpiderManMovie: what more can I say? 🕷🎶 https://t.co/cduWATX3EH",
    favorite_count: 0,
    retweet_count: 0,
    in_reply_to_screen_name: null,
    retweeted_status: {
      id_str: "1148472163453693952",
      username: "Spider-Man: Far From Home",
      user_screen_name: "SpiderManMovie",
      created_at: "2019-07-09T06:01:16.000Z",
      text: "what more can I say? 🕷🎶 https://t.co/cduWATX3EH",
      favorite_count: 80481,
      retweet_count: 23478,
      in_reply_to_screen_name: null,
      retweeted_status: null,
    },
  },
  {
    id_str: "1148652334517489670",
    username: "becaa",
    user_screen_name: "becafreitas4",
    created_at: "2019-07-09T17:57:12.000Z",
    text:
      "RT @rxbbersoul: she was a punk            he did ballet https://t.co/7t09D7UIb8",
    favorite_count: 0,
    retweet_count: 0,
    in_reply_to_screen_name: null,
    retweeted_status: {
      id_str: "1148002181011910656",
      username: "Aida",
      user_screen_name: "rxbbersoul",
      created_at: "2019-07-07T22:53:43.000Z",
      text: "she was a punk            he did ballet https://t.co/7t09D7UIb8",
      favorite_count: 158152,
      retweet_count: 29439,
      in_reply_to_screen_name: null,
      retweeted_status: null,
    },
  },
  {
    id_str: "1148652333762588677",
    username: "arquiduque da cacimbinha",
    user_screen_name: "henriqdp",
    created_at: "2019-07-09T17:57:11.000Z",
    text:
      "mais uma vez me encontro em situação de Daft Punk - Get Lucky (Josso Ares Remix)",
    favorite_count: 0,
    retweet_count: 0,
    in_reply_to_screen_name: null,
    retweeted_status: null,
  },
  {
    id_str: "1148652331329826822",
    username: "ill yaya",
    user_screen_name: "MaiasMind_",
    created_at: "2019-07-09T17:57:11.000Z",
    text: "Guys, I just got Punk'd. At my big age.",
    favorite_count: 0,
    retweet_count: 0,
    in_reply_to_screen_name: null,
    retweeted_status: null,
  },
  {
    id_str: "1148652330696552448",
    username: "clels is ready for ffh;",
    user_screen_name: "pureathearthes",
    created_at: "2019-07-09T17:57:11.000Z",
    text:
      "RT @SpiderManMovie: what more can I say? 🕷🎶 https://t.co/cduWATX3EH",
    favorite_count: 0,
    retweet_count: 0,
    in_reply_to_screen_name: null,
    retweeted_status: {
      id_str: "1148472163453693952",
      username: "Spider-Man: Far From Home",
      user_screen_name: "SpiderManMovie",
      created_at: "2019-07-09T06:01:16.000Z",
      text: "what more can I say? 🕷🎶 https://t.co/cduWATX3EH",
      favorite_count: 80481,
      retweet_count: 23477,
      in_reply_to_screen_name: null,
      retweeted_status: null,
    },
  },
  {
    id_str: "1148002181011910656",
    username: "Aida",
    user_screen_name: "rxbbersoul",
    created_at: "2019-07-07T22:53:43.000Z",
    text: "she was a punk            he did ballet https://t.co/7t09D7UIb8",
    favorite_count: 158196,
    retweet_count: 29445,
    in_reply_to_screen_name: null,
    retweeted_status: null,
  },
];
export default exampleTweets;
