export default class AppNavigator {
  constructor(history) {
    this.history = history;
  }

  pushRoot = () => this.push(this.rootPath());

  rootPath = () => "/";

  push = (pathname = "") => this.history.push(pathname);
}
