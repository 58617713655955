import React from "react";
import { Formik } from "formik";
import DashboardWidgetTypes from "../lib/DashboardWidgetTypes";

export default class NowPlayingForm extends React.Component {
  defaultState = DashboardWidgetTypes.Types.NowPlaying.DefaultState;

  render() {
    const { subwidgetDataItem, setSubmitForm, onSubmit } = this.props;
    const config = this.normalizeConfig(subwidgetDataItem.config);
    const labelStyle = { color: "#1C1C1C" };

    return (
      <Formik
        initialValues={config}
        onSubmit={onSubmit}
        render={({ values, handleChange, handleBlur, submitForm }) => {
          setSubmitForm(submitForm);

          return (
            <div className="uk-form-stacked uk-margin">
              <div className="uk-margin">
                <label className="uk-form-label">Name</label>
                <div className="uk-form-controls">
                  <input
                    className="uk-input"
                    type="text"
                    name="name"
                    placeholder="Name of the widget"
                    value={values.name || ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
              </div>

              <div className="uk-margin-small">
                <div className="uk-form-controls">
                  <label className="uk-form-label" style={labelStyle}>
                    <input
                      className="uk-checkbox uk-margin-small-right"
                      type="checkbox"
                      name="showLogo"
                      checked={values.showLogo || false}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    Show Logo
                  </label>
                </div>
              </div>

              <div className="uk-margin-small">
                <div className="uk-form-controls">
                  <label className="uk-form-label" style={labelStyle}>
                    <input
                      className="uk-checkbox uk-margin-small-right"
                      type="checkbox"
                      name="showStationName"
                      checked={values.showStationName || false}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    Show Station Name
                  </label>
                </div>
              </div>

              <div className="uk-margin-small">
                <div className="uk-form-controls">
                  <label className="uk-form-label" style={labelStyle}>
                    <input
                      className="uk-checkbox uk-margin-small-right"
                      type="checkbox"
                      name="showOnAir"
                      checked={values.showOnAir || false}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    Show On Air
                  </label>
                </div>
              </div>

              <div className="uk-margin">
                <label className="uk-form-label" style={labelStyle}>
                  Transition Type
                </label>
                <div className="uk-form-controls">
                  <select
                    className="uk-select"
                    name="transitionType"
                    value={values.transitionType}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <option value="none">None</option>
                    <option value="slide">Slide</option>
                    <option value="fade">Fade</option>
                    <option value="flip">Flip</option>
                  </select>
                </div>
              </div>

              <div className="uk-margin">
                <label className="uk-form-label" style={labelStyle}>
                  <input
                    className="uk-input-xsmall uk-form-width-xsmall uk-margin-small-right"
                    type="color"
                    name="backgroundColor"
                    placeholder=""
                    value={values.backgroundColor || "#000000"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  Background Color
                </label>
              </div>

              <div className="uk-margin">
                <label className="uk-form-label" style={labelStyle}>
                  <input
                    className="uk-input-xsmall uk-form-width-xsmall uk-margin-small-right"
                    type="color"
                    name="onAirFontColor"
                    placeholder=""
                    value={values.onAirFontColor || "#000000"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  On Air Font Color
                </label>
              </div>

              <div className="uk-margin">
                <label className="uk-form-label" style={labelStyle}>
                  <input
                    className="uk-input-xsmall uk-form-width-xsmall uk-margin-small-right"
                    type="color"
                    name="stationFontColor"
                    value={values.stationFontColor || "#000000"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  Station Font Color
                </label>
              </div>

              <div className="uk-margin">
                <label className="uk-form-label" style={labelStyle}>
                  <input
                    className="uk-input-xsmall uk-form-width-xsmall uk-margin-small-right"
                    type="color"
                    name="trackInfoFontColor"
                    value={values.trackInfoFontColor || "#000000"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  Track Info Font Color
                </label>
              </div>
            </div>
          );
        }}
      />
    );
  }

  normalizeConfig(config) {
    // mostly the schema gets goofy during development;
    // should not be an issue once schema stabilizes
    const {
      name,
      showLogo,
      showStationName,
      showOnAir,
      transitionType,
      backgroundColor,
      onAirFontColor,
      stationFontColor,
      trackInfoFontColor,
    } = config || {};
    const normalized = {
      name,
      showLogo,
      showStationName,
      showOnAir,
      transitionType,
      backgroundColor,
      onAirFontColor,
      stationFontColor,
      trackInfoFontColor,
    };

    // undefined values will break controlled form inputs
    if (normalized.name === undefined) {
      normalized.name = this.defaultState.name;
    }
    if (normalized.showLogo === undefined || normalized.showLogo === "") {
      normalized.showLogo = this.defaultState.showLogo;
    }
    if (
      normalized.showStationName === undefined ||
      normalized.showStationName === ""
    ) {
      normalized.showStationName = this.defaultState.showStationName;
    }
    if (normalized.showOnAir === undefined || normalized.showOnAir === "") {
      normalized.showOnAir = this.defaultState.showOnAir;
    }
    if (
      normalized.transitionType === undefined ||
      normalized.transitionType === ""
    ) {
      normalized.transitionType = this.defaultState.transitionType;
    }
    if (normalized.backgroundColor === undefined) {
      normalized.backgroundColor = this.defaultState.backgroundColor;
    }
    if (normalized.onAirFontColor === undefined) {
      normalized.onAirFontColor = this.defaultState.onAirFontColor;
    }
    if (normalized.stationFontColor === undefined) {
      normalized.stationFontColor = this.defaultState.stationFontColor;
    }
    if (normalized.trackInfoFontColor === undefined) {
      normalized.trackInfoFontColor = this.defaultState.trackInfoFontColor;
    }

    return normalized;
  }
}
