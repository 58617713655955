import React from "react";

import { Field } from "formik";

import { ControlledEditor } from "@monaco-editor/react";

class InnerFormikMonacoEditor extends React.Component {
  onFieldChange = (_event, value) => {
    const { field, form } = this.props;
    if (!field || !form) {
      return;
    }

    form.setFieldValue(field.name, value);
  };

  onFieldBlur = () => {
    const { field, form } = this.props;
    if (!field || !form) {
      return;
    }

    form.setFieldTouched(field.name, true);
  };

  render() {
    const { field, disabled } = this.props;
    if (!field) {
      return null;
    }

    const monacoOptions = {
      selectOnLineNumbers: true,
      readOnly: disabled,
    };

    return (
      <ControlledEditor
        name={field.name}
        value={field.value}
        width="100%"
        height="600px"
        language="scss"
        theme="vs-dark"
        options={monacoOptions}
        onChange={this.onFieldChange}
        onBlur={this.onFieldBlur}
      />
    );
  }
}

export default class FormikMonacoEditor extends React.Component {
  render() {
    return <Field {...this.props} component={InnerFormikMonacoEditor} />;
  }
}
