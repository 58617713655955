const exampleChatMessages = {
  kind: "youtube#liveChatMessageListResponse",
  etag: '"0UM_wBUsFuT6ekiIlwaHvyqc80M/uiLcv-mZXBUnuhoMmMEs-LVx6cY"',
  nextPageToken: "GNKZs5XYj-QCIIHxuu3dj-QC",
  pollingIntervalMillis: 1000,
  pageInfo: {
    totalResults: 3,
    resultsPerPage: 3,
  },
  items: [
    {
      kind: "youtube#liveChatMessage",
      etag: '"0UM_wBUsFuT6ekiIlwaHvyqc80M/ReuP4AHrHN3wIRCgqfix8kczy1Q"',
      id:
        "LCC.CiMSIQoYVUNZem9xcm9UNndhZHJjM0tSdmFsS2NREgUvbGl2ZRI5ChpDT3I1NzR2RWotUUNGUWJmZ2dvZHU4OEJJdxIbQ1BDMG1vTEVqLVFDRlZpSHhBb2RlMWtKZVEw",
      snippet: {
        type: "textMessageEvent",
        liveChatId: "EiEKGFVDWXpvcXJvVDZ3YWRyYzNLUnZhbEtjURIFL2xpdmU",
        authorChannelId: "UCN-hWk0vRANrLe4t1TWgfQg",
        publishedAt: "2019-08-19T18:10:32.542Z",
        hasDisplayContent: true,
        displayMessage: "I dunno, this stream seems pretty great to me!",
        textMessageDetails: {
          messageText: "I dunno, this stream seems pretty great to me!",
        },
      },
      authorDetails: {
        channelId: "UCN-hWk0vRANrLe4t1TWgfQg",
        channelUrl: "http://www.youtube.com/channel/UCN-hWk0vRANrLe4t1TWgfQg",
        displayName: "Becca Lee",
        profileImageUrl:
          "https://yt3.ggpht.com/-3TiQBf3Qxb8/AAAAAAAAAAI/AAAAAAAAAAA/VaOduTzczOA/s88-c-k-no-mo-rj-c0xffffff/photo.jpg",
        isVerified: false,
        isChatOwner: false,
        isChatSponsor: false,
        isChatModerator: false,
      },
    },
    {
      kind: "youtube#liveChatMessage",
      etag: '"0UM_wBUsFuT6ekiIlwaHvyqc80M/Xm-IsGscUD1xkZpiRcEy8IvxTB4"',
      id:
        "LCC.CiMSIQoYVUNZem9xcm9UNndhZHJjM0tSdmFsS2NREgUvbGl2ZRI5ChpDSURKdmZYWGotUUNGYjMxZ2dvZG5TQUlrdxIbQ01fNm5ON1hqLVFDRlVIV3hBb2Q2SFFCX0Ew",
      snippet: {
        type: "textMessageEvent",
        liveChatId: "EiEKGFVDWXpvcXJvVDZ3YWRyYzNLUnZhbEtjURIFL2xpdmU",
        authorChannelId: "UCYzoqroT6wadrc3KRvalKcQ",
        publishedAt: "2019-08-19T19:39:14.288Z",
        hasDisplayContent: true,
        displayMessage: "LOL HI",
        textMessageDetails: {
          messageText: "LOL HI",
        },
      },
      authorDetails: {
        channelId: "UCYzoqroT6wadrc3KRvalKcQ",
        channelUrl: "http://www.youtube.com/channel/UCYzoqroT6wadrc3KRvalKcQ",
        displayName: "Alex Fawkes",
        profileImageUrl:
          "https://yt3.ggpht.com/-bnLfarZX1eU/AAAAAAAAAAI/AAAAAAAAAAA/osbbHztUJZc/s88-c-k-no-mo-rj-c0xffffff/photo.jpg",
        isVerified: false,
        isChatOwner: true,
        isChatSponsor: false,
        isChatModerator: false,
      },
    },
    {
      kind: "youtube#liveChatMessage",
      etag: '"0UM_wBUsFuT6ekiIlwaHvyqc80M/WttRcCU_KqFeHw-6NsSicHiQ5mU"',
      id:
        "LCC.CiMSIQoYVUNZem9xcm9UNndhZHJjM0tSdmFsS2NREgUvbGl2ZRI5ChpDTktaczVYWWotUUNGWWFBd2dFZHdjVURqQRIbQ05MVm1wSFlqLVFDRmZkQlRBZ2ROZmdMdlEw",
      snippet: {
        type: "textMessageEvent",
        liveChatId: "EiEKGFVDWXpvcXJvVDZ3YWRyYzNLUnZhbEtjURIFL2xpdmU",
        authorChannelId: "UCN-hWk0vRANrLe4t1TWgfQg",
        publishedAt: "2019-08-19T19:40:21.227Z",
        hasDisplayContent: true,
        displayMessage: "cool stream, bro",
        textMessageDetails: {
          messageText: "cool stream, bro",
        },
      },
      authorDetails: {
        channelId: "UCN-hWk0vRANrLe4t1TWgfQg",
        channelUrl: "http://www.youtube.com/channel/UCN-hWk0vRANrLe4t1TWgfQg",
        displayName: "Becca Lee",
        profileImageUrl:
          "https://yt3.ggpht.com/-3TiQBf3Qxb8/AAAAAAAAAAI/AAAAAAAAAAA/VaOduTzczOA/s88-c-k-no-mo-rj-c0xffffff/photo.jpg",
        isVerified: false,
        isChatOwner: false,
        isChatSponsor: false,
        isChatModerator: false,
      },
    },
  ],
};

export default exampleChatMessages;
