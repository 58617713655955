import React from "react";
import { connect } from "react-redux";

import AbstractBasePage from "../../pages/AbstractBasePage";

import ThemeTable from "../../components/ThemesTable";
import PageTitleWithButton from "../../components/PageTitleWithButton";
import { BreadcrumbItem } from "../../components/Breadcrumbs";
import ThemeNavigator from "../../pages/ThemeNavigator";

import { loadThemes, setBreadcrumbItems } from "../../actions";
import { Spinner } from "../_shared/Bundle";

class ThemesIndex extends AbstractBasePage {
  constructor(props) {
    super(props);
    this.navigator = new ThemeNavigator(this.props.history);
  }

  handleAddTheme = () => {
    this.navigator.pushNew();
  };

  componentDidMount() {
    this.props.loadThemes();
  }

  render() {
    const { themes } = this.props;
    const themesRender = !themes ? <Spinner /> : <ThemeTable themes={themes} />;

    return (
      <>
        <PageTitleWithButton
          titleText="Themes"
          buttonText="+ Add Theme"
          onClick={this.handleAddTheme}
        />

        {themesRender}
      </>
    );
  }

  getBreadcrumbItems() {
    return [new BreadcrumbItem("Themes")];
  }
}

function mapStateToProps(state) {
  const { themes, breadcrumbItems } = state;
  return { themes, breadcrumbItems };
}

function mapDispatchToProps(dispatch) {
  return {
    loadThemes: () => dispatch(loadThemes()),
    setBreadcrumbItems: (items) => dispatch(setBreadcrumbItems(items)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ThemesIndex);
