import DashboardWidget from "./DashboardWidget";

export default class RotatingWidget extends DashboardWidget {
  constructor(props) {
    super(props);
    this.state = {
      name: "Rotating",
      color: "#e5bd2c",
      stem: "rotating",
      editable: true,
    };
  }
}
