import DashboardPreviewWidget from "./DashboardPreviewWidget";

export default class TextImagePreviewWidget extends DashboardPreviewWidget {
  constructor(props) {
    super(props);
    this.state = {
      name: "Text/Image",
      color: "#40be40",
      stem: "text-image",
    };
  }
}
