import DashboardWidget from "./DashboardWidget";

export default class TwitterFeedWidget extends DashboardWidget {
  constructor(props) {
    super(props);
    this.state = {
      name: "Instagram Comments",
      color: "#ee1877",
      stem: "instagram",
      editable: false,
    };
  }
}
