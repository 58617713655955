import Backbone from "backbone";
import "backbone-relational";

import beforeSend from "./beforeSend";

const AuthenticatedCollection = Backbone.Collection.extend({
  sync: function (_method, _model, options) {
    if (!options) {
      throw new Error("Missing options!");
    }
    if (options.beforeSend) {
      throw new Error("Existing beforeSend!");
    }

    options.beforeSend = beforeSend;
    return Backbone.Collection.prototype.sync.apply(this, arguments);
  },
});
export default AuthenticatedCollection;
