import React from "react";
import FormParsing from "../lib/FormParsing";
import UserAuth from "../lib/UserAuth";

/**
 * Represents a ChangePasswordForm.
 * @constructor
 */
export default class ChangePasswordForm extends React.Component {
  static defaultProps = {
    onPasswordChanged: () => {},
    onError: (_error) => {},
  };

  handleSubmit = async (event) => {
    const { email } = this.props;
    const { confirmationCode, password } = FormParsing.consumeSubmit(event);

    if (!confirmationCode || !password) {
      this.props.onError("Enter confirmation code and password");
      return;
    }

    try {
      await UserAuth.forgotPasswordSubmit(email, confirmationCode, password);
      this.props.onPasswordChanged();
    } catch (error) {
      this.props.onError(error);
    }
  };

  render() {
    return (
      <>
        <div>
          Enter the confirmation code from your email to reset your password.
        </div>
        <form onSubmit={this.handleSubmit}>
          <input
            placeholder="Confirmation Code"
            type="text"
            name="confirmationCode"
            className="uk-input uk-margin-bottom uk-margin-top"
          />
          <input
            placeholder="Password"
            type="password"
            name="password"
            className="uk-input uk-margin-bottom"
          />
          <input
            type="submit"
            name="commit"
            value="Change Password"
            data-disable-with="Change Password"
            className="tm-button tm-button-primary uk-margin-bottom"
          />
        </form>
      </>
    );
  }
}
