import React from "react";

/**
 * Will show a notification at the top of the screen.
 * @constructor
 * @param {string} message - Notification message to show.
 * @param {string} status - Notification status color.
 */

export default class Notification extends React.Component {
  render() {
    // const { message, status } = this.props;

    return <div>asdf</div>;
  }
}
