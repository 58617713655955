import DashboardPreviewWidget from "./DashboardPreviewWidget";

export default class TwitterFeedPreviewWidget extends DashboardPreviewWidget {
  constructor(props) {
    super(props);
    this.state = {
      name: "Twitter Feed",
      color: "#1ca1f3",
      stem: "twitter",
    };
  }
}
