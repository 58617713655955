import React from "react";
import UserAuth from "../../lib/UserAuth";

export default class Unauthorized extends React.Component {
  render() {
    if (!UserAuth.isAuthenticated(this.props)) {
      // login page will render elsewhere
      return null;
    }

    if (UserAuth.isAuthorized(this.props)) {
      // main app will render elsewhere
      return null;
    }

    return (
      <div className="vl-gradient-background vl-error">
        <div className="status-code sf-pro-display-ultralight">401</div>
        <div className="error-name">Unauthorized</div>
        <p className="error-message">
          Contact the system administrators to request access to this
          application, then try again.
        </p>
      </div>
    );
  }
}
