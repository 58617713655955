import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import UIkit from "uikit";
import AbstractBasePage from "./AbstractBasePage";
import ThemeNavigator from "./ThemeNavigator";

import ThemeForm from "../components/ThemeForm";
import PageTitle from "../components/PageTitle";
import { BreadcrumbItem } from "../components/Breadcrumbs";

import ThemeModel from "../models/ThemeModel";

import { saveTheme, setBreadcrumbItems } from "../actions";

class ThemeNewPage extends AbstractBasePage {
  constructor(props) {
    super(props);
    this.navigator = new ThemeNavigator(this.props.history);
  }

  handleSubmit = async (data, { setSubmitting }) => {
    const theme = ThemeModel.findOrCreate(data);

    const self = this;
    this.props.saveTheme(theme, {
      onSuccess: () => {
        setSubmitting(false);
        self.navigator.pushIndex();
        UIkit.notification(
          "<span uk-icon='icon: check'></span> Theme Created",
          {
            status: "success",
            timeout: 5000,
          }
        );
      },
      onError: () => {
        setSubmitting(false);
      },
    });
  };

  render() {
    const { themeDefaults } = this.props;
    if (!themeDefaults) {
      return null;
    }

    return (
      <div>
        <PageTitle text="New Theme" />
        <ThemeForm
          theme={new ThemeModel()} // themeDefaults.attributes
          onSubmit={this.handleSubmit}
        />
      </div>
    );
  }

  getBreadcrumbItems() {
    const pushIndex = this.navigator.pushIndex;
    return [
      new BreadcrumbItem("Themes", pushIndex),
      new BreadcrumbItem("New Theme"),
    ];
  }
}

function mapStateToProps(state) {
  const { breadcrumbItems, themeDefaults } = state;
  return { breadcrumbItems, themeDefaults };
}

function mapDispatchToProps(dispatch) {
  return {
    setBreadcrumbItems: (items) => dispatch(setBreadcrumbItems(items)),
    saveTheme: (theme, { onSuccess, onError }) =>
      dispatch(saveTheme(theme, { onSuccess, onError })),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ThemeNewPage)
);
