import React from "react";

/**
 * Represents a ButtonLink.
 * @constructor
 */
export default class ButtonLink extends React.Component {
  render() {
    let { className, ...other } = this.props;
    className = `uk-button-link vl-button-link-2 ${className || ""}`;
    return <button type="button" className={className} {...other} />;
  }
}
