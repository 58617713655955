import React from "react";
import { connect } from "react-redux";
import { ActionCableConsumer } from "react-actioncable-provider";

import { setInstagramComments } from "../actions";

class InstagramCommentsConsumer extends React.Component {
  componentWillMount() {
    const { instagramComments, setInstagramComments } = this.props;
    if (instagramComments) return;

    setInstagramComments([]);
  }

  handleReceived = (message) => {
    // left for dev convenience
    // console.log(JSON.stringify({ message }, null, 2))

    const { instagramComments, setInstagramComments } = this.props;
    instagramComments.unshift(message);

    // left for dev convenience
    // console.log(JSON.stringify({ instagramComments }, null, 2))

    const maxComments = 30;
    setInstagramComments(instagramComments.slice(0, maxComments));
  };

  render() {
    // HACK: Work around bug in ActionCableConsumer where remounting disconnects cable.
    return (this.consumer = this.consumer || (
      <ActionCableConsumer
        channel={{ channel: "InstagramCommentsChannel" }}
        onReceived={this.handleReceived}
      />
    ));
  }
}

function mapStateToProps(state) {
  const { instagramComments } = state;
  return { instagramComments };
}

function mapDispatchToProps(dispatch) {
  return {
    setInstagramComments: (instagramComments) =>
      dispatch(setInstagramComments(instagramComments)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InstagramCommentsConsumer);
