import React from "react";
import { Formik } from "formik";

import DashboardWidgetTypes from "../lib/DashboardWidgetTypes";

export default class TextImageForm extends React.Component {
  defaultState = DashboardWidgetTypes.Types.TextImage.DefaultState;

  render() {
    const { subwidgetDataItem, setSubmitForm, onSubmit } = this.props;
    const config = this.normalizeConfig(subwidgetDataItem.config);

    const labelStyle = {color: "#1C1C1C"};

    return (
      <Formik
        initialValues={config}
        onSubmit={onSubmit}
        render={({ values, handleChange, handleBlur, submitForm }) => {
          // the back and forth of handlers required here to get
          // submission working is a little insane; sorry
          setSubmitForm(submitForm);

          return (
            <div className="uk-form-stacked uk-margin">
              <div className="uk-margin">
                <label className="uk-form-label" style={labelStyle}>Name</label>
                <div className="uk-form-controls">
                  <input
                    className="uk-input"
                    name="name"
                    type="text"
                    placeholder="Name of the widget"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
              </div>

              <div className="uk-margin">
                <label className="uk-form-label" style={labelStyle}>Text Body</label>
                <div className="uk-form-controls">
                  <input
                    className="uk-input"
                    name="textBody"
                    type="text"
                    placeholder="Static text to display on the widget"
                    value={values.textBody}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
              </div>

              <div className="uk-margin">
                <label className="uk-form-label" style={labelStyle}>Image URL</label>
                <div className="uk-form-controls">
                  <input
                    className="uk-input"
                    name="imageURL"
                    type="text"
                    placeholder="Static URL of image to display on the widget"
                    value={values.imageURL}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
              </div>

              <div className="uk-margin">
                <label className="uk-form-label" style={labelStyle}>
                  <input
                    className="uk-input-xsmall uk-form-width-xsmall uk-margin-small-right"
                    type="color"
                    name="backgroundColor"
                    placeholder=""
                    value={values.backgroundColor || "#000000"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  Background Color
                </label>
              </div>

              <div className="uk-margin">
                <label className="uk-form-label" style={labelStyle}>
                  <input
                    className="uk-input-xsmall uk-form-width-xsmall uk-margin-small-right"
                    type="color"
                    name="textColor"
                    placeholder=""
                    value={values.textColor || "#000000"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  Text Color
                </label>
              </div>
            </div>
          );
        }}
      />
    );
  }

  normalizeConfig(config) {
    // mostly the schema gets goofy during development;
    // should not be an issue once schema stabilizes
    const { name, textBody, imageURL, backgroundColor, textColor } = config || {};
    const normalized = { name, textBody, imageURL, backgroundColor, textColor };

    // undefined values will break controlled form inputs
    if (normalized.name === undefined) {
      normalized.name = this.defaultState.name;
    }
    if (normalized.textBody === undefined) {
      normalized.textBody = this.defaultState.textBody;
    }
    if (normalized.imageURL === undefined) {
      normalized.imageURL = this.defaultState.imageURL;
    }
    if (normalized.backgroundColor === undefined) {
      normalized.backgroundColor = this.defaultState.backgroundColor;
    }
    if (normalized.textColor === undefined) {
      normalized.textColor = this.defaultState.textColor;
    }

    return normalized;
  }
}
