import React from "react";

import moment from "moment";

import ellipsize from "../lib/ellipsize";

// import DebugLogPane from './DebugLogPane'

export default class InstagramComment extends React.Component {
  render() {
    const { instagramComment } = this.props;

    return (
      <li className="instagram-comment">
        {/* left for developer convenience */}
        {/* <DebugLogPane data={instagramComment} /> */}

        <div className="original-media">
          {instagramComment.media.media_type === "IMAGE" && (
            <img
              className="image"
              src={instagramComment.media.media_url}
              alt={instagramComment.media.caption}
            />
          )}
          <div className="body">
            <div className="user-container">
              <div className="username">{instagramComment.media.username}</div>
              <div className="timestamp">
                {moment(instagramComment.timestamp).fromNow()}
              </div>
            </div>
            <div className="caption">
              {ellipsize(instagramComment.media.caption)}
            </div>
          </div>
        </div>
        <div className="new-comment">
          <div className="header">
            <img
              className="profile-picture"
              src={instagramComment.user.profile_picture_url}
              alt={instagramComment.username}
            />
            <div className="user-container">
              <div className="username">{instagramComment.username}</div>
              <div className="timestamp">
                {moment(instagramComment.timestamp).fromNow()}
              </div>
            </div>
          </div>
          <div className="body">
            <div className="text">{instagramComment.text}</div>
          </div>
        </div>
      </li>
    );
  }
}
