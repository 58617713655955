import React from "react";
import { connect } from "react-redux";
import TimezonePicker from "react-timezone";

import WidgetModal from "./WidgetModal";
import DashboardWidgetTypes from "../lib/DashboardWidgetTypes";

class WorldClockModal extends React.Component {
  defaultState = DashboardWidgetTypes.Types.WorldClock.DefaultState;

  render() {
    const { widgetDataItem } = this.props;
    if (!widgetDataItem) {
      return <WidgetModal modalId="world-clock-modal" />;
    }
    const config = this.normalizeConfig(widgetDataItem.config);

    return (
      <WidgetModal
        modalId="world-clock-modal"
        title="Edit World Clock Widget"
        initialValues={config}
        render={({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => {
          const error =
            touched.name &&
            errors.name &&
            touched.timezone &&
            errors.timezone &&
            touched.isTwelveHour &&
            errors.isTwelveHour &&
            touched.isSecondsDisplayed &&
            errors.isSecondsDisplayed &&
            touched.timezoneText &&
            errors.timezoneText &&
            touched.backgroundColor &&
            errors.backgroundColor && 
            touched.textColor &&
            errors.textColor;

          return (
            <form className="uk-form-stacked" onSubmit={handleSubmit}>
              <div className="uk-modal-body" uk-overflow-auto="">
                <div className="uk-margin">
                  <label className="uk-form-label">Name</label>
                  <div className="uk-form-controls">
                    <input
                      className="uk-input"
                      type="text"
                      name="name"
                      placeholder="Name of the widget"
                      value={values.name || ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                </div>

                <div className="uk-margin">
                  <label className="uk-form-label">Timezone</label>
                  <div className="uk-form-controls">
                    <TimezonePicker
                      offset="UTC"
                      className="timezone-picker"
                      inputProps={{
                        placeholder: "Timezone",
                        name: "timezone",
                      }}
                      value={values.timezone}
                      onChange={(value) => setFieldValue("timezone", value)}
                      onBlur={handleBlur}
                    />
                  </div>
                </div>

                <div className="uk-margin">
                  <label className="uk-form-label">Custom Timezone Text</label>
                  <div className="uk-form-controls">
                    <input
                      className="uk-input"
                      type="text"
                      name="timezoneText"
                      placeholder=""
                      value={values.timezoneText || ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                </div>

                <div className="uk-margin-small">
                  <div className="uk-form-controls">
                    <label className="uk-form-label">
                      <input
                        className="uk-checkbox uk-margin-small-right"
                        type="checkbox"
                        name="isTwelveHour"
                        checked={values.isTwelveHour || false}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      Display 12-hour format
                    </label>
                  </div>
                </div>

                <div className="uk-margin-small">
                  <div className="uk-form-controls">
                  <label className="uk-form-label">
                    <input
                      className="uk-checkbox uk-margin-small-right"
                      type="checkbox"
                      name="isSecondsDisplayed"
                      checked={values.isSecondsDisplayed || false}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    Display seconds</label>
                  </div>
                </div>

                <div className="uk-margin">
                  <label className="uk-form-label">
                    <input
                      className="uk-input-xsmall uk-form-width-xsmall uk-margin-small-right"
                      type="color"
                      name="backgroundColor"
                      placeholder=""
                      value={values.backgroundColor || "#000000"}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    Background Color
                  </label>
                </div>

                <div className="uk-margin">
                  <label className="uk-form-label">
                    <input
                      className="uk-input-xsmall uk-form-width-xsmall uk-margin-small-right"
                      type="color"
                      name="textColor"
                      placeholder=""
                      value={values.textColor || "#000000"}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    Text Color
                  </label>
                </div>
              </div>

              {error ? (
                <div className="uk-margin uk-form-danger">{error}</div>
              ) : null}

              <div className="uk-modal-footer uk-flex">
                <button
                  className="uk-button uk-button-default uk-modal-close"
                  type="button"
                >
                  Cancel
                </button>
                <button
                  className="uk-button uk-button-primary uk-margin-auto-left"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Save
                </button>
              </div>
            </form>
          );
        }}
      />
    );
  }

  normalizeConfig(config) {
    // mostly the schema gets goofy during development;
    // should not be an issue once schema stabilizes
    const { name, timezone, isTwelveHour, isSecondsDisplayed, timezoneText, backgroundColor, textColor } =
      config || {};
    const normalized = {
      name,
      timezone,
      isTwelveHour,
      isSecondsDisplayed,
      timezoneText,
      backgroundColor,
      textColor
    };

    // undefined values will break controlled form inputs
    if (normalized.name === undefined) {
      normalized.name = this.defaultState.name;
    }

    if (
      normalized.timezoneText === undefined ||
      (typeof normalized.timezoneText === "string" &&
        normalized.timezoneText.trim() === "")
    ) {
      normalized.timezoneText = this.defaultState.timezoneText;
    }

    if (normalized.timezone === undefined) {
      normalized.timezone = this.defaultState.timezone;
    }

    if ([undefined, ""].includes(normalized.isTwelveHour)) {
      normalized.isTwelveHour = this.defaultState.isTwelveHour;
    }

    if ([undefined, ""].includes(normalized.isSecondsDisplayed)) {
      normalized.isSecondsDisplayed = this.defaultState.isSecondsDisplayed;
    }

    if (normalized.backgroundColor === undefined) {
      normalized.backgroundColor = this.defaultState.backgroundColor;
    }

    if (normalized.textColor === undefined) {
      normalized.textColor = this.defaultState.textColor;
    }

    return normalized;
  }
}

function mapStateToProps(state) {
  const { widgetDataItem } = state;
  return { widgetDataItem };
}

export default connect(mapStateToProps)(WorldClockModal);
