import React from "react";

import NowPlayingPreviewWidget from "./NowPlayingPreviewWidget";
import RadioSchedulePreviewWidget from "./RadioSchedulePreviewWidget";
import RotatingPreviewWidget from "./RotatingPreviewWidget";
import TextImagePreviewWidget from "./TextImagePreviewWidget";
import TwitterFeedPreviewWidget from "./TwitterFeedPreviewWidget";
import WorldClockPreviewWidget from "./WorldClockPreviewWidget";
import YouTubeLiveChatPreviewWidget from "./YouTubeLiveChatPreviewWidget";
import InstagramCommentsPreviewWidget from "./InstagramCommentsPreviewWidget";

import DashboardWidgetTypes from "../lib/DashboardWidgetTypes";

// Selects correct widget to render based on widget type string.
export default class SelectingDashboardPreviewWidget extends React.Component {
  render() {
    const { type, ...other } = this.props;

    const types = DashboardWidgetTypes.Types;
    switch (type) {
      case types.NowPlaying.Type:
        return <NowPlayingPreviewWidget {...other} />;
      case types.RadioSchedule.Type:
        return <RadioSchedulePreviewWidget {...other} />;
      case types.Rotating.Type:
        return <RotatingPreviewWidget {...other} />;
      case types.TextImage.Type:
        return <TextImagePreviewWidget {...other} />;
      case types.TwitterFeed.Type:
        return <TwitterFeedPreviewWidget {...other} />;
      case types.WorldClock.Type:
        return <WorldClockPreviewWidget {...other} />;
      case types.YouTubeLiveChat.Type:
        return <YouTubeLiveChatPreviewWidget {...other} />;
      case types.InstagramComments.Type:
        return <InstagramCommentsPreviewWidget {...other} />;
      default:
        throw new Error(`Invalid widget type: ${type}`);
    }
  }
}
