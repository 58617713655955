import React from "react";
import { connect } from "react-redux";
import UIkit from "uikit";
import TwitterFeedRow from "./TwitterFeedRow";

import { loadTwitterFeeds, destroyTwitterFeed } from "../actions";

class TwitterFeedTable extends React.Component {
  render() {
    const { twitterFeeds, destroyTwitterFeed } = this.props;
    if (!twitterFeeds) {
      return null;
    }

    return (
      <div>
        <table className="uk-table uk-table-small uk-table-divider uk-table-middle uk-margin">
          <thead>
            <tr>
              <th>Name</th>
              <th>Item Approval</th>
              <th>Topics</th>
              <th>Following</th>
              <th>Blocked</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {twitterFeeds.map((twitterFeed) => (
              <TwitterFeedRow
                key={twitterFeed.get("id")}
                twitterFeed={twitterFeed}
                onDelete={() => destroyTwitterFeed(twitterFeed)}
              />
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const twitterFeeds = state.twitterFeeds || [];
  return { twitterFeeds };
}

function mapDispatchToProps(dispatch) {
  return {
    loadTwitterFeeds: () => dispatch(loadTwitterFeeds()),
    destroyTwitterFeed: (twitterFeed) => {
      UIkit.modal
        .confirm(
          `Are you sure you want to delete the Twitter Feed '${twitterFeed.attributes.name}'?`
        )
        .then(
          function () {
            dispatch(destroyTwitterFeed(twitterFeed));
            UIkit.notification(
              "<span uk-icon='icon: check'></span> Twitter Feed Deleted",
              {
                status: "success",
                timeout: 5000,
              }
            );
          },
          function () {}
        );
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TwitterFeedTable);
