import React from "react";
import { connect } from "react-redux";
import { ActionCableConsumer } from "react-actioncable-provider";

import { setYouTubeLiveChatMessages } from "../actions";

class YouTubeLiveChatConsumer extends React.Component {
  componentWillMount() {
    const {
      youtubeLiveChatMessages,
      streamerEmail,
      setYouTubeLiveChatMessages,
    } = this.props;
    if ((youtubeLiveChatMessages || {})[streamerEmail]) return;

    setYouTubeLiveChatMessages(streamerEmail, []);
  }

  handleReceived = (message) => {
    // left for dev convenience
    // console.log(JSON.stringify(message, null, 2))

    const {
      youtubeLiveChatMessages,
      streamerEmail,
      setYouTubeLiveChatMessages,
    } = this.props;

    const messages = (youtubeLiveChatMessages || {})[streamerEmail] || [];
    messages.push(message);

    // left for dev convenience
    // console.log(JSON.stringify(messages, null, 2))

    const maxMessages = 100;
    setYouTubeLiveChatMessages(streamerEmail, messages.slice(0, maxMessages));
  };

  render() {
    const { streamerEmail } = this.props;

    // HACK: Work around bug in ActionCableConsumer where remounting disconnects cable.
    return (this.consumer = this.consumer || (
      <ActionCableConsumer
        channel={{ channel: "YouTubeLiveChatChannel", email: streamerEmail }}
        onReceived={this.handleReceived}
      />
    ));
  }
}

function mapStateToProps(state) {
  const { youtubeLiveChatMessages } = state;
  return { youtubeLiveChatMessages };
}

function mapDispatchToProps(dispatch) {
  return {
    setYouTubeLiveChatMessages: (streamerEmail, chatMessages) =>
      dispatch(setYouTubeLiveChatMessages(streamerEmail, chatMessages)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(YouTubeLiveChatConsumer);
