import DashboardModel from "./models/DashboardModel";
import DashboardCollection from "./models/DashboardCollection";
import LayoutModel from "./models/LayoutModel";
import LayoutCollection from "./models/LayoutCollection";
import ScheduleModel from "./models/ScheduleModel";
import ScheduleCollection from "./models/ScheduleCollection";
import TimeSlotModel from "./models/TimeSlotModel";
import TimeSlotCollection from "./models/TimeSlotCollection";
import TwitterFeedModel from "./models/TwitterFeedModel";
import TwitterFeedCollection from "./models/TwitterFeedCollection";
import ThemeModel from "./models/ThemeModel";
import ThemeCollection from "./models/ThemeCollection";

const models = {
  DashboardModel,
  DashboardCollection,
  LayoutModel,
  LayoutCollection,
  ScheduleModel,
  ScheduleCollection,
  TimeSlotModel,
  TimeSlotCollection,
  TwitterFeedModel,
  TwitterFeedCollection,
  ThemeModel,
  ThemeCollection,
};
export default models;
