import React from "react";
import { connect } from "react-redux";

import WidgetModal from "./WidgetModal";
import DashboardWidgetTypes from "../lib/DashboardWidgetTypes";

class TextImageModal extends React.Component {
  defaultState = DashboardWidgetTypes.Types.TextImage.DefaultState;

  render() {
    const { widgetDataItem } = this.props;
    if (!widgetDataItem) {
      return <WidgetModal modalId="text-image-modal" />;
    }
    const config = this.normalizeConfig(widgetDataItem.config);

    return (
      <WidgetModal
        modalId="text-image-modal"
        title="Edit Text/Image Widget"
        initialValues={config}
        render={({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => {
          const error =
            touched.name &&
            errors.name &&
            touched.textBody &&
            errors.textBody &&
            touched.imageURL &&
            errors.imageURL &&
            touched.backgroundColor &&
            errors.backgroundColor &&
            touched.textColor &&
            errors.textColor;

          return (
            <form className="uk-form-stacked" onSubmit={handleSubmit}>
              <div className="uk-modal-body" uk-overflow-auto="">
                <div className="uk-margin">
                  <label className="uk-form-label">
                    Name <span className="vl-required">*</span>
                  </label>
                  <div className="uk-form-controls">
                    <input
                      className="uk-input"
                      name="name"
                      type="text"
                      placeholder=""
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required={true}
                    />
                  </div>
                </div>

                <div className="uk-margin">
                  <label className="uk-form-label">Static Text</label>
                  <div className="uk-form-controls">
                    <input
                      className="uk-input"
                      name="textBody"
                      type="text"
                      placeholder=""
                      value={values.textBody}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                </div>

                <div className="uk-margin">
                  <label className="uk-form-label">Image URL</label>
                  <div className="uk-form-controls">
                    <input
                      className="uk-input"
                      name="imageURL"
                      type="text"
                      placeholder=""
                      value={values.imageURL}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      pattern="(http(s?):)([/|.|\w|\s|-])*"
                    />
                  </div>
                </div>

                <div className="uk-margin">
                  <label className="uk-form-label">
                    <input
                      className="uk-input-xsmall uk-form-width-xsmall uk-margin-small-right"
                      type="color"
                      name="backgroundColor"
                      placeholder=""
                      value={values.backgroundColor || "#000000"}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    Background Color
                  </label>
                </div>

                <div className="uk-margin">
                  <label className="uk-form-label">
                    <input
                      className="uk-input-xsmall uk-form-width-xsmall uk-margin-small-right"
                      type="color"
                      name="textColor"
                      placeholder=""
                      value={values.textColor || "#000000"}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    Text Color
                  </label>
                </div>
              </div>

              {error ? (
                <div className="uk-margin uk-form-danger">{error}</div>
              ) : null}

              <div className="uk-modal-footer uk-flex">
                <button
                  className="uk-button uk-button-default uk-modal-close"
                  type="button"
                >
                  Cancel
                </button>
                <button
                  className="uk-button uk-button-primary uk-margin-auto-left"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Save
                </button>
              </div>
            </form>
          );
        }}
      />
    );
  }

  normalizeConfig(config) {
    // mostly the schema gets goofy during development;
    // should not be an issue once schema stabilizes
    const { name, textBody, imageURL, backgroundColor, textColor } = config || {};
    const normalized = { name, textBody, imageURL, backgroundColor, textColor };

    // undefined values will break controlled form inputs
    if (normalized.name === undefined) {
      normalized.name = this.defaultState.name;
    }
    if (normalized.textBody === undefined) {
      normalized.textBody = this.defaultState.textBody;
    }
    if (normalized.imageURL === undefined) {
      normalized.imageURL = this.defaultState.imageURL;
    }
    if (normalized.backgroundColor === undefined) {
      normalized.backgroundColor = this.defaultState.backgroundColor;
    }
    if (normalized.textColor === undefined) {
      normalized.textColor = this.defaultState.textColor;
    }

    return normalized;
  }
}

function mapStateToProps(state) {
  const { widgetDataItem } = state;
  return { widgetDataItem };
}

export default connect(mapStateToProps)(TextImageModal);
