import React from "react";
import { Formik } from "formik";
import DashboardWidgetTypes from "../lib/DashboardWidgetTypes";

export default class RadioScheduleForm extends React.Component {
  defaultState = DashboardWidgetTypes.Types.RadioSchedule.DefaultState;

  render() {
    const { subwidgetDataItem, setSubmitForm, onSubmit } = this.props;
    const config = this.normalizeConfig(subwidgetDataItem.config);

    const labelStyle = { color: "#1C1C1C" };

    return (
      <Formik
        initialValues={config}
        onSubmit={onSubmit}
        render={({ values, handleChange, handleBlur, submitForm }) => {
          setSubmitForm(submitForm);

          return (
            <div className="uk-form-stacked uk-margin">
              <div className="uk-margin">
                <label className="uk-form-label" style={labelStyle}>
                  <input
                    className="uk-input-xsmall uk-form-width-xsmall uk-margin-small-right"
                    type="color"
                    name="backgroundColor"
                    placeholder=""
                    value={values.backgroundColor || "#000000"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  Background Color
                </label>
              </div>

              <div className="uk-margin">
                <label className="uk-form-label" style={labelStyle}>
                  <input
                    className="uk-input-xsmall uk-form-width-xsmall uk-margin-small-right"
                    type="color"
                    name="showTimeTextColor"
                    placeholder=""
                    value={values.showTimeTextColor || "#000000"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  Show Time Text Color
                </label>
              </div>

              <div className="uk-margin">
                <label className="uk-form-label" style={labelStyle}>
                  <input
                    className="uk-input-xsmall uk-form-width-xsmall uk-margin-small-right"
                    type="color"
                    name="showNameTextColor"
                    placeholder=""
                    value={values.showNameTextColor || "#000000"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  Show Name Text Color
                </label>
              </div>

              <div className="uk-margin">
                <label className="uk-form-label" style={labelStyle}>
                  <input
                    className="uk-input-xsmall uk-form-width-xsmall uk-margin-small-right"
                    type="color"
                    name="showDescriptionTextColor"
                    placeholder=""
                    value={values.showDescriptionTextColor || "#000000"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  Show Description Text Color
                </label>
              </div>
            </div>
          );
        }}
      />
    );
  }

  normalizeConfig(config) {
    // mostly the schema gets goofy during development;
    // should not be an issue once schema stabilizes
    const {
      backgroundColor,
      showTimeTextColor,
      showNameTextColor,
      showDescriptionTextColor,
    } = config || {};
    const normalized = {
      backgroundColor,
      showTimeTextColor,
      showNameTextColor,
      showDescriptionTextColor,
    };

    // undefined values will break controlled form inputs
    if (normalized.backgroundColor === undefined) {
      normalized.backgroundColor = this.defaultState.backgroundColor;
    }
    if (normalized.showTimeTextColor === undefined) {
      normalized.showTimeTextColor = this.defaultState.showTimeTextColor;
    }
    if (normalized.showNameTextColor === undefined) {
      normalized.showNameTextColor = this.defaultState.showNameTextColor;
    }
    if (normalized.showDescriptionTextColor === undefined) {
      normalized.showDescriptionTextColor = this.defaultState.showDescriptionTextColor;
    }

    return normalized;
  }
}
