import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import dashboardApp from "./reducers";

import { Authenticator, Greetings, SignIn } from "aws-amplify-react";

// import "./index.css";
import "select2/dist/css/select2.min.css";
import "./styles/application.scss";

import App from "./App";
import models from "./models";

import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";

import UserAuth from "./lib/UserAuth";

import { DashboardsView } from "./views/dashboards/Bundle";
import LayoutViewPage from "./pages/LayoutViewPage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import DeveloperScratchPage from "./pages/DeveloperScratchPage";
import LandingPage from "./pages/LandingPage";
import { Unauthorized } from "./views/general/Bundle";

import * as serviceWorker from "./serviceWorker";

UIkit.use(Icons);
UserAuth.configure();

const store = createStore(dashboardApp, applyMiddleware(thunk));

// Expose to console for development and debug.
window.UserAuth = UserAuth;
window.store = store;
window.models = models;

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <Switch>
        {/* These must be outside auth check! */}
        <Route exact path="/unauthorized" component={Unauthorized} />
        <Route exact path="/dashboards/:id/view" component={DashboardsView} />
        <Route
          exact
          path="/dashboards/:dashboardId/layouts/:id/view"
          component={LayoutViewPage}
        />
        <Route exact path="/privacy-policy" component={PrivacyPolicyPage} />

        {/* Convenient to have this outside auth check. */}
        <Route
          exact
          path="/developer-scratch"
          component={DeveloperScratchPage}
        />

        <Route>
          <Authenticator hide={[Greetings, SignIn]}>
            <LandingPage />
            <Unauthorized />
            <App />
          </Authenticator>
        </Route>
      </Switch>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
