import React from "react";

import RotatingModal from "./RotatingModal";
import TextImageModal from "./TextImageModal";
import TwitterFeedModal from "./TwitterFeedModal";
import WorldClockModal from "./WorldClockModal";
import NowPlayingModal from "./NowPlayingModal";
import YouTubeLiveChatModal from "./YouTubeLiveChatModal";
import RadioScheduleModal from "./RadioScheduleModal";
import NotImplementedModal from "./NotImplementedModal";

import TimeSlotModal from "./TimeSlotModal";

import ErrorModal from "./ErrorModal";

export default class ModalList extends React.Component {
  render() {
    return (
      <div>
        <RotatingModal />
        <TextImageModal />
        <TwitterFeedModal />
        <WorldClockModal />
        <NowPlayingModal />
        <YouTubeLiveChatModal />
        <NotImplementedModal />
        <TimeSlotModal />
        <RadioScheduleModal />
        <ErrorModal />
      </div>
    );
  }
}
