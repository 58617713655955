import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import UIkit from "uikit";

import LayoutRow from "./LayoutRow";
import LayoutNavigator from "../pages/LayoutNavigator";
import MasterClientNavigator from "../pages/MasterClientNavigator";

import { loadDashboard, destroyLayout } from "../actions";

class LayoutsTable extends React.Component {
  constructor(props) {
    super(props);
    this.navigator = new MasterClientNavigator(this.props.history);
    this.layoutNavigator = new LayoutNavigator(this.props.history);
  }

  handleAddNewLayout = async () => {
    const { dashboard } = this.props;

    this.layoutNavigator.pushNew(dashboard);
  };

  handleDeleteLayout = async (layout) => {
    const { dashboard } = this.props;
    if (layout.get("default")) {
      return;
    }

    this.props.destroyLayout(layout);
    this.navigator.pushDashboardEdit(dashboard);
  };

  render() {
    const { dashboard } = this.props;
    if (!dashboard) {
      return null;
    }
    const layouts = dashboard.get("layouts");
    const theme = dashboard.get("theme");

    return (
      <>
        <button
          className="tm-button tm-button-primary uk-float-right"
          onClick={this.handleAddNewLayout}
        >
          Add New Layout
        </button>
        <table className="uk-table uk-table-small uk-table-divider uk-table-middle uk-margin">
          <thead>
            <tr>
              <th>Name</th>
              <th>Default</th>
              <th>Widget Count</th>
              <th>Theme</th>
            </tr>
          </thead>
          <tbody>
            {layouts.map((layout) => (
              <LayoutRow
                key={layout.get("id")}
                layout={layout}
                dashboard={dashboard}
                theme={theme}
                onDelete={this.handleDeleteLayout}
              />
            ))}
          </tbody>
        </table>
      </>
    );
  }

  loadDashboard() {
    const { dashboard } = this.props;
    this.props.loadDashboard(dashboard.get("id"));
  }
}

function mapStateToProps(state) {
  const dashboards = state.dashboards || [];
  return { dashboards };
}

function mapDispatchToProps(dispatch) {
  return {
    loadDashboard: (id) => dispatch(loadDashboard(id)),
    destroyLayout: (layout) => {
      UIkit.modal
        .confirm(
          `Are you sure you want to delete the Layout '${layout.attributes.name}'?`
        )
        .then(
          function () {
            dispatch(destroyLayout(layout));

            UIkit.notification(
              "<span uk-icon='icon: check'></span> Layout Deleted",
              {
                status: "success",
                timeout: 5000,
              }
            );
          },
          function () {}
        );
    },
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LayoutsTable)
);
