import React from "react";

import SelectingDashboardViewWidget from "./SelectingDashboardViewWidget";

export default class RotatingViewWidget extends React.Component {
  static defaultProps = {
    config: {
      subwidgetData: [],
    },
  };

  componentDidMount() {
    this.interval = setInterval(
      () => this.setState({ time: Date.now() }),
      1000
    );
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    const { config, ...other } = this.props;

    const { interval, subwidgetData } = config;
    if (!subwidgetData || subwidgetData.length === 0) return null;

    const time = new Date().getTime() / 1000;
    const index = Math.floor(time / interval) % subwidgetData.length;
    const subwidgetDataItem = subwidgetData[index];

    const type = subwidgetDataItem.type;
    const subwidgetConfig = subwidgetDataItem.config;

    return (
      <SelectingDashboardViewWidget
        key={index}
        type={type}
        config={subwidgetConfig}
        {...other}
      />
    );
  }
}
