import DashboardPreviewWidget from "./DashboardPreviewWidget";

export default class NowPlayingPreviewWidget extends DashboardPreviewWidget {
  constructor(props) {
    super(props);
    this.state = {
      name: "Now Playing",
      color: "#f75f71",
      stem: "now-playing",
    };
  }
}
