import React from "react";

export default class ModalFrame extends React.Component {
  render() {
    const { modalId, title, children } = this.props;

    return (
      <div id={modalId} className="vl-modal-frame uk-flex-top" uk-modal="">
        <div className="uk-modal-dialog uk-margin-auto-vertical">
          <div className="uk-modal-header">
            <h2 className="uk-modal-title">{title}</h2>
          </div>

          {children}
        </div>
      </div>
    );
  }
}
