import Amplify from "aws-amplify";

import awsconfigs from "../aws-exports-env";

import UserAPI from "./UserAPI";
import Utils from "./Utils";

export default class UserAuth {
  static configure() {
    const env = process.env.REACT_APP_ENV;
    const awsconfig = awsconfigs[env];
    Amplify.configure(awsconfig);
  }

  static invite(email) {
    return UserAPI.invite(email);
  }

  static async signIn(email, password) {
    const result = await Amplify.Auth.signIn(email, password);
    await this.refreshCachedAccessToken();
    return result;
  }

  static async signOut() {
    const result = await Amplify.Auth.signOut();
    this.cachedAccessToken = undefined;
    return result;
  }

  static isAuthenticated(props) {
    return !!props.authData;
  }

  static isAuthorized(props) {
    if (!this.isAuthenticated(props)) {
      return false;
    }

    const session = props.authData.signInUserSession;
    const groups = this.groups(session);
    return groups.includes("superadmin") || groups.includes("velociraptor");
  }

  static async isCurrentUserAdmin() {
    const session = await Amplify.Auth.currentSession();
    const groups = this.groups(session);
    return (
      groups.includes("superadmin") || groups.includes("velociraptor_admin")
    );
  }

  static async isCurrentUserSuperAdmin() {
    const session = await Amplify.Auth.currentSession();
    const groups = this.groups(session);
    return (
      groups.includes("superadmin")
    );
  }

  static async basicCurrentUserInfo() {
    const userInfo = await Amplify.Auth.currentUserInfo();

    const firstName = userInfo.attributes["given_name"];
    const lastName = userInfo.attributes["family_name"];
    const email = userInfo.attributes["email"];
    const initials = this.initials(firstName, lastName, email);

    return { email, firstName, lastName, initials };
  }

  // Return new JWT access token for current authenticated user.
  static async getAccessToken() {
    const user = await Amplify.Auth.currentAuthenticatedUser();
    return user.signInUserSession.accessToken.jwtToken;
  }

  static getCachedAccessToken() {
    return this.cachedAccessToken;
  }

  static async refreshSession() {
    const user = await Amplify.Auth.currentAuthenticatedUser();
    const session = await Amplify.Auth.currentSession();
    const token = session.getRefreshToken();
    return user.refreshSession(token, window.location.reload);
  }

  static async completeNewPassword(user, password) {
    return Amplify.Auth.completeNewPassword(user, password);
  }

  // Send confirmation code to email.
  static async forgotPassword(email) {
    return Amplify.Auth.forgotPassword(email);
  }

  // Submit confirmation code from email with new password.
  static async forgotPasswordSubmit(email, confirmationCode, password) {
    return Amplify.Auth.forgotPasswordSubmit(email, confirmationCode, password);
  }

  // private

  // This gets refreshed on a timer because backbone doesn't support inline async.
  static cachedAccessToken;
  static refreshCachedAccessToken = async () => {
    try {
      this.cachedAccessToken = await this.getAccessToken();
    } catch (error) {
      window.setTimeout(this.refreshCachedAccessToken, 10 * 1000);
    }
  };

  // Some pages (layout preview) load outside auth provider, but layout API
  // requires auth. Use this workaround to wait until auth is ready.
  static async blockOnToken() {
    while (!this.cachedAccessToken) {
      await Utils.sleep(100);
    }
  }

  static initials(firstName, lastName, email) {
    const firstInitial = (firstName || "").substr(0, 1).toUpperCase();
    const lastInitial = (lastName || "").substr(0, 1).toUpperCase();
    const emailInitial = (email || "").substr(0, 1).toUpperCase();

    if (firstName && lastName) {
      return firstInitial + lastInitial;
    } else if (firstName) {
      return firstInitial;
    } else if (lastName) {
      return lastInitial;
    } else if (email) {
      return emailInitial;
    }

    return "U";
  }

  static groups(session) {
    const groups = session.idToken.payload["cognito:groups"];
    return groups || [];
  }

  // Ease console access for development/debug.
  static amplify() {
    return Amplify;
  }
}

// token expires after an hour
const interval = 30 * 60 * 1000;

UserAuth.refreshCachedAccessToken();
window.setInterval(UserAuth.refreshCachedAccessToken, interval);
