import React from "react";
import { connect } from "react-redux";

import ButtonLink from "./ButtonLink";

/**
 * Represents a Breadcrumb link.
 * @constructor
 * @param {string} text - Text of the link.
 * @param {string} onClick - Action when link is clicked.
 */
class BreadcrumbItem {
  constructor(text, onClick) {
    this.text = text;
    this.onClick = onClick;
  }
}

/**
 * Represents a Breadcrumb area.
 * @constructor
 */
class Breadcrumbs_ extends React.Component {
  render() {
    const { breadcrumbItems } = this.props;
    if (!breadcrumbItems || breadcrumbItems.length === 0) {
      return null;
    }

    const rendered = this.renderItems(breadcrumbItems);
    return (
      <div className="breadcrumbs uk-container uk-flex uk-flex-middle">
        {rendered}
      </div>
    );
  }

  renderItems(items) {
    const rendered = items.map((item, i) => {
      if (!!item.onClick) {
        return (
          <span key={i}>
            <ButtonLink onClick={item.onClick}>{item.text}</ButtonLink>
          </span>
        );
      }
      return <span key={i}>{item.text}</span>;
    });
    return this.renderSeparated(rendered);
  }

  renderSeparated(items) {
    const self = this;
    return items.reduce(
      (acc, item, i) =>
        acc === null
          ? [item]
          : [acc, self.renderSeparator(items.length + i), item],
      null
    );
  }

  renderSeparator(key) {
    return (
      <span key={key} className="separator">
        >
      </span>
    );
  }
}

function mapStateToProps(state) {
  const { breadcrumbItems } = state;
  return { breadcrumbItems };
}

const Breadcrumbs = connect(mapStateToProps)(Breadcrumbs_);
export { Breadcrumbs as default, BreadcrumbItem };
