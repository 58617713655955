import Backbone from "backbone";
import "backbone-relational";

import API from "./API";
import AuthenticatedModel from "./AuthenticatedModel";

import LayoutModel from "./LayoutModel";
import LayoutCollection from "./LayoutCollection";
import ScheduleModel from "./ScheduleModel";

const DashboardModel = AuthenticatedModel.extend({
  urlRoot: API.urlRoot("dashboards"),

  relations: [
    {
      type: Backbone.HasMany,
      key: "layouts",
      relatedModel: LayoutModel,
      collectionType: LayoutCollection,

      reverseRelation: {
        key: "dashboard",
      },
    },
    {
      type: Backbone.HasOne,
      key: "schedule",
      relatedModel: ScheduleModel,

      reverseRelation: {
        type: Backbone.HasOne,
        key: "dashboard",
      },
    },
  ],

  getDefaultLayout: async function () {
    const layouts = this.get("layouts");
    return layouts.find((layout) => layout.get("default"));
  },

  getScheduledLayout: async function () {
    const schedule = this.get("schedule");
    await schedule.fetch();

    const timeSlot = schedule.getCurrentTimeSlot();
    if (timeSlot) {
      await timeSlot.fetch();
      return timeSlot.get("layout");
    }

    return await this.getDefaultLayout();
  },

  getDisplayName: function () {
    return this.get("name") || "Untitled Dashboard";
  },
});
export default DashboardModel;
