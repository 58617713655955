import Backbone from "backbone";
import "backbone-relational";

import API from "./API";
import AuthenticatedModel from "./AuthenticatedModel";

import DashboardModel from "./DashboardModel";
import DashboardCollection from "./DashboardCollection";

const ThemeModel = AuthenticatedModel.extend({
  urlRoot: API.urlRoot("themes"),

  relations: [
    {
      type: Backbone.HasMany,
      key: "dashboards",
      relatedModel: DashboardModel,
      collectionType: DashboardCollection,

      reverseRelation: {
        key: "theme",
      },
    },
  ],
});
export default ThemeModel;
