import React from "react";
import Clock from "react-live-clock";
import { timezones } from "react-timezone";

import DashboardWidgetTypes from "../lib/DashboardWidgetTypes";

export default class WorldClockViewWidget extends React.Component {
  static defaultProps = {
    config: DashboardWidgetTypes.Types.WorldClock.DefaultState,
  };

  render() {
    const { className, config, ...other } = this.props;
    const fullClassName = `dashboard-widget world-clock ${className}`;

    const { timezone, isTwelveHour, isSecondsDisplayed, timezoneText, backgroundColor, textColor } = config;

    const format = this.timeFormat(isTwelveHour, isSecondsDisplayed);
    const label = this.timezoneLabel(timezone, timezoneText);

    function containerStyle() {
      const style = Object.assign({}, other.style);

      if (backgroundColor) {
        Object.assign(style, {
          backgroundColor: backgroundColor,
        });
      }

      return style;
    }

    function textStyle() {
      const style = {};

      if (textColor) {
        Object.assign(style, {
          color: textColor,
        });
      }

      return style;
    }

    return (
      <div className={fullClassName} {...other} style={containerStyle()}>
        <Clock format={format} ticking={true} timezone={timezone} style={textStyle()} />
        <div className="timezone" style={textStyle()}>{label}</div>
      </div>
    );
  }

  // private

  timeFormat(isTwelveHour, isSecondsDisplayed) {
    if (!!isTwelveHour) {
      if (!!isSecondsDisplayed) return "h:mm:ss A";
      else return "h:mm A";
    } else {
      if (!!isSecondsDisplayed) return "H:mm:ss";
      else return "H:mm";
    }
  }

  timezoneLabel(timezone, timezoneText) {
    if (
      timezoneText !== undefined &&
      typeof timezoneText === "string" &&
      timezoneText.trim() !== ""
    ) {
      return timezoneText;
    } else {
      return timezones.find((tz) => tz.name === timezone).label;
    }
  }
}
