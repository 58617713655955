import React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import moment from "moment";

import { pollUpcomingShows } from "../actions";

class RadioScheduleViewWidget extends React.Component {
  static defaultProps = {
    config: {},
  };

  componentDidMount() {
    const { pollUpcomingShows } = this.props;
    pollUpcomingShows();
  }

  render() {
    const {
      className,
      config,
      pollUpcomingShows,
      upcomingShows,
      layout,
      ...other
    } = this.props;
    if (!upcomingShows) {
      return null;
    }

    const fullClassName = `dashboard-widget radio-schedule ${className}`;

    const {
      backgroundColor,
      showTimeTextColor,
      showNameTextColor,
      showDescriptionTextColor,
    } = config;

    function formatArtworkUrl(artworkUrl) {
      const dimension = "400";
      const scaling = "cc";
      const format = "png";

      return artworkUrl
        .replace("{w}", dimension)
        .replace("{h}", dimension)
        .replace("{c}", scaling)
        .replace("{f}", format);
    }

    function formatTime(timeString) {
      return moment(timeString).format("ddd h:mm A");
    }

    function containerStyle() {
      const style = Object.assign({}, other.style);

      if (backgroundColor) {
        Object.assign(style, {
          backgroundColor: backgroundColor,
        });
      }

      return style;
    }

    function showTimeStyle() {
      const style = { fontSize: "100%", alignSelf: "center" };

      if (showTimeTextColor) {
        Object.assign(style, {
          color: showTimeTextColor,
        });
      }

      return style;
    }

    function showNameStyle() {
      const style = { fontSize: "100%", alignSelf: "center" };

      if (showNameTextColor) {
        Object.assign(style, {
          color: showNameTextColor,
        });
      }

      return style;
    }

    function showDescriptionStyle() {
      const style = { fontSize: "100%", alignSelf: "center" };

      if (showDescriptionTextColor) {
        Object.assign(style, {
          color: showDescriptionTextColor,
        });
      }

      return style;
    }

    function beats1ShowStyle() {
      const style = { height: `calc(100% / ${layout.h})` };
      return style;
    }

    const cols = layout.w % 2 === 0 ? layout.w / 2 : (layout.w + 1) / 2;
    const rows = layout.h;

    function generateSlideshows() {
      const slideshows = [];
      const items = cols * rows;
      const indexes = _.range(0, upcomingShows.length, items);

      for (let index = 0; index < items; index++) {
        const shows = _.at(
          upcomingShows,
          _.map(indexes, (i) => i + index)
        );
        slideshows.push(shows);
      }

      return slideshows;
    }

    function renderColumns() {
      const divs = [];
      const slideshows = generateSlideshows();

      for (let index = 0; index < cols; index++) {
        const columnSlideshows = slideshows.filter(
          (_, i) => i % cols === index
        );
        divs.push(
          <div key={`div-${index}`}>{renderSlideshows(columnSlideshows)}</div>
        );
      }

      return divs;
    }

    function renderSlideshows(slideshows) {
      const divs = [];

      for (let index = 0; index < slideshows.length; index++) {
        const shows = slideshows[index];
        divs.push(
          <div
            className="radio-schedule-container"
            uk-slideshow="animation: fade; autoplay: true; autoplay-interval: 15000; pause-on-hover: false; min-height: 100%; max-height: 100%;"
            style={beats1ShowStyle()}
            key={`list-${index}`}
          >
            <ul className="uk-slideshow-items">
              {shows.map((upcomingShow, i) => {
                return renderShow(upcomingShow, i);
              })}
            </ul>
          </div>
        );
      }

      return divs;
    }

    function renderShow(upcomingShow, i) {
      if (!upcomingShow) return <li key={i}></li>;

      let { title, artworkUrl, startTime, endTime, description } = upcomingShow;

      artworkUrl = formatArtworkUrl(artworkUrl);
      startTime = formatTime(startTime);
      endTime = formatTime(endTime);

      return (
        <li key={i} className="beats1-show">
          <div className="show-container">
            <img className="artwork" alt="artwork" src={artworkUrl} />
            <div className="show-info marquee">
              <div style={infoDivStyle}>
                <span className="starttime endtime" style={showTimeStyle()}>
                  {startTime}&nbsp;-&nbsp;{endTime}
                </span>
              </div>
              <div style={infoDivStyle}>
                <span className="title" style={showNameStyle()}>
                  {title}
                </span>
              </div>
              <div style={infoDivStyle}>
                <span className="description" style={showDescriptionStyle()}>
                  {description}
                </span>
              </div>
            </div>
          </div>
        </li>
      );
    }

    const infoDivStyle = {
      height: "33.33%",
      display: "flex",
    };

    return (
      <div className={fullClassName} {...other} style={containerStyle()}>
        <div
          className="uk-grid-match uk-child-width-expand@s uk-height-1-1"
          uk-grid=""
        >
          {renderColumns()}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  let { upcomingShows } = state;
  return { upcomingShows };
}

function mapDispatchToProps(dispatch) {
  return {
    pollUpcomingShows: () => dispatch(pollUpcomingShows()),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RadioScheduleViewWidget);
