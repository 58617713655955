import DashboardPreviewWidget from "./DashboardPreviewWidget";

export default class RotatingPreviewWidget extends DashboardPreviewWidget {
  constructor(props) {
    super(props);
    this.state = {
      name: "Rotating",
      color: "#e5bd2c",
      stem: "rotating",
    };
  }
}
