import React from "react";
import { Link, withRouter } from "react-router-dom";

import PageTitle from "./PageTitle";
import LayoutNavigator from "../pages/LayoutNavigator";
import { DashboardsNavigator } from "../views/dashboards/Bundle";

class LayoutPageTitle extends React.Component {
  constructor(props) {
    super(props);
    this.navigator = new LayoutNavigator(this.props.history);
    this.dashboardNavigator = new DashboardsNavigator(this.props.history);
  }

  handlePreviewClicked = () => {
    const { dashboard, layout } = this.props;
    this.navigator.pushViewInTab(dashboard, layout);
  };

  render() {
    let { text } = this.props;
    if (!text) {
      const { dashboard, layout } = this.props;
      text = (
        <>
          <Link
            to={this.dashboardNavigator.editPath(dashboard)}
            className="vl-link"
          >
            {dashboard.getDisplayName()}
          </Link>
          &nbsp;- {layout.getDisplayName()}
        </>
      );

      // (text = ` - ${layout.getDisplayName()}`);
    }

    return (
      <PageTitle text={text}>
        <button
          className="tm-button tm-button-primary"
          onClick={this.handlePreviewClicked}
        >
          Preview Layout ↗
        </button>
      </PageTitle>
    );
  }
}

export default withRouter(LayoutPageTitle);
