import DashboardPreviewWidget from "./DashboardPreviewWidget";

export default class WorldClockPreviewWidget extends DashboardPreviewWidget {
  constructor(props) {
    super(props);
    this.state = {
      name: "World Clock",
      color: "#040404",
      stem: "clock",
    };
  }
}
