import React from "react";
import { connect } from "react-redux";

import AbstractBasePage from "./AbstractBasePage";

import PageTitle from "../components/PageTitle";
import { BreadcrumbItem } from "../components/Breadcrumbs";

import {
  setBreadcrumbItems,
  startPollingTwitterAnalytics,
  stopPollingTwitterAnalytics,
} from "../actions";

class TwitterAnalyticsPage extends AbstractBasePage {
  componentDidMount() {
    this.props.startPollingTwitterAnalytics();
  }

  componentWillUnmount() {
    this.props.stopPollingTwitterAnalytics();
  }

  render() {
    const { twitterAnalytics, twitterAnalyticsError } = this.props;
    if (twitterAnalyticsError) {
      return <div>{twitterAnalyticsError}</div>;
    }

    if (!twitterAnalytics) {
      return <div>Loading remote analytics...</div>;
    }

    // const {
    //   widget_count,
    //   feed_count,
    //   tweet_counts,
    //   topics,
    //   following,
    // } = twitterAnalytics;

    return (
      <>
        <PageTitle text="Twitter Analytics" />

        <div className="uk-alert uk-alert-info">
          <p>Coming Soon.</p>
        </div>
        {/* <h3 className="uk-heading-small">Widget Count</h3>
        <span>{widget_count}</span>

        <h3 className="uk-heading-small">Feed Count</h3>
        <span>{feed_count}</span>

        <h3 className="uk-heading-small">Tweet Counts</h3>
        <table className="uk-table">
          <tbody>
            <tr>
              <th>1 Hour</th>
              <td>{tweet_counts["one_hour"]}</td>
            </tr>
            <tr>
              <th>4 Hours</th>
              <td>{tweet_counts["four_hours"]}</td>
            </tr>
            <tr>
              <th>12 Hours</th>
              <td>{tweet_counts["twelve_hours"]}</td>
            </tr>
            <tr>
              <th>1 Day</th>
              <td>{tweet_counts["one_day"]}</td>
            </tr>
            <tr>
              <th>1 Week</th>
              <td>{tweet_counts["one_week"]}</td>
            </tr>
          </tbody>
        </table>

        <h3 className="uk-heading-small">Topics</h3>
        <ol>
          {topics.map((topic, index) => (
            <li key={index}>{topic}</li>
          ))}
        </ol>

        <h3 className="uk-heading-small">Following</h3>
        <ol>
          {following.map((follow, index) => (
            <li key={index}>{follow}</li>
          ))}
        </ol> */}

        {/* left for developer convenience */}
        {/* <pre><code>{JSON.stringify(twitterAnalytics, null, 2)}</code></pre> */}
      </>
    );
  }

  getBreadcrumbItems() {
    return [new BreadcrumbItem("Twitter Analytics")];
  }
}

function mapStateToProps(state) {
  const { breadcrumbItems, twitterAnalytics, twitterAnalyticsError } = state;
  return { breadcrumbItems, twitterAnalytics, twitterAnalyticsError };
}

function mapDispatchToProps(dispatch) {
  return {
    setBreadcrumbItems: (items) => dispatch(setBreadcrumbItems(items)),
    startPollingTwitterAnalytics: () =>
      dispatch(startPollingTwitterAnalytics()),
    stopPollingTwitterAnalytics: () => dispatch(stopPollingTwitterAnalytics()),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TwitterAnalyticsPage);
