import Backbone from "backbone";
import "backbone-relational";

import beforeSend from "./beforeSend";

const AuthenticatedModel = Backbone.RelationalModel.extend({
  sync: function (_method, _collection, options) {
    if (!options) {
      throw new Error("Missing options!");
    }
    if (options.beforeSend) {
      throw new Error("Existing beforeSend!");
    }

    options.beforeSend = beforeSend;
    return Backbone.RelationalModel.prototype.sync.apply(this, arguments);
  },
});
export default AuthenticatedModel;
