import DashboardWidget from "./DashboardWidget";

export default class YouTubeLiveChatWidget extends DashboardWidget {
  constructor(props) {
    super(props);
    this.state = {
      name: "YouTube Live Chat",
      color: "#c4302b",
      stem: "youtube",
      editable: true,
    };
  }
}
