import AppNavigator from "./AppNavigator";

export default class LayoutNavigator extends AppNavigator {
  pushIndex = () => this.push(this.indexPath());

  // Pass parent dashboard.
  pushNew = (dashboard) => this.push(this.newPath(dashboard));

  pushEdit = (dashboard, layout) => this.push(this.editPath(dashboard, layout));

  pushViewInTab = (dashboard, layout) =>
    window.open(this.viewPath(dashboard, layout), "_blank");

  indexPath = () => "/layouts";

  // Pass parent dashboard.
  newPath = (dashboard) => `/dashboards/${dashboard.get("id")}/layouts/new`;

  editPath = (dashboard, layout) =>
    `/dashboards/${dashboard.get("id")}/layouts/${layout.get("id")}`;

  viewPath = (dashboard, layout) =>
    `/dashboards/${dashboard.get("id")}/layouts/${layout.get("id")}/view`;
}
