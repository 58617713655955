import Backbone from "backbone";
import "backbone-relational";

import API from "./API";
import AuthenticatedModel from "./AuthenticatedModel";

import TimeSlotModel from "./TimeSlotModel";
import TimeSlotCollection from "./TimeSlotCollection";

const ScheduleModel = AuthenticatedModel.extend({
  urlRoot: API.urlRoot("schedules"),

  relations: [
    {
      type: Backbone.HasMany,
      key: "time_slots",
      relatedModel: TimeSlotModel,
      collectionType: TimeSlotCollection,

      reverseRelation: {
        key: "schedule",
      },
    },
  ],

  getCurrentTimeSlot: function () {
    const timeSlots = this.get("time_slots");
    return timeSlots.find((timeSlot) => timeSlot.isCurrent());
  },
});
export default ScheduleModel;
