import React from "react";

export default class TextImageViewWidget extends React.Component {
  static defaultProps = {
    config: {
      name: "",
      textBody: "",
      imageURL: "",
    },
  };

  render() {
    const { className, config, ...other } = this.props;
    const fullClassName = `dashboard-widget text-image ${className}`;

    const alt = config.name;
    const text = config.textBody;
    const src = config.imageURL;
    const { backgroundColor, textColor } = config;

    const image = this.renderImage(src, alt);

    function containerStyle() {
      const style = Object.assign({}, other.style);

      if (backgroundColor) {
        Object.assign(style, {
          backgroundColor: backgroundColor,
        });
      }

      return style;
    }

    function textStyle() {
      const style = {};

      if (textColor) {
        Object.assign(style, {
          color: textColor,
        });
      }

      return style;
    }

    return (
      <div className={fullClassName} {...other} style={containerStyle()}>
        {image}
        <div className="text" style={textStyle()}>{text}</div>
      </div>
    );
  }

  renderImage(src, alt) {
    if (!src) {
      return null;
    }
    return <img className="image" src={src} alt={alt}></img>;
  }
}
