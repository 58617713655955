import React from "react";
import { connect } from "react-redux";

import WidgetModal from "./WidgetModal";
import DashboardWidgetTypes from "../lib/DashboardWidgetTypes";

class RadioScheduleModal extends React.Component {
  defaultState = DashboardWidgetTypes.Types.RadioSchedule.DefaultState;

  render() {
    const { widgetDataItem } = this.props;
    if (!widgetDataItem) {
      return <WidgetModal modalId="radio-schedule-modal" />;
    }
    const config = this.normalizeConfig(widgetDataItem.config);

    return (
      <WidgetModal
        modalId="radio-schedule-modal"
        title="Edit Radio Schedule Widget"
        initialValues={config}
        render={({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => {
          const error =
            touched.backgroundColor &&
            errors.backgroundColor &&
            touched.showTimeTextColor &&
            errors.showTimeTextColor &&
            touched.showNameTextColor &&
            errors.showNameTextColor &&
            touched.showDescriptionTextColor &&
            errors.showDescriptionTextColor;

          return (
            <form className="uk-form-stacked" onSubmit={handleSubmit}>
              <div className="uk-modal-body" uk-overflow-auto="">
                <div className="uk-margin">
                  <label className="uk-form-label">
                    <input
                      className="uk-input-xsmall uk-form-width-xsmall uk-margin-small-right"
                      type="color"
                      name="backgroundColor"
                      placeholder=""
                      value={values.backgroundColor || "#000000"}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    Background Color
                  </label>
                </div>

                <div className="uk-margin">
                  <label className="uk-form-label">
                    <input
                      className="uk-input-xsmall uk-form-width-xsmall uk-margin-small-right"
                      type="color"
                      name="showTimeTextColor"
                      placeholder=""
                      value={values.showTimeTextColor || "#000000"}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    Show Time Text Color
                  </label>
                </div>

                <div className="uk-margin">
                  <label className="uk-form-label">
                    <input
                      className="uk-input-xsmall uk-form-width-xsmall uk-margin-small-right"
                      type="color"
                      name="showNameTextColor"
                      placeholder=""
                      value={values.showNameTextColor || "#000000"}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    Show Name Text Color
                  </label>
                </div>

                <div className="uk-margin">
                  <label className="uk-form-label">
                    <input
                      className="uk-input-xsmall uk-form-width-xsmall uk-margin-small-right"
                      type="color"
                      name="showDescriptionTextColor"
                      placeholder=""
                      value={values.showDescriptionTextColor || "#000000"}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    Show Description Text Color
                  </label>
                </div>
              </div>

              {error ? (
                <div className="uk-margin uk-form-danger">{error}</div>
              ) : null}

              <div className="uk-modal-footer uk-flex">
                <button
                  className="uk-button uk-button-default uk-modal-close"
                  type="button"
                >
                  Cancel
                </button>
                <button
                  className="uk-button uk-button-primary uk-margin-auto-left"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Save
                </button>
              </div>
            </form>
          );
        }}
      />
    );
  }

  normalizeConfig(config) {
    // mostly the schema gets goofy during development;
    // should not be an issue once schema stabilizes
    const {
      backgroundColor,
      showTimeTextColor,
      showNameTextColor,
      showDescriptionTextColor,
    } = config || {};
    const normalized = {
      backgroundColor,
      showTimeTextColor,
      showNameTextColor,
      showDescriptionTextColor,
    };

    // undefined values will break controlled form inputs
    if (normalized.backgroundColor === undefined) {
      normalized.backgroundColor = this.defaultState.backgroundColor;
    }

    if (normalized.showTimeTextColor === undefined) {
      normalized.showTimeTextColor = this.defaultState.showTimeTextColor;
    }

    if (normalized.showNameTextColor === undefined) {
      normalized.showNameTextColor = this.defaultState.showNameTextColor;
    }

    if (normalized.showDescriptionTextColor === undefined) {
      normalized.showDescriptionTextColor = this.defaultState.showDescriptionTextColor;
    }

    return normalized;
  }
}

function mapStateToProps(state) {
  const { widgetDataItem } = state;
  return { widgetDataItem };
}

export default connect(mapStateToProps)(RadioScheduleModal);
