import React from "react";

import { Field } from "formik";
import { Spinner } from "../views/_shared/Bundle";
import AppAPI from "../lib/AppAPI";

class InnerImageUploadField extends React.Component {
  componentDidMount() {
    this._isMounted = true;
    this.uploading = false;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onFieldChange = async (event, _value) => {
    this.uploading = true;
    const imgPath = await this.uploadImage(event);
    this.uploading = false;
    this.setFieldValue(imgPath);
  };

  onFieldBlur = () => {
    const { field, form } = this.props;
    if (!field || !form) {
      return;
    }

    form.setFieldTouched(field.name, true);
  };

  uploadImage = async (event) => {
    if (!event.target.files) {
      return null;
    }
    let file = event.target.files[0];

    let data = new FormData();
    data.append("image", file);

    this.setState({ error: undefined });

    try {
      const response = await new AppAPI("uploads").post(data);
      const imgPath = await response.text();

      return imgPath;
    } catch (error) {
      if (this._isMounted) {
        this.setState({ error: error.message || error });
      }

      return null;
    }
  };

  handleRemoveImage = (event) => {
    event.preventDefault();
    this.setFieldValue(null);
  };

  setFieldValue = (value) => {
    const { field, form } = this.props;
    if (!field || !form) {
      return;
    }

    form.setFieldValue(field.name, value);
  };

  render() {
    const { field } = this.props;
    if (!field) {
      return null;
    }

    if (this.uploading) {
      return <Spinner />;
    }

    const { error } = this.state || {};

    const uploadStyle = field.value ? { display: "none" } : null;
    const previewStyle = field.value ? null : { display: "none" };

    return (
      <div className="uk-form-controls">
        {/* display upload button if no image url */}
        <label className="uk-form-label">Background Image</label>

        <div className="upload-button-container" style={uploadStyle}>
          {/* display error if any */}
          {error && <div>{error}</div>}

          {/* Div constrains the label hack below to give it proper width and height */}
          <div className="inner-upload-button-container">
            <div uk-form-custom="">
              <input
                type="file"
                accept="image/jpeg,image/jpg,image/png"
                value=""
                onChange={this.onFieldChange}
                onBlur={this.onFieldBlur}
              />
              <button
                className="tm-button tm-button-primary uk-link"
                type="button"
              >
                <span uk-icon="icon: upload; ratio: 0.7" />
                Upload
              </button>
            </div>
          </div>
        </div>

        {/* display image preview if image url */}
        <div className="image-preview-container" style={previewStyle}>
          {field.value && <img src={field.value} alt="upload preview" />}

          <button
            className="tm-button tm-button-caution uk-margin"
            onClick={this.handleRemoveImage}
          >
            <span uk-icon="icon: close;ratio: 0.7;" />
            Remove
          </button>
        </div>
      </div>
    );
  }
}

export default class ImageUploadField extends React.Component {
  render() {
    return <Field {...this.props} component={InnerImageUploadField} />;
  }
}
