import React from "react";

export default class NullLink extends React.Component {
  render() {
    // uikit requires blank hrefs

    return (
      // eslint-disable-next-line
      <a href="" onClick={() => false}>
        {this.props.children}
      </a>
    );
  }
}
