import React from "react";
import { connect } from "react-redux";

import { Formik } from "formik";

import Modal from "./Modal";
import DashboardWidgetTypes from "../lib/DashboardWidgetTypes";

import { saveWidget } from "../actions";

class WidgetModal extends React.Component {
  handleSave = (values) => {
    const { layout, index, widgetDataItem, subwidgetData } = this.props;
    if (!layout || !index || !widgetDataItem) {
      return;
    }

    widgetDataItem.config = values;
    if (widgetDataItem.type === DashboardWidgetTypes.Types.Rotating.Type) {
      widgetDataItem.config.subwidgetData = subwidgetData;
    }
    this.props.saveWidget(layout, index, widgetDataItem);
  };

  render() {
    const { modalId, title, initialValues, render } = this.props;
    return (
      <Modal modalId={modalId} title={title}>
        <Formik
          initialValues={initialValues}
          onSubmit={this.handleSave}
          render={render}
        />
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  const { layout, index, widgetDataItem, subwidgetData } = state;
  return { layout, index, widgetDataItem, subwidgetData };
}

function mapDispatchToProps(dispatch) {
  return {
    saveWidget: (layout, index, widgetDataItem) =>
      dispatch(saveWidget(layout, index, widgetDataItem)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(WidgetModal);
