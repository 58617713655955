import React from "react";

import Modal from "./Modal";

export default class NotImplementedModal extends React.Component {
  render() {
    return (
      <Modal modalId="not-implemented-modal" title="Not Implemented">
        <p>This widget type has not been implemented yet. Sorry!</p>
        <p className="uk-text-right">
          <button
            className="uk-button uk-button-default uk-modal-close"
            type="button"
          >
            OK
          </button>
        </p>
      </Modal>
    );
  }
}
