import React from "react";

export default class Spinner extends React.Component {
  // constructor(props) {
  //   super(props);
  // }

  render() {
    return (
      <div className="vl-spinner-container">
        <span uk-spinner="ratio: 4.5"></span>
      </div>
    );
  }
}
