import DashboardWidget from "./DashboardWidget";

export default class WorldClockWidget extends DashboardWidget {
  constructor(props) {
    super(props);
    this.state = {
      name: "World Clock",
      color: "#040404",
      stem: "clock",
      editable: true,
    };
  }
}
