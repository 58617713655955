export default {
  development: {
    Auth: {
      userPoolId: "us-east-1_psxv3hwHE",
      userPoolWebClientId: "61san69ihfc051v3nijteilfiq",
      mandatorySignIn: false,
    },
  },
  staging: {
    Auth: {
      userPoolId: "us-east-1_psxv3hwHE",
      userPoolWebClientId: "61san69ihfc051v3nijteilfiq",
      mandatorySignIn: false,
    },
  },
  production: {
    Auth: {
      userPoolId: "us-east-1_IGu3nkC0z",
      userPoolWebClientId: "4ufjah2jktalkcahd9ig896rpr",
      mandatorySignIn: false,
    },
  },
};
