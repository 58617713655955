import React from "react";
// import Moment from "react-moment";
import { withRouter } from "react-router-dom";

import ButtonLink from "../components/ButtonLink";

import { DashboardsNavigator } from "../views/dashboards/Bundle";

class DashboardRow extends React.Component {
  constructor(props) {
    super(props);
    this.navigator = new DashboardsNavigator(this.props.history);
  }

  render() {
    const { dashboard } = this.props;
    if (!dashboard) {
      return null;
    }

    const pushEdit = this.navigator.pushEdit.bind(this.navigator, dashboard);
    const displayName = dashboard.getDisplayName();
    // const createdAt = dashboard.get("created_at");
    const layoutCount = this.layoutCount();

    return (
      <tr>
        <td>
          <ButtonLink onClick={pushEdit}>{displayName}</ButtonLink>
        </td>
        <td>{layoutCount}</td>
        <td className="actions">{this.renderDeleteButton()}</td>
      </tr>
    );
  }

  renderDeleteButton() {
    const { dashboard, onDelete } = this.props;
    if (!dashboard || !onDelete) {
      return null;
    }

    return (
      <button
        className="uk-icon-button vl-table-delete"
        onClick={() => onDelete(dashboard)}
        uk-icon="icon: trash; scale: 0.75"
      ></button>
    );
  }

  layoutCount() {
    const { dashboard } = this.props;
    const layouts = dashboard.get("layouts");
    return layouts.length;
  }
}
export default withRouter(DashboardRow);
