// https://greensock.com/forums/topic/9059-cross-browser-to-detect-tab-or-window-is-active-so-animations-stay-in-sync-using-html5-visibility-api/

export default class FocusListener {
  constructor({ force = false }) {
    if (!force) {
      throw new Error(
        "Do not instantiate FocusListener singleton yourself! Use FocusListener.get()."
      );
    }

    this.statesToEvents = {
      hidden: "visibilitychange",
      webkitHidden: "webkitvisibilitychange",
      mozHidden: "mozvisibilitychange",
      msHidden: "msvisibilitychange",
    };

    this.focusGainedCallbacks = [];
    this.focusLostCallbacks = [];

    this.registerHandler();
  }

  static get() {
    if (!this.instance) {
      this.instance = new FocusListener({ force: true });
    }
    return this.instance;
  }

  isTabFocused() {
    const state = this.firstState();
    return !document[state];
  }

  addTabFocusGainedCallback(callback) {
    this.focusGainedCallbacks.push(callback);
  }

  removeTabFocusGainedCallback(callback) {
    this.removeCallback(this.focusGainedCallbacks, callback);
  }

  addTabFocusLostCallback(callback) {
    this.focusLostCallbacks.push(callback);
  }

  removeTabFocusLostCallback(callback) {
    this.removeCallback(this.focusLostCallbacks, callback);
  }

  // private

  static instance;

  registerHandler() {
    const event = this.firstEvent();
    document.addEventListener(event, this.handleVisibilityChange);
  }

  handleVisibilityChange = () => {
    if (this.isTabFocused()) {
      const fireCallbacks = this.fireCallbacks.bind(
        this,
        this.focusGainedCallbacks
      );

      // give some time for browser to update or unexpected things happen
      setTimeout(fireCallbacks, 200);
    } else {
      this.fireCallbacks(this.focusLostCallbacks);
    }
  };

  fireCallbacks(callbacks) {
    for (const callback of callbacks) {
      callback();
    }
  }

  removeCallback(callbacks, callback) {
    var index = callbacks.indexOf(callback);
    if (index > -1) {
      callbacks.splice(index, 1);
    }
  }

  firstState() {
    for (const state in this.statesToEvents) {
      if (state in document) {
        return state;
      }
    }
    throw new Error("No valid state in document!");
  }

  firstEvent() {
    const state = this.firstState();
    return this.statesToEvents[state];
  }
}
