import React from "react";

import FormParsing from "../lib/FormParsing";
import UserAuth from "../lib/UserAuth";

export default class LoginForm extends React.Component {
  static defaultProps = {
    onForgotPasswordClicked: (_event) => {},
    onNewPasswordRequired: (_user) => {},
    onError: (_error) => {},
  };

  handleSubmit = async (event) => {
    const { email, password } = FormParsing.consumeSubmit(event);

    if (!email || !password) {
      this.props.onError("Enter email/password");
      return;
    }

    try {
      const user = await UserAuth.signIn(email, password);
      if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        this.props.onNewPasswordRequired(user);
        return;
      }

      this.props.onStateChange("signedIn", user);
    } catch (error) {
      this.props.onError(error);
    }
  };

  render() {
    return (
      <div className="uk-flex uk-flex-column">
        <div className="uk-margin-top uk-padding-remove-vertical">
          <form onSubmit={this.handleSubmit}>
            <input
              className="uk-input input-top"
              placeholder="Email"
              type="email"
              name="email"
            />
            <input
              className="uk-input input-bottom"
              placeholder="Password"
              type="password"
              name="password"
            />
            <button
              className="tm-button tm-button-primary uk-margin-top"
              type="submit"
              name="commit"
              value=""
              data-disable-with="Log In"
            >
              Log In
            </button>
          </form>
        </div>
        <div className="uk-margin-small-bottom">
          <button
            className="uk-button-link auth-link"
            onClick={this.props.onForgotPasswordClicked}
          >
            Forgot your password?
          </button>
        </div>
      </div>
    );
  }
}
