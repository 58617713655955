import React from "react";

export default class PageTitle extends React.Component {
  render() {
    const { text, children } = this.props;
    return (
      <h2 className="uk-heading-divider uk-flex uk-flex-middle">
        <div className="uk-flex-1 uk-text-left">{text}</div>
        {children}
      </h2>
    );
  }
}
