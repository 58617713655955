import DashboardPreviewWidget from "./DashboardPreviewWidget";

export default class RadioSchedulePreviewWidget extends DashboardPreviewWidget {
  constructor(props) {
    super(props);
    this.state = {
      name: "Schedule",
      color: "#29ccbf",
      stem: "schedule",
    };
  }
}
