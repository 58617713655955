import React from "react";

import NowPlayingWidget from "./NowPlayingWidget";
import RadioScheduleWidget from "./RadioScheduleWidget";
import RotatingWidget from "./RotatingWidget";
import TextImageWidget from "./TextImageWidget";
import WorldClockWidget from "./WorldClockWidget";
import TwitterFeedWidget from "./TwitterFeedWidget";
import YouTubeLiveChatWidget from "./YouTubeLiveChatWidget";
import InstagramCommentsWidget from "./InstagramCommentsWidget";

import ToolbarWidget from "./ToolbarWidget";

import DashboardWidgetTypes from "../lib/DashboardWidgetTypes";

export default class WidgetToolbar extends React.Component {
  render() {
    const types = DashboardWidgetTypes.Types;

    return (
      <div className="widget-toolbar">
        <ToolbarWidget type={types.NowPlaying.Type}>
          <NowPlayingWidget />
        </ToolbarWidget>
        <ToolbarWidget type={types.Rotating.Type}>
          <RotatingWidget />
        </ToolbarWidget>
        <ToolbarWidget type={types.TextImage.Type}>
          <TextImageWidget />
        </ToolbarWidget>
        <ToolbarWidget type={types.RadioSchedule.Type}>
          <RadioScheduleWidget />
        </ToolbarWidget>
        <ToolbarWidget type={types.WorldClock.Type}>
          <WorldClockWidget />
        </ToolbarWidget>
        <ToolbarWidget type={types.TwitterFeed.Type}>
          <TwitterFeedWidget />
        </ToolbarWidget>
        <ToolbarWidget type={types.YouTubeLiveChat.Type}>
          <YouTubeLiveChatWidget />
        </ToolbarWidget>
        <ToolbarWidget type={types.InstagramComments.Type}>
          <InstagramCommentsWidget />
        </ToolbarWidget>
        <div className="uk-clearfix"></div>
      </div>
    );
  }
}
