import React from "react";
import { withRouter } from "react-router-dom";

import ButtonLink from "./ButtonLink";
import DashboardPreviewGraphic from "./DashboardPreviewGraphic";

import { DashboardsNavigator } from "../views/dashboards/Bundle";

class DashboardPreviewTile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.navigator = new DashboardsNavigator(this.props.history);
  }

  async componentDidMount() {
    this._isMounted = true;

    const { dashboard } = this.props;
    if (!dashboard) {
      return;
    }

    await dashboard.fetch();
    const defaultLayout = await dashboard.getDefaultLayout();
    if (this._isMounted) {
      this.setState({ defaultLayout });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { dashboard } = this.props;
    if (!dashboard) {
      return null;
    }
    const dashboardDisplayName = dashboard.getDisplayName();

    const { defaultLayout } = this.state;
    if (!defaultLayout) {
      return null;
    }
    const defaultLayoutDisplayName = defaultLayout.getDisplayName();

    const pushEdit = this.navigator.pushEdit.bind(this.navigator, dashboard);
    const pushViewInTab = this.navigator.pushViewInTab.bind(
      this.navigator,
      dashboard
    );

    // note uikit requires empty outer div for grid item margins to work
    return (
      <div>
        <div className="uk-card uk-card-default vl-dashboard-preview-tile">
          <div onClick={pushEdit}>
            <DashboardPreviewGraphic dashboard={dashboard} />
          </div>
          <div className="vl-dashboard-preview-tile-lower">
            <div className="vl-text-pane">
              <div className="vl-title" onClick={pushEdit}>
                {dashboardDisplayName}
              </div>
              <div className="vl-subtitle" onClick={pushEdit}>
                {defaultLayoutDisplayName}
              </div>
            </div>
            <div className="vl-ellipsis-pane">
              <ButtonLink className="uk-align-right">
                <span
                  className="vl-ellipsis"
                  uk-icon="icon: more; ratio: 1.5"
                />
              </ButtonLink>
              <div uk-dropdown="mode: click">
                <ul className="uk-nav">
                  <li>
                    <ButtonLink onClick={() => pushEdit("layouts")}>
                      Layouts
                    </ButtonLink>
                  </li>
                  <li>
                    <ButtonLink onClick={() => pushEdit("schedule")}>
                      Schedule
                    </ButtonLink>
                  </li>
                  <li>
                    <ButtonLink onClick={() => pushEdit("edit")}>
                      Edit Details
                    </ButtonLink>
                  </li>
                  <li>
                    <ButtonLink onClick={pushViewInTab}>View Live ↗</ButtonLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(DashboardPreviewTile);
