import React from "react";
import { withRouter } from "react-router-dom";

import PageTitleWithButton from "./PageTitleWithButton";
import { DashboardsNavigator } from "../views/dashboards/Bundle";

class DashboardPageTitle extends React.Component {
  constructor(props) {
    super(props);
    this.navigator = new DashboardsNavigator(this.props.history);
  }

  handlePreviewClicked = () => {
    const { dashboard } = this.props;
    this.navigator.pushViewInTab(dashboard);
  };

  render() {
    let { text } = this.props;
    if (!text) {
      const { dashboard } = this.props;
      text = dashboard.getDisplayName();
    }

    return (
      <PageTitleWithButton
        titleText={text}
        buttonText="Preview Dashboard ↗"
        onClick={this.handlePreviewClicked}
      />
    );
  }
}

export default withRouter(DashboardPageTitle);
