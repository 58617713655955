import DashboardWidget from "./DashboardWidget";

export default class TextImageWidget extends DashboardWidget {
  constructor(props) {
    super(props);
    this.state = {
      name: "Text/Image",
      color: "#40be40",
      stem: "text-image",
      editable: true,
    };
  }
}
