import React from "react";
import { withRouter } from "react-router-dom";

import AppNavigator from "./AppNavigator";
import ButtonLink from "../components/ButtonLink";

class PageNotFoundPage extends React.Component {
  constructor(props) {
    super(props);
    this.navigator = new AppNavigator(this.props.history);
  }

  render() {
    const pushRoot = this.navigator.pushRoot;
    return (
      <div>
        <h4>Page Not Found</h4>
        <ButtonLink onClick={pushRoot}>Go home</ButtonLink>
      </div>
    );
  }
}
export default withRouter(PageNotFoundPage);
