import AppNavigator from "./AppNavigator";

export default class TwitterFeedNavigator extends AppNavigator {
  pushIndex = () => this.push(this.indexPath());

  pushNew = () => this.push(this.newPath());

  pushEdit = (twitterFeed) => this.push(this.editPath(twitterFeed));

  pushGlobalFilter = () => this.push(this.globalFilterPath());

  indexPath = () => "/social/twitter-feeds";

  newPath = () => "/social/twitter-feeds/new";

  editPath = (twitterFeed) => `/social/twitter-feeds/${twitterFeed.get("id")}`;

  globalFilterPath = () => "/social/twitter-global-filter";
}
