import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import UIkit from "uikit";
import AbstractBasePage from "./AbstractBasePage";
import MasterClientNavigator from "./MasterClientNavigator";

import PageTitle from "../components/PageTitle";
import TwitterGlobalFilterForm from "../components/TwitterGlobalFilterForm";
import ErrorBanner from "../components/ErrorBanner";
import { BreadcrumbItem } from "../components/Breadcrumbs";

import {
  loadTwitterGlobalFilter,
  saveTwitterGlobalFilter,
  setBreadcrumbItems,
} from "../actions";

class TwitterGlobalFilterPage extends AbstractBasePage {
  constructor(props) {
    super(props);
    this.navigator = new MasterClientNavigator(this.props.history);
  }

  handleSubmit = async (values, { setSubmitting }) => {
    const tokenize = (string) =>
      string
        .split(",")
        .map((s) => s.trim())
        .filter((s) => s !== "");
    const attributes = {
      id: values.id,
      blocked_topics: tokenize(values.blocked_topics),
      blocked_follows: tokenize(values.blocked_follows),
    };

    const self = this;
    this.props.saveTwitterGlobalFilter(attributes, {
      onSuccess: () => {
        setSubmitting(false);
        self.navigator.pushTwitterFeedIndex();

        UIkit.notification(
          "<span uk-icon='icon: check'></span> Global Filter Updated",
          {
            status: "success",
            timeout: 5000,
          }
        );
      },
      onError: (error) => {
        setSubmitting(false);
        self.setState({ error });
      },
    });
  };

  componentWillMount() {
    this.props.loadTwitterGlobalFilter();
  }

  render() {
    const { twitterGlobalFilter } = this.props;
    if (!twitterGlobalFilter) {
      return null;
    }

    return (
      <div>
        <PageTitle text="Twitter Global Filter" />
        <TwitterGlobalFilterForm
          twitterGlobalFilter={twitterGlobalFilter}
          onSubmit={this.handleSubmit}
        />
        <ErrorBanner parent={this} />
      </div>
    );
  }

  getBreadcrumbItems() {
    const pushTwitterFeedIndex = this.navigator.pushTwitterFeedIndex;
    return [
      new BreadcrumbItem("Social Feeds", pushTwitterFeedIndex),
      new BreadcrumbItem("Twitter"),
      new BreadcrumbItem("Global Filter"),
    ];
  }
}

function mapStateToProps(state) {
  const { twitterGlobalFilter, breadcrumbItems } = state;
  return { twitterGlobalFilter, breadcrumbItems };
}

function mapDispatchToProps(dispatch) {
  return {
    loadTwitterGlobalFilter: () => dispatch(loadTwitterGlobalFilter()),
    saveTwitterGlobalFilter: (twitterGlobalFilter, { onSuccess, onError }) =>
      dispatch(
        saveTwitterGlobalFilter(twitterGlobalFilter, { onSuccess, onError })
      ),
    setBreadcrumbItems: (items) => dispatch(setBreadcrumbItems(items)),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TwitterGlobalFilterPage)
);
