import UserAuth from "./UserAuth";

// Deprecated; use Backbone-derived AuthenticatedModel instead.
// Left in for now to support existing UserAPI calls until they
// require significant change, then the dependency should be broken
// and this class removed.
export default class AppAPI {
  constructor(path) {
    this.path = path;
  }

  async fetchJson(
    { id, appendix, method = "GET", params = {}, body, expectJson = true } = {
      id: undefined,
      appendix: undefined,
      method: "GET",
      params: {},
      body: undefined,
      expectJson: true,
    }
  ) {
    const args = {
      method: method,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    try {
      const accessToken = await UserAuth.getAccessToken();
      args.headers.Authorization = `Bearer ${accessToken}`;
    } catch (error) {
      // let request error and handle that instead
    }

    if (body) {
      args.body = JSON.stringify(body);
    }

    const url = new URL(this.url(id, appendix));
    url.search = new URLSearchParams(params);

    const response = await fetch(url, args);
    if (expectJson) {
      return response.json();
    }
    return response;
  }

  // WARNING: Quick and dirty for Twitter analytics.
  // You probably won't want to use this elsewhere
  async get() {
    const args = { method: "GET" };

    try {
      const accessToken = await UserAuth.getAccessToken();
      args.headers = { Authorization: `Bearer ${accessToken}` };
    } catch (error) {
      // let request error and handle that instead
      console.log({ error });
    }

    const url = new URL(this.url());
    const response = await fetch(url, args);
    return response;
  }

  // WARNING: Quick and dirty for image uploads.
  // You probably won't want to use this elsewhere
  async post(body) {
    const args = {
      method: "POST",
      body: body,
    };

    try {
      const accessToken = await UserAuth.getAccessToken();
      args.headers = { Authorization: `Bearer ${accessToken}` };
    } catch (error) {
      // let request error and handle that instead
      console.log({ error });
    }

    const url = new URL(this.url());
    const response = await fetch(url, args);
    return response;
  }

  url(id, appendix) {
    const base = `${this.constructor._API_HOST}/${this.path}`;
    if (!id) {
      return base;
    }
    if (!appendix) {
      return `${base}/${id}`;
    }
    return `${base}/${id}/${appendix}`;
  }

  // private

  static _API_HOST = process.env.REACT_APP_API_HOST;
}
