import React from "react";
import { connect } from "react-redux";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import DashboardWidgetTypes from "../lib/DashboardWidgetTypes";
import moment from "moment";

import { subscribeYouTubeLiveChat } from "../actions";

import exampleChatMessages from "./exampleChatMessages";

class YouTubeLiveChatViewWidget extends React.Component {
  static defaultProps = {
    config: DashboardWidgetTypes.Types.YouTubeLiveChat.DefaultState,
  };

  async componentDidMount() {
    const { config, subscribeYouTubeLiveChat } = this.props;
    const { streamerEmail } = config;
    if (!streamerEmail) {
      return;
    }

    subscribeYouTubeLiveChat(streamerEmail);
  }

  render() {
    const {
      className,
      config,
      subscribeYouTubeLiveChat,
      youtubeLiveChatMessages,
      ...other
    } = this.props;
    const fullClassName = `dashboard-widget youtube-live-chat ${className}`;

    const { name, streamerEmail } = config;

    return (
      <div className={fullClassName} {...other}>
        {name && (
          <div className="youtube-live-chat-widget-name">
            <img
              src="/images/icons/youtube.svg"
              alt="YouTube icon"
              className="youtube-icon"
            />
            {name}
          </div>
        )}

        <div className="youtube-live-chat-live-chats">
          <ul className="youtube-live-chat youtube-live-chat-messages list list-unstyled">
            <TransitionGroup>
              {((youtubeLiveChatMessages || {})[streamerEmail] || []).map(
                (chatMessage) => (
                  <CSSTransition
                    key={chatMessage.id}
                    classNames="youtube"
                    timeout={{ enter: 500 }}
                  >
                    <div className="youtube-live-chat-message">
                      <div>
                        <img
                          src={chatMessage.authorDetails.profileImageUrl}
                          alt="user"
                          className="youtube-user-image"
                        />
                      </div>
                      <div>
                        <div>
                          <div className="youtube-user-name">
                            {chatMessage.authorDetails.displayName}
                          </div>
                          <div className="youtube-live-chat-message-time">
                            {moment(chatMessage.snippet.publishedAt).fromNow()}
                          </div>
                        </div>
                        <div className="youtube-live-chat-message-text">
                          {chatMessage.snippet.displayMessage}
                        </div>
                      </div>
                    </div>
                  </CSSTransition>
                )
              )}
            </TransitionGroup>
          </ul>
        </div>
      </div>
    );
  }
}

// Acts like an object but returns exampleChatMessages for any key.
const exampleChatMessagesProxy = new Proxy(
  {},
  { get: () => exampleChatMessages.items }
);

// Left for developer convenience.
const useExampleChatMessages = false;

function mapStateToProps(state) {
  const youtubeLiveChatMessages = useExampleChatMessages
    ? exampleChatMessagesProxy
    : state.youtubeLiveChatMessages;
  return { youtubeLiveChatMessages };
}

function mapDispatchToProps(dispatch) {
  return {
    subscribeYouTubeLiveChat: (streamerEmail) =>
      dispatch(subscribeYouTubeLiveChat(streamerEmail)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(YouTubeLiveChatViewWidget);
