import React from "react";

import moment from "moment";

import ellipsizeBase from "../lib/ellipsize";
import TwitterImage from "./TwitterImage";

const ellipsize = (string) => ellipsizeBase(string, 24);

export default class Tweet extends React.Component {
  selectImages(tweet, imageDisplay) {
    const { extended_entities } = tweet;
    if (!extended_entities) return [];

    const { media } = extended_entities;
    if (!media || media.length === 0) return [];

    // possible values: photo, animated_gif, video
    const images = media.filter(
      (e) => e.type === "photo" || e.type === "animated_gif"
    );
    if (!images || images.length === 0) return [];

    switch (imageDisplay) {
      default:
      // intentional fallthrough
      case "none":
        return [];
      case "first":
        return images.slice(0, 1);
      case "all":
        return images;
    }
  }

  render() {
    const { tweet, imageDisplay } = this.props;

    return (
      <li className="tweet-show">
        <div className="tweet-container">
          {tweet.retweeted_status ? (
            <div className="retweet-info">
              <div className="tweet-icon retweet" />
              {tweet.user_verified ? (
                <div className="tweet-icon verified" />
              ) : null}

              <div>
                <span className="display-name">
                  {ellipsize(tweet.retweeted_status.username)}
                </span>
                &nbsp;
                <span className="screen-name">
                  @{ellipsize(tweet.retweeted_status.user_screen_name)}
                </span>
              </div>
              <div>retweeted</div>
            </div>
          ) : null}

          {tweet.in_reply_to_screen_name ? (
            <div className="reply-info">
              <div className="tweet-icon reply" />
              <div>
                in reply to{" "}
                <span className="screen-name">
                  {tweet.in_reply_to_screen_name}
                </span>
              </div>
            </div>
          ) : null}

          <div className="tweet">
            <div className="tweet-content">
              <div className="status">
                <div className="tweet-header">
                  <div className="tweet-name">
                    {tweet.user_verified ? (
                      <div className="tweet-icon verified" />
                    ) : null}

                    <div>
                      <span className="display-name">
                        {ellipsize(tweet.username)}
                      </span>
                      &nbsp;
                      <span className="screen-name">
                        @{ellipsize(tweet.user_screen_name)}
                      </span>
                    </div>
                  </div>

                  <div className="tweeted-at">
                    {moment(tweet.created_at).fromNow()}
                  </div>
                </div>

                <div className="tweet-text">{tweet.text}</div>

                <div className="tweet-images">
                  {this.selectImages(tweet, imageDisplay).map(
                    (image, index) => (
                      <TwitterImage key={index} image={image} />
                    )
                  )}
                </div>
              </div>

              {tweet.quoted_status ? (
                <div className="quoted-status">
                  <div className="tweet-header">
                    <div className="tweet-name">
                      <div>
                        <span className="display-name">
                          {ellipsize(tweet.quoted_status.username)}
                        </span>
                        &nbsp;
                        <span className="screen-name">
                          @{ellipsize(tweet.quoted_status.user_screen_name)}
                        </span>
                      </div>
                    </div>
                    <div className="tweeted-at">
                      {moment(tweet.quoted_status.created_at).fromNow()}
                    </div>
                  </div>

                  <div className="tweet-text">{tweet.quoted_status.text}</div>

                  <div className="tweet-images">
                    {this.selectImages(tweet.quoted_status, imageDisplay).map(
                      (image, index) => (
                        <TwitterImage key={index} image={image} />
                      )
                    )}
                  </div>
                </div>
              ) : null}
            </div>
          </div>

          <div className="tweet-meta">
            <div className="action-counts">
              <div className="tweet-icon favorite" />
              <div className="favorites">{tweet.favorite_count}</div>

              <div className="tweet-icon retweet" />
              <div className="retweets">{tweet.retweet_count}</div>
            </div>

            {tweet.place_name ? (
              <span className="tweet-place">{tweet.place_name}</span>
            ) : null}
          </div>
        </div>
      </li>
    );
  }
}
