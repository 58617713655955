import React from "react";
import { connect } from "react-redux";

import DashboardPreviewTile from "./DashboardPreviewTile";

import { loadDashboards, destroyDashboard } from "../actions";

class DashboardPreviewTileGrid extends React.Component {
  render() {
    const { dashboards, destroyDashboard } = this.props;
    if (!dashboards) {
      return null;
    }

    return (
      <div>
        <div className="vl-dashboard-preview-tile-grid">
          {dashboards.map((dashboard) => (
            <DashboardPreviewTile
              key={dashboard.get("id")}
              dashboard={dashboard}
              onDelete={() => destroyDashboard(dashboard)}
            />
          ))}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const dashboards = state.dashboards || [];
  return { dashboards };
}

function mapDispatchToProps(dispatch) {
  return {
    loadDashboards: () => dispatch(loadDashboards()),
    destroyDashboard: (dashboard) => dispatch(destroyDashboard(dashboard)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardPreviewTileGrid);
