import React from "react";

import NowPlayingWidget from "./NowPlayingWidget";
import RadioScheduleWidget from "./RadioScheduleWidget";
import RotatingWidget from "./RotatingWidget";
import TextImageWidget from "./TextImageWidget";
import TwitterFeedWidget from "./TwitterFeedWidget";
import WorldClockWidget from "./WorldClockWidget";
import YouTubeLiveChatWidget from "./YouTubeLiveChatWidget";
import InstagramCommentsWidget from "./InstagramCommentsWidget";

import DashboardWidgetTypes from "../lib/DashboardWidgetTypes";

// Selects correct widget to render based on widget type string.
export default class SelectingDashboardWidget extends React.Component {
  render() {
    const { type, ...other } = this.props;

    const types = DashboardWidgetTypes.Types;
    switch (type) {
      case types.NowPlaying.Type:
        return <NowPlayingWidget {...other} />;
      case types.RadioSchedule.Type:
        return <RadioScheduleWidget {...other} />;
      case types.Rotating.Type:
        return <RotatingWidget {...other} />;
      case types.TextImage.Type:
        return <TextImageWidget {...other} />;
      case types.TwitterFeed.Type:
        return <TwitterFeedWidget {...other} />;
      case types.WorldClock.Type:
        return <WorldClockWidget {...other} />;
      case types.YouTubeLiveChat.Type:
        return <YouTubeLiveChatWidget {...other} />;
      case types.InstagramComments.Type:
        return <InstagramCommentsWidget {...other} />;
      default:
        throw new Error(`Invalid widget type: ${type}`);
    }
  }
}
