import React from "react";
import { connect } from "react-redux";
import { Formik } from "formik";

import DashboardWidgetTypes from "../lib/DashboardWidgetTypes";

import { loadTwitterFeeds } from "../actions";

class TwitterFeedWidgetForm extends React.Component {
  defaultState = DashboardWidgetTypes.Types.TwitterFeed.DefaultState;

  componentWillMount() {
    this.props.loadTwitterFeeds();
  }

  render() {
    const {
      subwidgetDataItem,
      setSubmitForm,
      onSubmit,
      twitterFeeds,
    } = this.props;
    const config = this.normalizeConfig(subwidgetDataItem.config);

    if (config.twitterFeedID === "" && twitterFeeds.length > 0) {
      config.twitterFeedID = twitterFeeds.at(0).get("id");
    }

    return (
      <Formik
        initialValues={config}
        onSubmit={onSubmit}
        render={({ values, handleChange, handleBlur, submitForm }) => {
          // the back and forth of handlers required here to get
          // submission working is a little insane; sorry
          setSubmitForm(submitForm);

          return (
            <div className="uk-form-stacked uk-margin">
              <div className="uk-margin">
                <label className="uk-form-label">Name</label>
                <div className="uk-form-controls">
                  <input
                    className="uk-input"
                    name="name"
                    type="text"
                    placeholder="Name of the widget"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
              </div>

              <div className="uk-margin">
                <label className="uk-form-label">Twitter Feed</label>
                <div className="uk-form-controls">
                  <select
                    className="uk-select"
                    name="twitterFeedID"
                    value={values.twitterFeedID}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    {twitterFeeds.map((twitterFeed) => (
                      <option
                        key={twitterFeed.get("id")}
                        value={twitterFeed.get("id")}
                      >
                        {twitterFeed.getDisplayName()}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="uk-margin">
                <label className="uk-form-label">Image Display</label>
                <div className="uk-form-controls">
                  <select
                    className="uk-select"
                    name="imageDisplay"
                    value={values.imageDisplay}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <option value="none">Don't show images</option>
                    <option value="first">Show first image</option>
                    <option value="all">Show all images</option>
                  </select>
                </div>
              </div>
            </div>
          );
        }}
      />
    );
  }

  normalizeConfig(config) {
    // mostly the schema gets goofy during development;
    // should not be an issue once schema stabilizes
    const { name, twitterFeedID, imageDisplay } = config || {};
    const normalized = { name, twitterFeedID, imageDisplay };

    // undefined values will break controlled form inputs
    if (normalized.name === undefined) {
      normalized.name = this.defaultState.name;
    }
    if (normalized.twitterFeedID === undefined) {
      normalized.twitterFeedID = this.defaultState.twitterFeedID;
    }
    if (normalized.imageDisplay === undefined) {
      normalized.imageDisplay = this.defaultState.imageDisplay;
    }

    return normalized;
  }
}

function mapStateToProps(state) {
  const { twitterFeeds } = state;
  return { twitterFeeds };
}

function mapDispatchToProps(dispatch) {
  return {
    loadTwitterFeeds: () => dispatch(loadTwitterFeeds()),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TwitterFeedWidgetForm);
