import React from "react";
import { connect } from "react-redux";

import Modal from "./Modal";
import TimeSlotForm from "./TimeSlotForm";

class TimeSlotModal extends React.Component {
  render() {
    const { timeSlot, dashboard } = this.props;
    if (!timeSlot || !dashboard) {
      return <Modal modalId="time-slot-modal" />;
    }

    return (
      <Modal modalId="time-slot-modal" title="Edit Time Slot">
        <TimeSlotForm timeSlot={timeSlot} dashboard={dashboard} />
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  const { timeSlot, dashboard } = state;
  return { timeSlot, dashboard };
}

export default connect(mapStateToProps)(TimeSlotModal);
