import React from "react";
import { connect } from "react-redux";
import { Formik, Field } from "formik";
import MasterClientNavigator from "../pages/MasterClientNavigator";
import TwitterFormParser from "../lib/TwitterFormParsing";
import { withRouter } from "react-router-dom";
import { loadTwitterLists } from "../actions";
import { Spinner } from "../views/_shared/Bundle";

class TwitterFeedForm extends React.Component {
  constructor(props) {
    super(props);
    this.navigator = new MasterClientNavigator(this.props.history);
  }

  handleCancel = () => {
    this.navigator.pushTwitterFeedIndex();
  };

  componentDidMount() {
    this.props.loadTwitterLists();
  }

  componentDidUpdate(oldProps) {
    const oldFeed = oldProps.twitterFeed;
    if (!oldFeed) {
      return;
    }

    const newFeed = this.props.twitterFeed;
    if (!newFeed) {
      if (this.resetForm) this.resetForm();
      return;
    }

    if (oldFeed.get("id") !== newFeed.get("id")) {
      if (this.resetForm) this.resetForm();
    }
  }

  render() {
    const { twitterFeed, twitterLists, onSubmit } = this.props;
    const initialValues = TwitterFormParser.getFormValuesFromAttributes(
      twitterFeed
    );

    // wait until these load or initialValues gets fucked
    if (!twitterLists) return <Spinner />;

    // set a default unless already set
    if (twitterLists && twitterLists.length > 0) {
      initialValues["twitter_list_id"] =
        initialValues["twitter_list_id"] || twitterLists[0]["id_str"];
    }

    return (
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        render={({
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          resetForm,
        }) => {
          this.resetForm = resetForm;

          return (
            <form className="uk-form-stacked" onSubmit={handleSubmit}>
              <div uk-grid="" className="uk-child-width-1-2">
                {/* column 1 */}
                <div>
                  <div className="uk-margin">
                    <label className="uk-form-label">
                      Name <span className="vl-required">*</span>
                    </label>
                    <div className="uk-form-controls">
                      <input
                        className="uk-input"
                        type="text"
                        name="name"
                        placeholder=""
                        value={values.name || ""}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required={true}
                        autoFocus={true}
                      />
                    </div>
                  </div>

                  <div className="uk-margin">
                    <label className="uk-form-label">
                      Feed topic keywords <span className="vl-required">*</span>
                    </label>
                    <div className="uk-form-controls">
                      <Field
                        component="textarea"
                        className="uk-textarea"
                        name="topics"
                        type="text"
                        placeholder=""
                        value={values.topics || ""}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required={true}
                        rows={4}
                      />
                    </div>
                  </div>

                  <div className="uk-margin">
                    <label className="uk-form-label">
                      Blocked feed topic keywords
                    </label>
                    <div className="uk-form-controls">
                      <Field
                        component="textarea"
                        className="uk-textarea"
                        name="blocked_topics"
                        type="text"
                        placeholder="A comma separated list of topics that should not be shown"
                        value={values.blocked_topics || ""}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        rows={4}
                      />
                    </div>
                  </div>

                  {values.use_twitter_list &&
                  twitterLists &&
                  twitterLists.length > 0 ? (
                    <div className="uk-margin">
                      <label className="uk-form-label">Twitter list</label>
                      <div className="uk-form-controls">
                        <select
                          className="uk-select"
                          name="twitter_list_id"
                          value={
                            values.twitter_list_id ||
                            twitterLists[0]["id_str"] ||
                            ""
                          }
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          {twitterLists.map((twitterList) => (
                            <option
                              key={twitterList["id_str"]}
                              value={twitterList["id_str"]}
                            >
                              {`${twitterList["full_name"]}: ${twitterList["name"]}`}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  ) : (
                    <div className="uk-margin">
                      <label className="uk-form-label">
                        Followed screen names
                      </label>
                      <div className="uk-form-controls">
                        <Field
                          component="textarea"
                          className="uk-textarea"
                          name="follows"
                          type="text"
                          placeholder="A comma separated list of screen names"
                          value={values.follows || ""}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          rows={4}
                        />
                      </div>
                    </div>
                  )}

                  <div className="uk-margin">
                    <label className="uk-form-label">
                      Blocked screen names
                    </label>
                    <div className="uk-form-controls">
                      <Field
                        component="textarea"
                        className="uk-textarea"
                        name="blocked_follows"
                        type="text"
                        placeholder="A comma separated list of screen names that should not be shown"
                        value={values.blocked_follows || ""}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        rows={4}
                      />
                    </div>
                  </div>
                </div>

                {/* column 2 */}
                <div>
                  <div className="uk-margin-small">
                    <label className="uk-form-label">
                      Items require approval
                    </label>
                    <div className="uk-form-controls">
                      <input
                        className="uk-checkbox"
                        type="checkbox"
                        name="require_approval"
                        checked={values.require_approval || false}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                  </div>

                  {twitterLists && twitterLists.length > 0 ? (
                    <div className="uk-margin">
                      <label className="uk-form-label">Use Twitter list</label>
                      <div className="uk-form-controls">
                        <input
                          className="uk-checkbox"
                          name="use_twitter_list"
                          type="checkbox"
                          checked={values.use_twitter_list || false}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </div>
                    </div>
                  ) : null}

                  <div className="uk-margin">
                    <label className="uk-form-label">Show retweets</label>
                    <div className="uk-form-controls">
                      <input
                        className="uk-checkbox"
                        name="show_retweets"
                        type="checkbox"
                        checked={values.show_retweets || false}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                  </div>

                  <div className="uk-margin">
                    <label className="uk-form-label">
                      Only show verified accounts
                    </label>
                    <div className="uk-form-controls">
                      <input
                        className="uk-checkbox"
                        name="show_verified_users_only"
                        type="checkbox"
                        checked={values.show_verified_users_only || false}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <hr />
              <div className="uk-margin uk-flex uk-flex-between">
                <button
                  className="tm-button tm-button-link"
                  type="button"
                  onClick={this.handleCancel}
                >
                  Cancel
                </button>
                <button
                  className="tm-button tm-button-primary"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Save
                </button>
              </div>
            </form>
          );
        }}
      />
    );
  }
}

function mapStateToProps(state) {
  const { twitterLists } = state;
  return { twitterLists };
}

function mapDispatchToProps(dispatch) {
  return {
    loadTwitterLists: () => dispatch(loadTwitterLists()),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TwitterFeedForm)
);
