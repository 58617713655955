import React from "react";
import { connect } from "react-redux";
import { Formik, Field } from "formik";
import { withRouter } from "react-router-dom";
import FormikMonacoEditor from "./FormikMonacoEditor";
import MasterClientNavigator from "../pages/MasterClientNavigator";
import UserAuth from "../lib/UserAuth";

class ThemeForm extends React.Component {
  constructor(props) {
    super(props);
    this.formik = React.createRef();
    this.navigator = new MasterClientNavigator(this.props.history);
    this.state = { isSuperAdmin: false };
  }

  async componentDidMount() {
    this._isMounted = true;

    const isSuperAdmin = await UserAuth.isCurrentUserSuperAdmin();
    if (this._isMounted) {
      this.setState({ isSuperAdmin });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(previousProps) {
    const previousId = previousProps.theme && previousProps.theme.get("id");
    const currentId = this.props.theme && this.props.theme.get("id");

    if (previousId !== currentId) {
      if (this.formik.current) {
        this.formik.current.resetForm();
      }
    }
  }

  handleCancel = () => {
    this.navigator.pushThemeIndex();
  };

  render() {
    const { theme, themeError, onSubmit } = this.props;
    if (!theme) {
      return null;
    }

    const { name, description, is_locked, input_scss, id } = theme.attributes;
    const initialValues = { name, description, is_locked, input_scss, id };
    const isNewRecord = !id;
    const { isSuperAdmin } = this.state;

    return (
      <Formik
        ref={this.formik}
        initialValues={initialValues}
        validate={() => {}}
        onSubmit={onSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => {
          const error =
            touched.name &&
            errors.name &&
            touched.description &&
            errors.description &&
            touched.is_locked &&
            errors.is_locked &&
            touched.input_scss &&
            errors.input_scss;

          return (
            <>
              <form className="uk-form-stacked" onSubmit={handleSubmit}>
                {themeError ? (
                  <div className="uk-margin uk-form-danger">{themeError}</div>
                ) : null}
                {error ? (
                  <div className="uk-margin uk-form-danger">{error}</div>
                ) : null}
                <div uk-grid="" className="uk-child-width-1-2">
                  <div>
                    <div className="uk-margin">
                      <label className="uk-form-label">
                        Name <span className="vl-required">*</span>
                      </label>
                      <div className="uk-form-controls">
                        <input
                          className="uk-input"
                          name="name"
                          type="text"
                          placeholder=""
                          value={values.name || ""}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          required={true}
                          autoFocus={true}
                        />
                      </div>
                    </div>
                    <div className="uk-margin">
                      <label className="uk-form-label">Description</label>
                      <div className="uk-form-controls">
                        <Field
                          component="textarea"
                          className="uk-textarea"
                          name="description"
                          type="text"
                          placeholder=""
                          value={values.description || ""}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          rows={4}
                        />
                      </div>
                    </div>

                    <div className="uk-margin">
                      <label className="uk-form-label">Locked</label>
                      <div className="uk-form-controls">
                        <input
                          className="uk-checkbox"
                          type="checkbox"
                          name="is_locked"
                          disabled={!isNewRecord && !isSuperAdmin}
                          checked={values.is_locked || false}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="uk-margin">
                      <label className="uk-form-label">SCSS</label>
                      <div className="uk-form-controls">
                        <FormikMonacoEditor
                          name="input_scss"
                          value={values.input_scss || ""}
                          disabled={values.is_locked}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="uk-margin uk-flex uk-flex-between">
                  <button
                    className="tm-button tm-button-link"
                    type="button"
                    onClick={this.handleCancel}
                  >
                    Cancel
                  </button>
                  <button
                    className="tm-button tm-button-primary"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Save
                  </button>
                </div>
              </form>
            </>
          );
        }}
      </Formik>
    );
  }
}

function mapStateToProps(state) {
  const { themeError } = state;
  return { themeError };
}

export default withRouter(connect(mapStateToProps)(ThemeForm));
