import React from "react";
import { connect } from "react-redux";
import UIkit from "uikit";

import ThemeRow from "./ThemeRow";

import { destroyTheme } from "../actions";

class ThemesTable extends React.Component {
  render() {
    const { themes, destroyTheme } = this.props;
    if (!themes) {
      return null;
    }

    return (
      <div>
        <table className="uk-table uk-table-small uk-table-divider uk-table-middle uk-margin">
          <thead>
            <tr>
              <th>Name</th>
              <th>Description</th>
              <th>Locked</th>
              <th>Dashboards Using</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {themes.map((theme) => (
              <ThemeRow
                key={theme.get("id")}
                theme={theme}
                onDelete={() => destroyTheme(theme)}
              />
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

function mapStateToProps(_state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    destroyTheme: (theme) => {
      UIkit.modal
        .confirm(
          `Are you sure you want to delete the Theme '${theme.attributes.name}'?`
        )
        .then(
          function () {
            dispatch(destroyTheme(theme));
            UIkit.notification(
              "<span uk-icon='icon: check'></span> Theme Deleted",
              {
                status: "success",
                timeout: 5000,
              }
            );
          },
          function () {}
        );
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ThemesTable);
