import React from "react";
import { connect } from "react-redux";

import AbstractBasePage from "../../pages/AbstractBasePage";

import DashboardPreviewTileGrid from "../../components/DashboardPreviewTileGrid";
import DashboardTable from "../../components/DashboardTable";
import PageTitleWithButton from "../../components/PageTitleWithButton";
import { BreadcrumbItem } from "../../components/Breadcrumbs";
import { DashboardsNavigator } from "./Bundle";
import { Spinner } from "../_shared/Bundle";

import { loadDashboards, setBreadcrumbItems } from "../../actions";

class DashboardsIndex extends AbstractBasePage {
  constructor(props) {
    super(props);
    this.navigator = new DashboardsNavigator(this.props.history);
  }

  handleAddDashboard = () => {
    this.navigator.pushNew();
  };

  componentDidMount() {
    this.props.loadDashboards();
  }

  render() {
    const { dashboards } = this.props;
    let dashboardsRender;

    if (!dashboards) {
      dashboardsRender = <Spinner />;
    } else {
      dashboardsRender = (
        <>
          <div
            className="vl-switcher"
            uk-switcher="toggle: > *"
            data-uk-switcher="{swiping:false}"
          >
            <button className="uk-button uk-button-default vl-button-toggle vl-left">
              <span uk-icon="icon: grid; ratio: 0.8" />
              <span className="vl-text">Grid</span>
            </button>
            <button className="uk-button uk-button-default vl-button-toggle vl-right">
              <span uk-icon="icon: list; ratio: 0.8" />
              <span className="vl-text">List</span>
            </button>
          </div>

          <ul className="uk-switcher">
            <li>
              <DashboardPreviewTileGrid />
            </li>
            <li>
              <DashboardTable />
            </li>
          </ul>
        </>
      );
    }

    return (
      <>
        <PageTitleWithButton
          titleText="Dashboards"
          buttonText="New Dashboard"
          onClick={this.handleAddDashboard}
        />

        {dashboardsRender}
      </>
    );
  }

  getBreadcrumbItems() {
    return [new BreadcrumbItem("Dashboards")];
  }
}

function mapStateToProps(state) {
  const { dashboards, breadcrumbItems } = state;
  return { dashboards, breadcrumbItems };
}

function mapDispatchToProps(dispatch) {
  return {
    loadDashboards: () => dispatch(loadDashboards()),
    setBreadcrumbItems: (items) => dispatch(setBreadcrumbItems(items)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardsIndex);
