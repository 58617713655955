import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import UIkit from "uikit";
import AbstractBasePage from "./AbstractBasePage";
import MasterClientNavigator from "./MasterClientNavigator";

import LayoutForm from "../components/LayoutForm";
import PageTitle from "../components/PageTitle";
import { BreadcrumbItem } from "../components/Breadcrumbs";

import LayoutModel from "../models/LayoutModel";

import { loadDashboard, saveLayout, setBreadcrumbItems } from "../actions";
import { Spinner } from "../views/_shared/Bundle";

class LayoutNewPage extends AbstractBasePage {
  constructor(props) {
    super(props);
    this.navigator = new MasterClientNavigator(this.props.history);
  }

  handleSubmit = async (data) => {
    const { match } = this.props;
    data.dashboard_id = match.params.dashboardId;
    const layout = LayoutModel.findOrCreate(data);
    await this.props.saveLayout(layout);

    const { dashboard } = this.props;
    this.navigator.pushDashboardEdit(dashboard);
    UIkit.notification("<span uk-icon='icon: check'></span> Layout Created", {
      status: "success",
      timeout: 5000,
    });
  };

  componentWillMount() {
    const { match } = this.props;
    const dashboardId = match.params.dashboardId;
    this.props.loadDashboard(dashboardId);
  }

  render() {
    const { dashboard } = this.props;
    const layoutRender = !dashboard ? (
      <Spinner />
    ) : (
      <LayoutForm
        layout={new LayoutModel()}
        onSubmit={this.handleSubmit}
        dashboard={dashboard}
      />
    );

    return (
      <div>
        <PageTitle text="New Layout" />
        {layoutRender}
      </div>
    );
  }

  getBreadcrumbItems() {
    const { dashboard } = this.props;
    if (!dashboard) {
      return [];
    }

    const pushDashboardIndex = this.navigator.pushDashboardIndex;
    const pushDashboardEdit = this.navigator.pushDashboardEdit.bind(
      this.navigator,
      dashboard
    );
    return [
      new BreadcrumbItem("Dashboards", pushDashboardIndex),
      new BreadcrumbItem(dashboard.getDisplayName(), pushDashboardEdit),
      new BreadcrumbItem("New Layout"),
    ];
  }
}

function mapStateToProps(state) {
  const { dashboard, layout, breadcrumbItems } = state;
  return { dashboard, layout, breadcrumbItems };
}

function mapDispatchToProps(dispatch) {
  return {
    loadDashboard: (id) => dispatch(loadDashboard(id)),
    saveLayout: (layout) => dispatch(saveLayout(layout)),
    setBreadcrumbItems: (items) => dispatch(setBreadcrumbItems(items)),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LayoutNewPage)
);
