import AppNavigator from "./AppNavigator";
import { DashboardsNavigator } from "../views/dashboards/Bundle";
import LayoutNavigator from "./LayoutNavigator";
import UserNavigator from "./UserNavigator";
import TwitterFeedNavigator from "./TwitterFeedNavigator";
import ThemeNavigator from "./ThemeNavigator";

// Composes all other navigators (for components that need multiple navigators).
export default class MasterClientNavigator extends AppNavigator {
  constructor(history) {
    super(history);
    this.dashboard = new DashboardsNavigator(history);
    this.layout = new LayoutNavigator(history);
    this.user = new UserNavigator(history);
    this.twitterFeed = new TwitterFeedNavigator(history);
    this.theme = new ThemeNavigator(history);
  }

  pushDashboardIndex = () => this.dashboard.pushIndex();

  pushDashboardEdit = (dashboard) => this.dashboard.pushEdit(dashboard);

  pushLayoutEdit = (dashboard, layout) =>
    this.layout.pushEdit(dashboard, layout);

  pushUserIndex = () => this.user.pushIndex();

  dashboardIndexPath = () => this.dashboard.indexPath();

  dashboardEditPath = (dashboard) => this.dashboard.editPath(dashboard);

  layoutEditPath = (dashboard, layout) =>
    this.layout.editPath(dashboard, layout);

  userIndexPath = () => this.user.indexPath();

  twitterFeedIndexPath = () => this.twitterFeed.indexPath();

  pushTwitterFeedIndex = () => this.twitterFeed.pushIndex();

  themePath = () => this.theme.indexPath();

  themeIndex = () => this.theme.pushIndex();

  pushThemeIndex = () => this.theme.pushIndex();

  twitterAnalyticsPath = () => "/social/twitter-analytics";

  pushTwitterAnalytics = () => this.push(this.twitterAnalyticsPath());
}
