import React from "react";
import { connect } from "react-redux";
import { loadModelDefaults } from "../actions";

class ModelDefaultsLoader extends React.Component {
  componentDidMount() {
    this.props.loadModelDefaults();
  }

  render() {
    return null;
  }
}

function mapStateToProps(_state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return { loadModelDefaults: () => dispatch(loadModelDefaults()) };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModelDefaultsLoader);
