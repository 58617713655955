import React from "react";
import { connect } from "react-redux";

import WidgetModal from "./WidgetModal";
import DashboardWidgetTypes from "../lib/DashboardWidgetTypes";

class NowPlayingModal extends React.Component {
  defaultState = DashboardWidgetTypes.Types.NowPlaying.DefaultState;

  render() {
    const { widgetDataItem } = this.props;
    if (!widgetDataItem) {
      return <WidgetModal modalId="now-playing-modal" />;
    }
    const config = this.normalizeConfig(widgetDataItem.config);

    return (
      <WidgetModal
        modalId="now-playing-modal"
        title="Edit Now Playing Widget"
        initialValues={config}
        render={({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => {
          const error =
            touched.name &&
            errors.name &&
            touched.showLogo &&
            errors.showLogo &&
            touched.showStationName &&
            errors.showStationName &&
            touched.showOnAir &&
            errors.showOnAir &&
            touched.backgroundColor &&
            errors.backgroundColor &&
            touched.onAirFontColor &&
            errors.onAirFontColor &&
            touched.stationFontColor &&
            errors.stationFontColor &&
            touched.trackInfoFontColor &&
            errors.trackInfoFontColor;

          return (
            <form className="uk-form-stacked" onSubmit={handleSubmit}>
              <div className="uk-modal-body" uk-overflow-auto="">
                <div className="uk-margin">
                  <label className="uk-form-label">Name</label>
                  <div className="uk-form-controls">
                    <input
                      className="uk-input"
                      type="text"
                      name="name"
                      placeholder=""
                      value={values.name || ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                </div>

                <div className="uk-margin-small">
                  <div className="uk-form-controls">
                    <label className="uk-form-label">
                      <input
                        className="uk-checkbox uk-margin-small-right"
                        type="checkbox"
                        name="showLogo"
                        checked={values.showLogo || false}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      Show Logo
                    </label>
                  </div>
                </div>

                <div className="uk-margin-small">
                  <div className="uk-form-controls">
                    <label className="uk-form-label">
                      <input
                        className="uk-checkbox uk-margin-small-right"
                        type="checkbox"
                        name="showStationName"
                        checked={values.showStationName || false}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      Show Station Name
                    </label>
                  </div>
                </div>

                <div className="uk-margin-small">
                  <div className="uk-form-controls">
                    <label className="uk-form-label">
                      <input
                        className="uk-checkbox uk-margin-small-right"
                        type="checkbox"
                        name="showOnAir"
                        checked={values.showOnAir || false}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      Show On Air
                    </label>
                  </div>
                </div>

                <div className="uk-margin">
                  <label className="uk-form-label">Transition Type</label>
                  <div className="uk-form-controls">
                    <select
                      className="uk-select"
                      name="transitionType"
                      value={values.transitionType}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option value="none">None</option>
                      <option value="slide">Slide</option>
                      <option value="fade">Fade</option>
                      <option value="flip">Flip</option>
                    </select>
                  </div>
                </div>

                <div className="uk-margin">
                  <label className="uk-form-label">
                    <input
                      className="uk-input-xsmall uk-form-width-xsmall uk-margin-small-right"
                      type="color"
                      name="backgroundColor"
                      placeholder=""
                      value={values.backgroundColor || "#000000"}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    Background Color
                  </label>
                </div>

                <div className="uk-margin">
                  <label className="uk-form-label">
                    <input
                      className="uk-input-xsmall uk-form-width-xsmall uk-margin-small-right"
                      type="color"
                      name="onAirFontColor"
                      placeholder=""
                      value={values.onAirFontColor || "#000000"}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    On Air Font Color
                  </label>
                </div>

                <div className="uk-margin">
                  <label className="uk-form-label">
                    <input
                      className="uk-input-xsmall uk-form-width-xsmall uk-margin-small-right"
                      type="color"
                      name="stationFontColor"
                      value={values.stationFontColor || "#000000"}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    Station Font Color
                  </label>
                </div>

                <div className="uk-margin">
                  <label className="uk-form-label">
                    <input
                      className="uk-input-xsmall uk-form-width-xsmall uk-margin-small-right"
                      type="color"
                      name="trackInfoFontColor"
                      value={values.trackInfoFontColor || "#000000"}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    Track Info Font Color
                  </label>
                </div>
              </div>

              {error ? (
                <div className="uk-margin uk-form-danger">{error}</div>
              ) : null}

              <div className="uk-modal-footer uk-flex">
                <button
                  className="uk-button uk-button-default uk-modal-close"
                  type="button"
                >
                  Cancel
                </button>
                <button
                  className="uk-button uk-button-primary uk-margin-auto-left"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Save
                </button>
              </div>
            </form>
          );
        }}
      />
    );
  }

  normalizeConfig(config) {
    // mostly the schema gets goofy during development;
    // should not be an issue once schema stabilizes
    const {
      name,
      showLogo,
      showStationName,
      showOnAir,
      transitionType,
      backgroundColor,
      onAirFontColor,
      stationFontColor,
      trackInfoFontColor,
    } = config || {};
    const normalized = {
      name,
      showLogo,
      showStationName,
      showOnAir,
      transitionType,
      backgroundColor,
      onAirFontColor,
      stationFontColor,
      trackInfoFontColor,
    };

    // undefined values will break controlled form inputs
    if (normalized.name === undefined) {
      normalized.name = this.defaultState.name;
    }
    if (normalized.showLogo === undefined || normalized.showLogo === "") {
      normalized.showLogo = this.defaultState.showLogo;
    }
    if (
      normalized.showStationName === undefined ||
      normalized.showStationName === ""
    ) {
      normalized.showStationName = this.defaultState.showStationName;
    }
    if (normalized.showOnAir === undefined || normalized.showOnAir === "") {
      normalized.showOnAir = this.defaultState.showOnAir;
    }
    if (
      normalized.transitionType === undefined ||
      normalized.transitionType === ""
    ) {
      normalized.transitionType = this.defaultState.transitionType;
    }
    if (normalized.backgroundColor === undefined) {
      normalized.backgroundColor = this.defaultState.backgroundColor;
    }
    if (normalized.onAirFontColor === undefined) {
      normalized.onAirFontColor = this.defaultState.onAirFontColor;
    }
    if (normalized.stationFontColor === undefined) {
      normalized.stationFontColor = this.defaultState.stationFontColor;
    }
    if (normalized.trackInfoFontColor === undefined) {
      normalized.trackInfoFontColor = this.defaultState.trackInfoFontColor;
    }

    return normalized;
  }
}

function mapStateToProps(state) {
  const { widgetDataItem } = state;
  return { widgetDataItem };
}

export default connect(mapStateToProps)(NowPlayingModal);
