import React from "react";
import { connect } from "react-redux";

import WidgetModal from "./WidgetModal";
import DashboardWidgetTypes from "../lib/DashboardWidgetTypes";

import { loadTwitterFeeds } from "../actions";

class TwitterFeedModal extends React.Component {
  defaultState = DashboardWidgetTypes.Types.TwitterFeed.DefaultState;

  componentWillMount() {
    this.props.loadTwitterFeeds();
  }

  render() {
    const { widgetDataItem, twitterFeeds } = this.props;
    if (!widgetDataItem || !twitterFeeds) {
      return <WidgetModal modalId="twitter-feed-modal" />;
    }
    const config = this.normalizeConfig(widgetDataItem.config);

    if (config.twitterFeedID === "" && twitterFeeds.length > 0) {
      config.twitterFeedID = twitterFeeds.at(0).get("id");
    }

    return (
      <WidgetModal
        modalId="twitter-feed-modal"
        title="Edit Twitter Feed Widget"
        initialValues={config}
        render={({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => {
          const error =
            touched.name &&
            errors.name &&
            touched.twitterFeed &&
            errors.twitterFeed &&
            touched.imageDisplay &&
            errors.imageDisplay;

          return (
            <form className="uk-form-stacked" onSubmit={handleSubmit}>
              <div className="uk-modal-body">
                <div className="uk-margin">
                  <label className="uk-form-label">Name</label>
                  <div className="uk-form-controls">
                    <input
                      className="uk-input"
                      name="name"
                      type="text"
                      placeholder="Name of the widget"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                </div>

                <div className="uk-margin">
                  <label className="uk-form-label">Twitter Feed</label>
                  <div className="uk-form-controls">
                    <select
                      className="uk-select"
                      name="twitterFeedID"
                      value={values.twitterFeedID}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      {twitterFeeds.map((twitterFeed) => (
                        <option
                          key={twitterFeed.get("id")}
                          value={twitterFeed.get("id")}
                        >
                          {twitterFeed.getDisplayName()}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="uk-margin">
                  <label className="uk-form-label">Image Display</label>
                  <div className="uk-form-controls">
                    <select
                      className="uk-select"
                      name="imageDisplay"
                      value={values.imageDisplay}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option value="none">Don't show images</option>
                      <option value="first">Show first image</option>
                      <option value="all">Show all images</option>
                    </select>
                  </div>
                </div>
              </div>

              {error ? (
                <div className="uk-margin uk-form-danger">{error}</div>
              ) : null}

              <div className="uk-modal-footer uk-flex">
                <button
                  className="uk-button uk-button-default uk-modal-close"
                  type="button"
                >
                  Cancel
                </button>
                <button
                  className="uk-button uk-button-primary uk-margin-auto-left"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Save
                </button>
              </div>
            </form>
          );
        }}
      />
    );
  }

  normalizeConfig(config) {
    // mostly the schema gets goofy during development;
    // should not be an issue once schema stabilizes
    const { name, twitterFeedID, imageDisplay } = config || {};
    const normalized = { name, twitterFeedID, imageDisplay };

    // undefined values will break controlled form inputs
    if (normalized.name === undefined) {
      normalized.name = this.defaultState.name;
    }
    if (normalized.twitterFeedID === undefined) {
      normalized.twitterFeedID = this.defaultState.twitterFeedID;
    }
    if (normalized.imageDisplay === undefined) {
      normalized.imageDisplay = this.defaultState.imageDisplay;
    }

    return normalized;
  }
}

function mapStateToProps(state) {
  const { widgetDataItem, twitterFeeds } = state;
  return { widgetDataItem, twitterFeeds };
}

function mapDispatchToProps(dispatch) {
  return {
    loadTwitterFeeds: () => dispatch(loadTwitterFeeds()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TwitterFeedModal);
