import React from "react";
import { connect } from "react-redux";
import { ActionCableProvider } from "react-actioncable-provider";

import TwitterFeedConsumer from "./TwitterFeedConsumer";

// Manage ActionCable consumers for Twitter feeds. The library is a little
// buggy, so we want to just mount this once and leave it mounted.
class TwitterFeedConsumerManager extends React.Component {
  render() {
    const subscribedTwitterFeeds = this.props.subscribedTwitterFeeds || [];
    const ids = this.unique(
      subscribedTwitterFeeds.map((twitterFeed) => twitterFeed.get("id"))
    );

    return (
      <ActionCableProvider url={process.env.REACT_APP_WS_URL}>
        {ids.map((id) => (
          <TwitterFeedConsumer key={id} twitterFeedID={id} />
        ))}
      </ActionCableProvider>
    );
  }

  unique = (array) => [...new Set(array)];
}

function mapStateToProps(state) {
  const { subscribedTwitterFeeds } = state;
  return { subscribedTwitterFeeds };
}

export default connect(mapStateToProps)(TwitterFeedConsumerManager);
