import React from "react";
import { connect } from "react-redux";
import BigCalendar from "react-big-calendar";

import _ from "lodash";
import moment from "moment";

import TimeSlotModel from "../models/TimeSlotModel";
import { loadTimeSlots, editTimeSlot } from "../actions";

class DashboardSchedule extends React.Component {
  static localizer = BigCalendar.momentLocalizer(moment);

  // display several years worth of weekly recurrence
  static recurrenceDisplayCount = 7 * 52;

  componentWillMount() {
    const { dashboard, loadTimeSlots } = this.props;
    loadTimeSlots(dashboard);
  }

  handleSelectSlot = async ({ start, end }) => {
    const { dashboard, editTimeSlot } = this.props;
    const timeSlot = await TimeSlotModel.fromDashboard(dashboard, {
      start,
      end,
    });
    editTimeSlot(timeSlot);
  };

  handleSelectEvent = (event) => {
    const { editTimeSlot } = this.props;
    editTimeSlot(event.timeSlot);
  };

  render() {
    const { timeSlots } = this.props;
    if (!timeSlots) {
      return null;
    }

    // const events = this.generateAllEvents(timeSlots);

    return (
      <div className="uk-alert uk-alert-info">
        <p>Coming Soon.</p>
      </div>
      // <div className="vl-calendar-container">
      //   <BigCalendar
      //     localizer={this.constructor.localizer}
      //     views={{ week: true, month: true }}
      //     defaultView="week"
      //     events={events}
      //     startAccessor="start"
      //     endAccessor="end"
      //     selectable={true}
      //     onSelectSlot={this.handleSelectSlot}
      //     onSelectEvent={this.handleSelectEvent}
      //   />
      // </div>
    );
  }

  generateAllEvents(timeSlots) {
    return _.flatten(
      timeSlots.map((timeSlot) => this.generateEvents(timeSlot))
    );
  }

  generateEvents(timeSlot) {
    if (timeSlot.get("recurring")) {
      const array = Array(this.constructor.recurrenceDisplayCount).fill();
      return array.map((_, i) => this.generateEvent(timeSlot, i));
    }
    return [this.generateEvent(timeSlot)];
  }

  generateEvent(timeSlot, weekOffset = 0) {
    return {
      start: this.weeksLater(new Date(timeSlot.get("start_at")), weekOffset),
      end: this.weeksLater(new Date(timeSlot.get("end_at")), weekOffset),
      title: timeSlot.get("name"),
      timeSlot,
    };
  }

  weeksLater(date, weeks) {
    const later = new Date();
    later.setTime(date.getTime() + weeks * 7 * 24 * 60 * 60 * 1000);
    return later;
  }
}

function mapStateToProps(state) {
  const { timeSlots } = state;
  return { timeSlots };
}

function mapDispatchToProps(dispatch) {
  return {
    loadTimeSlots: (dashboard) => dispatch(loadTimeSlots(dashboard)),
    editTimeSlot: (timeSlot) => dispatch(editTimeSlot(timeSlot)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardSchedule);
