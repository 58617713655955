import React from "react";
import PageTitle from "./PageTitle";

export default class PageTitleWithButton extends React.Component {
  render() {
    let { titleText, buttonText, onClick } = this.props;
    return (
      <PageTitle text={titleText}>
        <button
          className="uk-button tm-button tm-button-primary uk-button-small"
          onClick={onClick}
        >
          {buttonText}
        </button>
      </PageTitle>
    );
  }
}
