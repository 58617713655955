import React from "react";
import { connect } from "react-redux";

import AbstractBasePage from "./AbstractBasePage";

import TwitterFeedTable from "../components/TwitterFeedTable";
import TwitterGlobalFilterTable from "../components/TwitterGlobalFilterTable";
import PageTitleWithButton from "../components/PageTitleWithButton";
import { BreadcrumbItem } from "../components/Breadcrumbs";
import TwitterFeedNavigator from "./TwitterFeedNavigator";
import { Spinner } from "../views/_shared/Bundle";
import {
  loadTwitterFeeds,
  loadTwitterGlobalFilter,
  setBreadcrumbItems,
} from "../actions";

class TwitterFeedIndexPage extends AbstractBasePage {
  constructor(props) {
    super(props);
    this.navigator = new TwitterFeedNavigator(this.props.history);
  }

  handleAddTwitterFeed = () => {
    this.navigator.pushNew();
  };

  handleEditGlobalFilter = () => {
    this.navigator.pushGlobalFilter();
  };

  componentDidMount() {
    this.props.loadTwitterFeeds();
    this.props.loadTwitterGlobalFilter();
  }

  render() {
    const { twitterFeeds, twitterGlobalFilter } = this.props;
    const twitterFeedsRender = !twitterFeeds ? (
      <Spinner />
    ) : (
      <TwitterFeedTable />
    );

    const twitterGlobalFilterRender = !twitterGlobalFilter ? (
      <Spinner />
    ) : (
      <TwitterGlobalFilterTable />
    );

    return (
      <div>
        <PageTitleWithButton
          titleText="Twitter Feeds"
          buttonText="+ Add Twitter Feed"
          onClick={this.handleAddTwitterFeed}
        />
        {twitterFeedsRender}

        <PageTitleWithButton
          titleText="Global Filter"
          buttonText="Edit Global Filter"
          onClick={this.handleEditGlobalFilter}
        />
        {twitterGlobalFilterRender}
      </div>
    );
  }

  getBreadcrumbItems() {
    return [new BreadcrumbItem("Social Feeds")];
  }
}

function mapStateToProps(state) {
  const { twitterFeeds, twitterGlobalFilter, breadcrumbItems } = state;
  return { twitterFeeds, twitterGlobalFilter, breadcrumbItems };
}

function mapDispatchToProps(dispatch) {
  return {
    loadTwitterFeeds: () => dispatch(loadTwitterFeeds()),
    loadTwitterGlobalFilter: () => dispatch(loadTwitterGlobalFilter()),
    setBreadcrumbItems: (items) => dispatch(setBreadcrumbItems(items)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TwitterFeedIndexPage);
