import React from "react";
import Datetime from "react-datetime";

import { Field } from "formik";

import moment from "moment";

// Support for Formik #598
// https://github.com/YouCanBookMe/react-datetime/issues/598#issuecomment-440518051
class InnerFormikDatetime extends React.Component {
  onFieldChange = (value) => {
    const { field, form } = this.props;
    if (!field || !form) {
      return;
    }

    if (value instanceof moment) {
      // if the date field isn't in a valid date format, react-datetime's
      // onChange handler returns a string; otherwise it returns a moment
      // object. this is why we can't override DateTime's onChange prop
      // with Formik's field.onChange
      form.setFieldValue(field.name, moment(value));
    } else {
      form.setFieldValue(field.name, value);
    }
  };

  onFieldBlur = () => {
    const { field, form } = this.props;
    if (!field || !form) {
      return;
    }

    form.setFieldTouched(field.name, true);
  };

  render() {
    const { field } = this.props;
    if (!field) {
      return null;
    }

    return (
      <Datetime
        name={field.name}
        value={field.value}
        onChange={this.onFieldChange}
        onBlur={this.onFieldBlur}
      />
    );
  }
}

export default class FormikDatetime extends React.Component {
  render() {
    return <Field {...this.props} component={InnerFormikDatetime} />;
  }
}
