import React from "react";

export default class GradientBackground extends React.Component {
  render() {
    const { className } = this.props;
    return (
      <div className={`${className} vl-gradient-background`}>
        {this.props.children}
      </div>
    );
  }
}
