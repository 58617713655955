import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import UIkit from "uikit";
import AbstractBasePage from "./AbstractBasePage";
import MasterClientNavigator from "./MasterClientNavigator";

import TwitterFeedForm from "../components/TwitterFeedForm";
import PageTitle from "../components/PageTitle";
import ErrorBanner from "../components/ErrorBanner";
import { BreadcrumbItem } from "../components/Breadcrumbs";

import TwitterFeedModel from "../models/TwitterFeedModel";

import TwitterFormParsing from "../lib/TwitterFormParsing";

import { saveTwitterFeed, setBreadcrumbItems } from "../actions";

class TwitterFeedNewPage extends AbstractBasePage {
  constructor(props) {
    super(props);
    this.navigator = new MasterClientNavigator(this.props.history);
  }

  handleSubmit = async (values, { setSubmitting }) => {
    const twitterFeed = TwitterFormParsing.createFromFormValues(values);

    const self = this;
    this.props.saveTwitterFeed(twitterFeed, {
      onSuccess: () => {
        setSubmitting(false);
        self.navigator.pushTwitterFeedIndex();
        UIkit.notification(
          "<span uk-icon='icon: check'></span> Twitter Feed Created",
          {
            status: "success",
            timeout: 5000,
          }
        );
      },
      onError: (error) => {
        setSubmitting(false);
        self.setState({ error });
      },
    });
  };

  render() {
    return (
      <div>
        <PageTitle text="New Twitter Feed" />
        <TwitterFeedForm
          twitterFeed={new TwitterFeedModel()}
          onSubmit={this.handleSubmit}
        />
        <ErrorBanner parent={this} />
      </div>
    );
  }

  getBreadcrumbItems() {
    const pushIndex = this.navigator.pushIndex;
    return [
      new BreadcrumbItem("Social Feeds", pushIndex),
      new BreadcrumbItem("New Twitter Feed"),
    ];
  }
}

function mapStateToProps(state) {
  const { breadcrumbItems } = state;
  return { breadcrumbItems };
}

function mapDispatchToProps(dispatch) {
  return {
    saveTwitterFeed: (twitterFeed, { onSuccess, onError }) =>
      dispatch(saveTwitterFeed(twitterFeed, { onSuccess, onError })),
    setBreadcrumbItems: (items) => dispatch(setBreadcrumbItems(items)),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TwitterFeedNewPage)
);
